.settings_header {
  .img-container {
    width: max-content;
    height: auto;
    .update-dp-label {
      position: relative;

      &:hover {
        .upload-img-cover {
          display: block;
        }
      }
      .upload-img-cover {
        display: none;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px 10px;
        color: white;
        border-radius: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
