// @tailwind base;
// @tailwind components;
// @tailwind utilities;

.footer_container {
	max-height: 380px;
	box-sizing: border-box;
	font-family: "Outfit", serif;
	background-color: #050505;
	color: #fff;
	padding: 0 2rem;
	margin-top: -1px;

	.footer_container_top {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		gap: 1.5rem; /* 16px */
		padding: 0 3rem;

		@media (max-width: 768px) {
			padding: 0;
		}

		@media (min-width: 821px) and (max-width: 1024px) {
			padding: 0 1rem;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 3rem;
		}

		@media (min-width: 1025px) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
			// grid-template-columns: repeat(4, auto);
			// gap: 5rem;
		}

		& h4 {
			color: #fff;
			font-size: 24px;
			margin-bottom: 10px;
			font-family: "Outfit", serif;
			font-style: normal;
			font-weight: 500;
			line-height: 30px;
			/* identical to box height */
			letter-spacing: -0.002em;

			@media (max-width: 820px) {
				text-align: center;
			}
		}

		& a {
			color: #fff;
			text-decoration: none;
			font-family: "Outfit", serif;
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 22px;
			/* identical to box height */
			color: #ffffff;
		}

		.input-group {
			margin-top: 10px;

			.form-control {
				border-radius: 0;
			}

			.btn-primary {
				border-radius: 0;
			}
		}
	}

	p {
		// padding-bottom: 20px;
		margin-bottom: 0;
		font-family: "Outfit", serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: -0.002em;
		color: #ffffff;
		margin-bottom: 1.5rem;

		@media (max-width: 820px) {
			text-align: center;
		}
	}
}

.subscribe_btn {
	width: 88px;
	height: 40px !important;
	border: none;
	background: #fd5901;
	border-radius: 6.624px;
	color: #fff;
	font-family: "Outfit", serif;
}

.subscribe_input {
	input {
		font-family: "Outfit", serif;
		width: 276px;
		height: 40px !important;
		background: #ffffff;
		box-shadow: 0px 0.552px 8.28px rgba(0, 0, 0, 0.15);
		border-radius: 6.624px;
		border-bottom-left-radius: 10px !important;
		border-top-left-radius: 10px !important;

		&::placeholder {
			color: #fd5901;
		}
	}
}

.subscribe_input {
	input {
		font-family: "Outfit", serif;
		width: 276px;
		height: 40px !important;
		background: #ffffff;
		box-shadow: 0px 0.552px 8.28px rgba(0, 0, 0, 0.15);
		border-radius: 6.624px;
		border-bottom-left-radius: 10px !important;
		border-top-left-radius: 10px !important;

		&::placeholder {
			color: #fd5901;
		}
	}
}

.footer_container .input-group .btn-primary {
	border-radius: 0;
}

.subscribe_btn {
	font-family: "Outfit", serif;
	width: 88px;
	height: 40px !important;
	border: none;
	background: #fd5901;
	border-radius: 6.624px;
	color: #fff;
}

form {
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	#email-input {
		border-radius: 20px 0 0 20px;

		@media (max-width: 768px) {
			border-radius: 20px;
		}
	}
	#subscribe-btn {
		border-radius: 0 20px 20px 0;

		@media (max-width: 768px) {
			border-radius: 20px;
		}
	}
}

.copyright_text {
	font-family: "Outfit", serif;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3rem;
}

.copyright_text h5 {
	margin: 0;
	font-family: "Outfit", serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height */

	display: flex;
	align-items: center;
	letter-spacing: -0.24px;

	color: #bdb9b9;
}

.footer2-social-icon {
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		fill: #050505;
	}
}

@media screen and (max-width: 768px) {
	.footer_container {
		.footer_list_item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.list-unstyled {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
