.add-product-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  
    .add-product-popup-content {
      background: var(--white-to-grey);
      padding: 2rem;
      border-radius: 0.5rem;
      width: 90%;
      max-width: 600px;
      max-height: 90vh;
      overflow-y: auto;
      scrollbar-width: 0.2rem; // For Firefox
      //-ms-overflow-style: none; // For Internet Explorer and Edge
    
      &::-webkit-scrollbar {
        width: 0.2rem;
      }
  
      h2 {
        color: var(--text-color);
        margin-bottom: 1.5rem;
        text-align: center;
      }
  
      .add-product-form-group {
        margin-bottom: 1.5rem;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          color: var(--text-color);
        }
  
        input[type="text"],
        input[type="url"],
        textarea {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid var(--text-color);
          border-radius: 0.5rem;
          background: var(--background-color);
          color: var(--text-color);
          &::placeholder {
            color: var(--placeholder-color);
          }
  
          &:focus {
            outline: none;
            border-color: #fd5901;
          }
        }
  
        &.add-product-checkbox {
          label {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
        }
  
        .add-product-url-input {
          display: flex;
          gap: 0.5rem;
          margin-bottom: 0.5rem;
  
          .add-product-remove-url {
            background: #ff4444;
            color: white;
            border: none;
            border-radius: 0.25rem;
            width: 2rem;
            cursor: pointer;
          }
        }
  
        .add-product-add-url {
          background: none;
          border: 1px dashed var(--border-color);
          color: var(--text-color);
          padding: 0.5rem;
          width: 100%;
          border-radius: 0.5rem;
          cursor: pointer;
          margin-top: 0.5rem;
  
          &:hover {
            border-color: #fd5901;
          }
        }
      }
  
      .add-product-button-group {
        display: flex;
        gap: 1rem;
        margin-top: 2rem;
  
        button {
          flex: 1;
          padding: 0.75rem;
          border-radius: 0.5rem;
          border: none;
          cursor: pointer;
          font-weight: 500;
  
          &.add-product-cancel-btn {
            background: var(--background-color);
            color: var(--text-color);
            border: 1px solid var(--border-color);
          }
  
          &.add-product-submit-btn {
            background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
            color: white;
  
            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
        }
      }
  
      .add-product-close-btn {
        position: sticky;
        left: 100%;
        background: none;
        border: none;
        font-size: 1.5rem;
        color: var(--text-color);
        cursor: pointer;
      }
    }
  }