.chat_search_container {
  border-radius: var(--border-radius);
  // border: 1px solid #cfcfcf;
  // background: var(--white-to-grey);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  // padding: 15px 20px;

  @media (max-width: 820px) {
    border-radius: 15px;
    gap: 10px;
  }

  .chat_title {
    color: var(--bs-body-color);
    font-size: 28px;
    font-weight: 600;
    margin: 0;

    @media (min-width: 821px) {
      margin-top: 40px;
    }
  }

  .search_chat {
    border-radius: 20px;
    // background: #eaeef2;
    background: var(--white-to-grey);
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    @media (max-width: 820px) {
      border-radius: 15px;
    }

    input {
      flex-grow: 1;
      border: none;
      // background: #eaeef2;
      background: var(--white-to-grey);
      border-radius: 20px;
      outline: none;
      padding: 5px 15px;
    }
  }

  .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.search-results-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--white-to-grey);
}

.search_results {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  background-color: var(--white-to-grey);

  .single_result:nth-child(6),
  .single_result:nth-child(13) {
    border-bottom: none;
  }
  a {
    text-decoration: none;
    &:hover {
      color: var(--currentTheme-dark) !important;
    }
  }
  .single_result {
    border-bottom: 1px solid lightgrey;
    padding: 2px 0;
    transition: padding-left ease 0.2s;
    &:hover {
      // background-color: rgb(221, 221, 221);
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}
.theme_switch_chat{
  margin-left: 4rem;
  margin-top: -0.3rem;
}