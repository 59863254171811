.startup_sidebar {
	width: 65px;
	max-width: 65px;
	height: 100dvh;
	margin: 0;
	padding: 0;
	transition: all 1000ms ease-in-out !important;
	// hide scroll bar
	&::-webkit-scrollbar {
		display: none;
		width: 0px;
	}

	.sidebar-main {
		width: 20%;
		height: 100vh;
		background-color: var(--white-to-grey);
		position: absolute;
		border-radius: 8px 8px 8px 0px;
		border: 2px solid red;
	}

	.pro-sidebar .pro-menu a {
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		color: var(--d-l-grey);
	}

	.hr-above-support {
		color: rgba(173, 173, 173, 0.2);
		border: 1px solid rgba(173, 173, 173, 0.2);
	}

	.pro-item-content {
		display: contents;
		justify-content: center;
		align-items: center;
		color: var(--d-l-grey);
		background-color: transparent !important;
		a {
			&:link {
				color: var(--d-l-grey);
			}
			svg {
				color: var(--d-l-grey);
			}
		}
		svg,
		img {
			height: 20px;
		}
		svg {
			height: 25px !important;
		}
		span {
			margin-left: 24px;
			font-weight: 400;
		}
	}

	#header {
		width: max-content;
		max-width: 220px;
		overflow: hidden;
		transition: all 1000ms ease-in-out;

		.pro-sidebar {
			width: 100%;
			min-width: 100%;
			overflow-y: auto;
			// max-height: 80vh;
			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: rgba(
					211,
					211,
					211,
					0.555
				); /* Set the background color for the thumb (the draggable part) */
			}
		}

		.pro-sidebar.collapsed {
			width: 65px;
			min-width: 40px;
			max-width: 65px;

			@media (max-width: 480px) {
				transform: translateX(-100%);
			}
		}
		.pro-sidebar-inner {
			// border-radius: 20px;
			background-color: var(--white-to-grey);
			box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
		}

		.pro-sidebar-inner .pro-sidebar-layout {
			overflow-y: hidden;
		}

		.pro-sidebar-inner .pro-sidebar-layout .logotext p {
			font-size: 20px;
			padding: 0 20px;
			color: #000;
			font-weight: bold;
		}
	}

	.close-menu {
		position: sticky;
		z-index: 1051 !important;
		top: 4.7rem;

		display: flex;
		justify-content: end;

		transition: all 500ms ease-in-out !important;
		&.close-btn-collapsed {
			top: 20px;
			height: 25px;
			img {
				height: 25px;
			}
		}
	}

	.logotext {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
		padding-top: 15px;
		padding-bottom: 15px;

		h3 {
			height: 23px;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 500;
			font-size: 1rem !important;
			line-height: 22px;
			margin: 5px 0;
			/* identical to box height */
			// color: #000000;

			.top-voice-badge {
				position: relative;
				display: flex;
				align-items: center;
				// cursor: pointer;

				.top-voice-icon {
					width: 1.2rem;
					height: 1.2rem;
					fill: #c0c0c0;
				}
				.top-voice-text {
					width: 70px;
					position: absolute;
					top: -20px;
					left: 10px;
					color: #fff;
					font-size: 0.8rem;
					font-weight: 500;
					opacity: 0;
					transition: opacity 0.3s ease;
				}

				&:hover .top-voice-text {
					opacity: 1;
				}
			}
		}
		h4 {
			margin: 0;
			font-size: 12px !important;
			// margin-bottom: 20px;
		}
	}

	.create_post {
		width: 100%;
		height: 40px;
		border: none;
		background: #fd5901;
		border-radius: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #ffffff;
	}
	.create_post img {
		height: 15px !important;
		margin-left: 5px;
	}
	.plus_btn {
		width: 40px;
		height: 40px;
		left: 90px;
		top: 329px;
		border: none;
		background: #fd5901;
		border-radius: 10px;
		margin-left: -7px;
	}
	#header .pro-sidebar-inner .pro-sidebar-layout ul {
		margin: auto;
		a {
			:link {
				color: var(--d-l-grey) !important;
			}
			svg {
				color: var(--d-l-grey);
			}
		}
	}

	#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
		color: #000;
		margin: 10px 0px;
		font-weight: bold;
		justify-content: flex-start;
		background-color: transparent !important;
	}

	#header
		.pro-sidebar-inner
		.pro-sidebar-layout
		ul
		.pro-inner-item
		.pro-icon-wrapper {
		color: #000;
		border-radius: 3px;
	}

	#header
		.pro-sidebar-inner
		.pro-sidebar-layout
		ul
		.pro-inner-item
		.pro-icon-wrapper
		.pro-item-content {
		color: #000;
	}

	#header .pro-sidebar-inner .pro-sidebar-layout .active {
		background: transparent !important;
		* {
			color: var(--currentTheme) !important;
		}
	}

	#header .pro-sidebar-inner .pro-sidebar-layout .active-item {
		padding: 1px;
		transition: background-color 0.3s ease, transform 0.3s ease, width 0.3s ease;

		&:hover {
			transform: translateX(5px);
		}
	}

	#header .logo {
		padding: 20px;
	}

	// CSS for Community in sidebar
	.sidebar__community {
		details {
			font-size: inherit;
			font-weight: 400;
			color: var(--d-l-grey);
			position: relative;

			summary {
				list-style-type: none;
				font-size: inherit;
				color: inherit;
				transition: margin 0.15s ease-out;
			}

			&[open] summary {
				margin-bottom: 10px;
				color: #fd5901;
			}

			.sidebar__community__btn {
				outline: none;
				border: none;
				background-color: transparent;
				padding-block: 0.25em;
				padding-inline: 0;

				color: #fd5901;
				font-size: 0.85rem;
				font-weight: 500;
				text-align: left;
			}
		}

		// For responsiveness of Modal component
		.modal {
			margin-top: 20%;

			@media (min-width: 500px) {
				margin-top: 15%;
			}

			@media (min-width: 575px) {
				margin-top: 13.5%;
			}

			@media (min-width: 768px) {
				margin-top: 10%;
			}

			@media (min-width: 769px) {
				margin-top: 0;
			}
		}
	}
	.sidebar__meeting {
		details {
			font-size: inherit;
			font-weight: 400;
			color: var(--d-l-grey);
			position: relative;
			summary {
				list-style-type: none;
				font-size: inherit;
				color: inherit;
				transition: margin 0.15s ease-out;
			}

			&[open] summary {
				margin-bottom: 10px;
				color: #fd5901;
			}

			.sidebar__meeting__btn {
				outline: none;
				border: none;
				background-color: transparent;
				padding-block: 0.25em;
				padding-inline: 0;
				color: #fd5901;
				font-size: 0.85rem;
				font-weight: 500;
				text-align: left;
				text-decoration: underline;

				// &:hover {
				// 	text-decoration: underline; // Optional hover effect
				// }
			}
		}
	}
}

@media (min-width: 820px) {
	.mobile-onboarding {
		display: none !important;
	}
}

@media (max-width: 820px) {
	.mobile-onboarding {
		margin-left: 1rem;
	}
}

.startup_sidebar
	#header
	.pro-sidebar-inner
	.pro-sidebar-layout
	ul
	.pro-inner-item {
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 10px 15px;
	cursor: pointer;
	color: #000;
	margin: 10px 0px;
	font-weight: bold;
	background-color: transparent !important;

	a {
		margin-left: 7px;
	}
}
