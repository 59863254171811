/* afterregisterpopup.scss */
.investor_after_register_popup {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }

  .popup-content {
    background-color: #fff;
    padding: 40px;
    text-align: center;
    border-radius: var(--border-radius);
    position: relative;
    width: 40%;
    flex-direction: column;

    img {
      max-width: 60px;
      margin: 0 auto;
    }
    
    h1 {
      color: #000;
      font-family: Poppins;
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: #373737;
      // text-align: justify;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  /* Center the buttons */
  button {
    margin: 10px;
  }

  /* Style the close button */
  .investor_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #000;
  }

  .investor_ok_button {
    width: 20%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #d3f36b;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .investor_after_register_popup {
    position: relative;

    .popup-content {
      background-color: #fff;
      padding: 40px;
      text-align: center;
      border-radius: var(--border-radius);
      position: relative;
      width: 80%;

      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #373737;
        // text-align: justify;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    button {
      margin: 10px;
    }

    /* Style the close button */
    .investor_close-button {
      width: 20px;
      position: absolute;
      top: 0px;
      right: 10px;
      background: none;
      border: none;
      font-size: 14px;
      cursor: pointer;
      color: #000;
    }

    .investor_ok_button {
      width: 20%;
      height: 30px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #d3f36b;
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
    }
  }
}
