// // SharePopup.scss
// .share-popup {
// 	position: relative;

// 	.share-button {
// 		display: flex;
// 		align-items: center;
// 		gap: 8px;
// 		padding: 8px 16px;
// 		border: 1px solid #e2e8f0;
// 		border-radius: 6px;
// 		background: white;
// 		cursor: pointer;
// 		transition: all 0.2s;

// 		&:hover {
// 			background: #f7fafc;
// 		}
// 	}

// 	.popup-overlay {
// 		position: fixed;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		bottom: 0;
// 		background: rgba(0, 0, 0, 0.5);
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		z-index: 1000;
// 	}

// 	.popup-content {
// 		background: white;
// 		border-radius: 8px;
// 		padding: 24px;
// 		width: 90%;
// 		max-width: 400px;
// 		position: relative;

// 		.popup-header {
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			margin-bottom: 20px;

// 			h2 {
// 				margin: 0;
// 				font-size: 1.25rem;
// 				font-weight: 600;
// 			}

// 			.close-button {
// 				background: none;
// 				border: none;
// 				font-size: 24px;
// 				cursor: pointer;
// 				padding: 0;
// 				color: #666;

// 				&:hover {
// 					color: #000;
// 				}
// 			}
// 		}
// 	}

// 	.social-icons {
// 		display: flex;
// 		justify-content: center;
// 		gap: 16px;
// 		margin-bottom: 24px;

// 		.social-button {
// 			width: 40px;
// 			height: 40px;
// 			border-radius: 50%;
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 			border: 1px solid #e2e8f0;
// 			background: white;
// 			cursor: pointer;
// 			transition: all 0.2s;

// 			svg {
// 				width: 20px;
// 				height: 20px;
// 			}

// 			&.twitter {
// 				color: #1da1f2;
// 				&:hover {
// 					background: #1da1f2;
// 					color: white;
// 				}
// 			}

// 			&.facebook {
// 				color: #4267b2;
// 				&:hover {
// 					background: #4267b2;
// 					color: white;
// 				}
// 			}

// 			&.linkedin {
// 				color: #0077b5;
// 				&:hover {
// 					background: #0077b5;
// 					color: white;
// 				}
// 			}

// 			&.whatsapp {
// 				color: #25d366;
// 				&:hover {
// 					background: #25d366;
// 					color: white;
// 				}
// 			}
// 		}
// 	}

// 	.copy-link {
// 		.copy-button {
// 			width: 100%;
// 			display: flex;
// 			align-items: center;
// 			justify-content: space-between;
// 			padding: 8px 16px;
// 			border: 1px solid #e2e8f0;
// 			border-radius: 6px;
// 			color: #666;
// 			background: white;
// 			cursor: pointer;
// 			transition: all 0.2s;

// 			&:hover {
// 				background: #f7fafc;
// 			}

// 			.url-text {
// 				overflow: hidden;
// 				text-overflow: ellipsis;
// 				white-space: nowrap;
// 				margin-right: 12px;
// 			}
// 		}
// 	}

// 	.whatsapp-title {
// 		margin: 1rem 0;
// 		display: flex;
// 		flex-direction: column;
// 		gap: 0.5rem;

// 		label {
// 			font-size: 0.9rem;
// 			color: #666;
// 		}

// 		.whatsapp-title-input {
// 			padding: 0.5rem;
// 			border: 1px solid #e2e8f0;
// 			border-radius: 6px;
// 			width: 100%;
// 			font-size: 0.9rem;
// 			background-color: white;
// 			color: #666;

// 			&:focus {
// 				outline: none;
// 				border-color: #0077ff;
// 			}

// 			&::placeholder {
// 				color: #666;
// 			}
// 		}
// 	}

// 	.copy-alert {
// 		margin-top: 16px;
// 		padding: 12px;
// 		background: #4caf50;
// 		color: white;
// 		border-radius: 6px;
// 		text-align: center;
// 		animation: fadeIn 0.3s ease;
// 	}
// }

// @keyframes fadeIn {
// 	from {
// 		opacity: 0;
// 		transform: translateY(-10px);
// 	}
// 	to {
// 		opacity: 1;
// 		transform: translateY(0);
// 	}
// }

:root {
	--bg-color: #ffffff;
	--text-color: #333333;
	--text-color-secondary: #848587;
	--accent-color: #fd5901;
	--background-color: #fff;
	--background-color-secondary: #37344433;
	--background-btn: rgba(52, 55, 68, 0.2);
}

.dark-theme {
	--bg-color: #1e1f24;
	--text-color: #ffffff;
	--text-color-secondary: #9ca3af;
	--accent-color: #fd5901;
	--background-color: #1e1f24;
	--background-color-secondary: rgba(6, 6, 6, 0.2);
	--background-btn: rgb(30, 30, 30);
}
.investor-theme {
	--accent-color: var(--bs-investor) !important;
}

.share-popup {
	position: relative;
	background-color: var(--background-color);
	color: var(--text-color);

	.share-button {
		display: flex;
		align-items: center;
		gap: 8px;
		padding: 8px 16px;
		border: 1px solid var(--background-color-secondary);
		border-radius: 6px;
		background: var(--background-color);
		color: var(--text-color);
		cursor: pointer;
		transition: all 0.2s;

		&:hover {
			background: var(--background-color-secondary);
		}
	}

	.popup-overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
	}

	.popup-content {
		background: var(--bg-color);
		border-radius: 8px;
		padding: 24px;
		width: 90%;
		max-width: 400px;
		position: relative;
		color: var(--text-color);

		.popup-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;

			h2 {
				margin: 0;
				font-size: 1.25rem;
				font-weight: 600;
				color: var(--text-color);
			}

			.close-button {
				background: none;
				border: none;
				font-size: 24px;
				cursor: pointer;
				padding: 0;
				color: var(--text-color-secondary);

				&:hover {
					color: var(--accent-color);
					transform: scale(1.2);
				}
			}
		}
	}

	.social-icons {
		display: flex;
		justify-content: center;
		gap: 16px;
		margin-bottom: 24px;

		.social-button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid var(--background-color-secondary);
			background: var(--background-color);
			cursor: pointer;
			transition: all 0.2s;

			svg {
				width: 20px;
				height: 20px;
			}

			&.twitter {
				color: #1da1f2;
				&:hover {
					background: #1da1f2;
					color: white;
				}
			}

			&.facebook {
				color: #4267b2;
				&:hover {
					background: #4267b2;
					color: white;
				}
			}

			&.linkedin {
				color: #0077b5;
				&:hover {
					background: #0077b5;
					color: white;
				}
			}

			&.whatsapp {
				color: #25d366;
				&:hover {
					background: #25d366;
					color: white;
				}
			}
		}
	}

	.copy-link {
		.copy-button {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 16px;
			border: 1px solid var(--background-color-secondary);
			border-radius: 6px;
			color: var(--text-color-secondary);
			background: var(--background-color);
			cursor: pointer;
			transition: all 0.2s;

			&:hover {
				background: var(--background-color-secondary);
			}

			.url-text {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin-right: 12px;
				color: var(--text-color);
			}
		}
	}

	.whatsapp-title {
		margin: 1rem 0;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		label {
			font-size: 0.9rem;
			color: var(--text-color-secondary);
		}

		.whatsapp-title-input {
			padding: 0.5rem;
			border: 1px solid var(--background-color-secondary);
			border-radius: 6px;
			width: 100%;
			font-size: 0.9rem;
			background-color: var(--background-color);
			color: var(--text-color);

			&:focus {
				outline: none;
				border-color: var(--accent-color);
			}

			&::placeholder {
				color: var(--text-color);
			}
		}
	}

	.copy-alert {
		margin-top: 16px;
		padding: 12px;
		background: #4caf50;
		color: white;
		border-radius: 6px;
		text-align: center;
		animation: fadeIn 0.3s ease;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
