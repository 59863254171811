.createpost__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh !important;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	// backdrop-filter: blur(5px);
}

.createpost__popup {
	box-sizing: border-box;
	position: fixed;
	top: 50%;
	left: 50%;
	background-color: var(--white-to-grey) !important;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000;
	border-radius: 10px;

	@media (max-width: 820px) {
		border-radius: 0px;
		width: 80dvw;
	}
	@media (min-width: 1024px) {
		width: 70dvw;
		min-height: 50dvh;
		height: 80dvh;
	}
	@media (min-width: 1200px) {
		width: 60dvw;
		min-height: 50dvh;
		height: 80dvh;
	}
	@media (max-width: 480px) {
		border-radius: 0px;
		width: 100dvw;
	}

	.createpost__container {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		max-height: 100dvh;
		padding: 0.5rem;
		color: white;
		pointer-events: auto;
		background-color: var(--bs-modal-bg);
		background-clip: padding-box;
		border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
		border-radius: var(--bs-modal-border-radius);
		outline: 0;
		.createpost__body__footer {
			height: 100%;
			max-height: calc(100dvh - 103px);
			overflow-y: scroll;
			// hide scrollbar
			&::-webkit-scrollbar {
				display: none;
				width: 0;
			}
		}

		@media (max-width: 480px) {
			width: 100dvw;
			height: 100dvh;
			justify-content: space-between;
			border-radius: 0px;
		}

		.createpost__header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 10px;

			.ceatepost__profile {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding: 15px;

				span {
					margin-left: 10px;

					h2 {
						font-size: 1rem;
						font-family: Poppins;
						font-weight: 500;
						line-height: 160%;
						letter-spacing: 1.144px;
						margin: 0;
						color: var(--d-l-grey);
					}

					h6 {
						color: #a1a1a1;
						font-size: 10.306px;
						font-family: Poppins;
						line-height: 160%;
						letter-spacing: 0.572px;
					}
				}
			}
		}

		.createpost__body {
			position: relative;
			flex: 1 1 auto;
			width: 100%;
			height: calc(100dvh - 400px);
			// padding: var(--bs-modal-padding);
			
			/* Mention Dropdown Styles */
			.suggestions-dropdown {
				position: absolute;
				background-color: white;
				border: 1px solid #ddd;
				box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
				z-index: 10;
				max-height: 150px;
				overflow-y: auto;
				width: 200px;
				border-radius: 4px;
			}
			
			.suggestion-item {
				padding: 8px 12px;
				cursor: pointer;
				transition: background-color 0.2s ease;
			
				&:hover {
				background-color: #f0f0f0;
				}
			
				&:active {
				background-color: #d0d0d0;
				color: #000;
				}
			}
		}

		.createpost__footer {
			display: flex;
			flex-direction: column;

			.createpost__footer__container {
				width: 480px;
				height: 65px;
				border-radius: var(--border-radius);
				border: 0.894px solid #e0e0e0;
				background-color: var(--bs-light);
				display: flex;
				justify-content: space-between;
				align-items: center;

				@media (min-width: 1200px) {
					width: 100%;
				}
				@media (max-width: 4800px) {
					width: 100%;
				}

				.left_buttons {
					display: flex;
					gap: 10px;
					padding: 10px;
					position: relative;

					.white_button {
						border: none;
						padding: 0;
						width: 48.114px;
						height: 48.114px;
						border-radius: 50%;

						img {
							width: 38px;
							height: 38px;
						}
					}
					.tooltip-text {
						visibility: hidden;
						position: absolute;
						z-index: 1;
						width: 60px;
						color: white;
						background-color: #192733;
						padding: 2px;
						font-size: 10px;
						border-radius: 5px;
						text-align: center;
					}

					.hover-text:hover .tooltip-text {
						visibility: visible;
					}

					.top {
						bottom: 100%;
						left: 0;
						transform: translateY(-5px);
					}
					.top1 {
						bottom: 100%;
						left: 58px;
						transform: translateY(-5px);
					}
					.top2 {
						bottom: 100%;
						left: 116px;
						transform: translateY(-5px);
					}
					.top3 {
						bottom: 100%;
						left: 174px;
						transform: translateY(-5px);
					}
				}
				.post_button_container {
					padding: 10px;

					.post_button {
						color: #fff;
						border: none;
						border-radius: 26.823px;
						background: #fd5901;
						width: 116px;
						height: 40px;
						flex-shrink: 0;
					}
				}
			}
		}
	}
}

.image-cropper-container {
	position: relative;
	display: inline-block;
	margin-top: 20px;
}
.image-cropper-container-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 1rem;
	background-color: var(--bs-gray-900);
}

.image-cropper {
	position: relative;
	width: 200px;
	height: 200px;
	overflow: hidden;
}

.image-cropper img {
	max-width: 100%;
	height: auto;
	display: block;
}

.image-cropper-container {
	border: 1px solid #ccc;
	border-radius: 5px;
}

@keyframes slideFromTop {
	from {
		transform: translateY(-100%);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@media (max-width: 767px) {
	.create_post_modal {
		position: fixed;
		// top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 10000;
	}

	.createpost_modal_footer {
		display: flex;
		width: 100%;
		margin: auto;

		.modal_footer_container {
			width: 100%;
			min-height: 65px;
			height: auto;
			flex-shrink: 0;
			border-radius: var(--border-radius);
			border: 0.894px solid var(bs-border-color);
			// background: #eaeef2;
			background: var(--bs-light);

			/* footer */
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			.left_buttons {
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
				padding: 10px;
				margin-inline: auto;

				.white_button {
					// background-color: white;
					border: none;
					padding: 0;
					width: 42.114px;
					height: 42.114px;
					border-radius: 50%;

					img {
						width: 38px;
						height: 38px;
					}
				}
			}

			.post_button_container {
				margin-inline: auto;
			}
		}
	}
}

@media (max-width: 575px) {
	.create_post_modal {
		width: 100%;
		height: 100%;
		.modal-dialog {
			margin-inline: 0;
		}

		.modal-body {
			padding-inline: 0;
		}
	}
}

.share-linkedin {
	display: flex;
	align-items: center;
	margin-top: 10px;

	input[type="checkbox"] {
		margin-right: 5px;
	}
}

.image-preview-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	gap: 10px;
	padding: 10px;
	width: 100%;
}

.image-preview-item {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
	border-radius: 4px;
	overflow: hidden;
}

.remove-image-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	background: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		background: rgba(0, 0, 0, 0.7);
	}
}

.poll-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white-to-grey);
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	z-index: 10001;
	width: 90%;
	max-width: 400px;

	h3 {
		margin-bottom: 20px;
		color: var(--d-l-grey);
	}

	.poll-options-container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-bottom: 20px;

		.poll-option-input {
			display: flex;
			gap: 10px;
			align-items: center;

			input {
				flex: 1;
				padding: 8px;
				border: 1px solid var(--bs-border-color);
				border-radius: 4px;
				background-color: var(--bs-light);
				color: var(--d-l-grey);
			}

			button {
				background: none;
				border: none;
				color: #ff4444;
				cursor: pointer;
				padding: 5px;

				&:hover {
					color: #cc0000;
				}
			}
		}
	}

	.poll-popup-buttons {
		display: flex;
		justify-content: flex-end;
		gap: 10px;

		button {
			padding: 8px 16px;
			border-radius: 4px;
			border: none;
			cursor: pointer;

			&.add-option {
				background-color: var(--bs-light);
				color: var(--d-l-grey);
			}

			&.save-poll {
				background-color: #fd5901;
				color: white;
			}
		}
	}
}

.poll-popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000;
}

.custom-quill {
	height: 100%;
	border: none;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.custom-quill .ql-container {
	border: none !important;
	min-height: 100px !important;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.ql-editor.ql-blank::before{
	color: var(--grey-to-white) !important;
}

.custom-quill .ql-editor {
	background-color: inherit;
	color: var(--d-l-grey) !important;
	max-width: 60dvw;
	min-height: 100px !important;
	height: calc(80dvh - 400px);

	display: flex;
	flex-wrap: wrap;
	overflow: scroll;
	word-wrap: normal;

	&::-webkit-scrollbar {
		display: none;
		width: 0;
	}

	@media (max-width: 820px) {
		max-width: 100dvw;
		height: calc(100dvh - 400px);
	}
	@media (max-width: 480px) {
		max-width: 100dvw;
		height: calc(100dvh - 400px);
	}
}

.custom-quill .ql-container .ql-snow {
	height: 100%;
}

.custom-quill .ql-editor.ql-blank {
	min-height: 100px !important;
}

.custom-quill .ql-placeholder {
	color: var(--placeholder-color) !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 1;
}

.video-preview {
	width: 200px;
	height: 150px;
	// @media (min-width: 1200px) {
	// 	width: 50%;
	// 	height: 25dvh;
	// }
	// @media (max-width: 480px) {
	// 	// width: 50%;
	// 	height: 250px;
	// }
}
.allow-multiple-checkbox {
	color: var(--d-l-grey);
	margin: 15px 0;
	display: flex;
	align-items: center;

	label {
		display: flex;
		align-items: center;
		cursor: pointer;
		gap: 10px;
	}

	input[type="checkbox"] {
		appearance: none;
		-webkit-appearance: none;
		width: 20px;
		height: 20px;
		border: 2px solid var(--d-l-grey);
		border-radius: 50%;
		outline: none;
		cursor: pointer;
		position: relative;
		margin: 0;

		&:checked {
			background-color: #fd5901;
			border-color: #fd5901;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8px;
				height: 8px;
				background-color: white;
				border-radius: 50%;
			}
		}

		&:hover {
			border-color: #fd5901;
		}
	}
}


.community-color {
	background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%) !important;
	color: white;
}