.single-post-page {
  display: flex;
  gap: 10px;
  // margin-bottom: 20px;
  justify-content: space-around;

  .loader {
    margin: 0 auto;
  }

  .feedpostcard_main_container {
    @media (max-width: 820px) {
      flex-basis: 100%;
    }
    flex-basis: 50%;
  }
  .right-sidebar {
    flex-basis: 30%;
  }
}
