.inputCard {
    border-radius: 30px;
    margin: 0 auto;
    width: 26rem;
    height: 17rem;
    padding: 20px;
    background: linear-gradient(239.92deg, #292929 -0.03%, #1E1C1C 100.05%);
    color: #fff;
    box-sizing: border-box;
  
    .suggested {
      p {
        font-size: 1rem;
        padding-bottom: 0.9rem;
      }
      font-size: 1.2em;
      margin-bottom: 20px;
    }
  
    .cardOption {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
  
      input[type='radio'] {
        margin-right: 10px;
      }
  
      label {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
  
      .cardInfo {
        display: flex;
        flex-direction: column;
        padding: 5px;
        border-radius: 15px;
        width: 100%;
        box-sizing: border-box;
      }
  
      .cardType {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
  
        span{
          font-size: 1rem;
        }
  
        img {
          width: 40px;
          height: auto;
        }
      }
  
      .cardNumber {
        margin-top: 5px;
        font-size: 0.9rem;
      }
  
      .cvv {
        display: flex;
        align-items: center;
        margin-top: 10px;
  
        input {
          width: 5rem;
          height: 2rem;
          padding: 5px;
          font-size: 1rem;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 0;
          background: none;
          color: #fff;
          box-sizing: border-box;
  
          &:focus {
            outline: none;
            border-bottom: 1px solid #fff;
          }
        }
  
        i {
          margin-left: 5px;
          font-size: 1rem;
          color: #ccc;
        }
      }
    }
  }
  
  .inputCardTwo {
    border-radius: 30px;
    margin: 0 auto;
    width: 26rem;
    height: auto;
    padding: 20px;
    color: #fff;
    box-sizing: border-box;
  
    .suggested {
      p {
        margin-bottom: 10px;
        font-size: 1rem;
        
      }
      font-size: 1.2em;
      margin-bottom: 20px;
    }
  
    .cardOption {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
  
      input[type='radio'] {
        margin-right: 10px;
      }
  
      label {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
  
      .cardInfo {
        display: flex;
        flex-direction: column;
        padding: 5px;
        border-radius: 15px;
        width: 100%;
        box-sizing: border-box;
      }
  
      .cardType {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
  
        span{
          font-size: 1rem;
        }
  
        img {
          width: 40px;
          height: auto;
        }
      }
  
      .cardNumber {
        margin-top: 5px;
        font-size: 0.9rem;
      }
  
      .cvv {
        display: flex;
        align-items: center;
        margin-top: 10px;
  
        input {
          width: 5rem;
          height: 2rem;
          padding: 5px;
          font-size: 1rem;
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 0;
          background: none;
          color: #fff;
          box-sizing: border-box;
  
          &:focus {
            outline: none;
            border-bottom: 1px solid #fff;
          }
        }
  
        i {
          margin-left: 5px;
          font-size: 1rem;
          color: #ccc;
        }
      }
    }
  }
  
  .inputCardOthers {
    border-radius: 30px;
    margin: 10px auto; // Add some margin to separate the containers
    width: 90%; // Adjust width if necessary
    height: 4.5rem;
    background: #222121;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center; // Align items vertically in the center
    padding: 0 15px;
  
    .suggested {
      display: flex;
      justify-content: space-between;
      align-items: center; // Align items vertically in the center
      width: 100%;
  
      .cardType {
        display: flex;
        align-items: center; // Align items vertically in the center
        flex-grow: 1; // Allow cardType to take up all available space except the arrow
        margin-top: 15px;
  
        img {
          width: 40px;
          height: 2rem;
          margin-right: 10px;
        }
  
        span {
          font-size: 1rem;
        }
      }
  
      #arrow {
        font-size: 1.5rem;
        margin-left: auto; // Push arrow to the right
        align-self: center; // Ensure the arrow is vertically centered
      }
    }
  }
  
  
  
  
  
  
  
  
  .subscriber_modal-header {
  padding: 10px;
  }
  
  .subscription_head {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  text-align: center; // Center the text horizontally
  
  span {
    text-align: center;
  
    h2 {
      font-size: 1.5rem;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      line-height: 160%;
      letter-spacing: 1.144px;
      margin: 0;
      color: #ffffff;
    }
  
    h6 {
      color: #999;
      font-size: 1rem;
      font-family: Poppins, sans-serif;
      line-height: 160%;
      letter-spacing: 0.572px;
      margin-top: 10px;
    }
  }
  }
  
  .payment_success_plans {
    display: flex;
    justify-content: space-around;
    //   background: #5A5A5A1A;
    width: 28rem;
    height: fit-content;
    border-radius: 20px;
    margin: 0px 15px;
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    flex-wrap: wrap;

  // PaymentSuccess.scss
    .payment-success {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .detail-total{
            span{
                font-size: 1rem;
            }
            font-size: 1.4rem;
        }

        .headSuccess{
            margin-top: 3rem;
        }

        background: linear-gradient(180deg, #2E2E2E 0%, #1A1A1A 100%);
        color: white;
        padding: 20px;
        border-radius: 10px;
        max-width: 400px;
        margin: auto;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
  
        .icon-container {
        background-color: #3a3a3a;
        position: absolute;
        top: -25px;
        left: 140px;
        border-radius: 50%;
        display: inline-block;
        padding: 10px;
        margin-bottom: 20px;
  
      .icon {
        color: #00cc66;
        font-size: 30px;
      }
    }
  
    h2 {
      margin-bottom: 10px;
      font-size: 24px;
    }
  
    p {
      margin-bottom: 20px;
      font-size: 16px;
    }
  
    .payment-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      padding: 10px;
      border-radius: 10px;
  
      .detail-item {
        .detail-head{
            margin-top: 15px;
        }
        width: 100%;
        height: 5rem;
        border: 1px solid #595959;
        border-radius: 20px;
        justify-content: space-between;
        margin-bottom: 10px;

  
        span {
          color: #ccc;
        }
  
        strong {
          font-weight: bold;
        }
      }
    }
  
    .pdf-button {
      background-color: transparent;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        color: rgb(213, 213, 213);
      }
    }
  }
  
  
  .plan {
    background: #222;
    padding: 20px;
    border-radius: 10px;
    width: 75%;
    height: 60vh;
    margin: 10px;
    text-align: center;
    color: white;
  
    &:hover {
      background: #555;
    }
  
    .headingContent {
      font-size: 2rem;
      margin-bottom: 10px;
      border: none;
      text-align: start;
    }
  
    .price {
      font-size: 2.25rem;
      margin: 10px 0;
      text-align: start;
  
      span {
        font-size: 1.5rem;
      }
    }
  
    .description {
      font-size: 1rem;
      margin: 10px 0 20px;
    }
  
    .features {
      text-align: left;
      margin-bottom: 20px;
  
      p {
        margin: 5px 0;
      }
    }
  
    .purchase_button {
      background: transparent;
      color: white;
      padding: 10px 20px;
      border: 2px solid #adff2f;
      border-radius: 30px;
      cursor: pointer;
      transition: background 0.3s;
  
      &:hover {
        color: black;
        background: #adff2f;
      }
    }
  }
  
  .plan.pro {
    background: #444;
    &:hover {
      background: #555;
    }
  }
  
  .plan.advance {
    background: #444;
    &:hover {
      background: #555;
    }
  }
  }
  
  .subscriber-background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  z-index: 9999;
  }
  
  .payment_post_modal {
  --offset: 3px;
  background: rgb(33, 33, 33);
  border-radius: 10px;
  position: fixed;
  width: 32rem;
  height: 42rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 85%;
  z-index: 10000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2.76px solid;
  border-image: linear-gradient(207.22deg, #595959 -0.01%, #1E1B19 97.73%) 1;
  box-sizing: border-box;
  
  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 100%;
  }
  
  .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    /* Hide scrollbar */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .done_button {
    background: #adff2f;
    color: #333;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s;
    display: block;
    width: 100px;
    padding: 10px 0px;
    margin: 10px auto;
    max-width: 100%;
  
    &:hover {
      background: #9de02f;
    }
  }
  
  .closebar {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s;
  
    &:hover {
      transform: rotate(180deg);
    }
  }
  }
  
  @media (max-width: 768px) {
  .inputCard {
    width: 90%;
    height: auto;
    padding: 10px;
  
    .suggested {
      p {
        font-size: 0.9rem;
        padding-bottom: 2rem;
      }
      font-size: 1em;
    }
  
    .cardOption {
      input[type='radio'] {
        margin-right: 5px;
      }
  
      .cardInfo {
        padding: 5px;
      }
  
      .cardType {
        .cardLogo {
          width: 30px;
        }
      }
  
      .cardNumber {
        font-size: 0.8em;
      }
  
      .cvv {
        input {
          width: 40px;
          padding: 3px;
        }
      }
    }
  }
  
  .payment_post_modal {
    width: 95vw;
    height: auto;
    max-height: 95vh;
  }
  
  .pay_button {
    width: 90%;
  }
  }
  