.mobile-bottom-toolbar {
	position: fixed;
	bottom: 0;
	right: 50%;
	transform: translateX(50%);
	box-sizing: border-box;
	background: var(--white-to-dgrey);
	z-index: 99;

	.nav-link-text {
		font-size: 9px;
		font-weight: 600;
	}

	svg {
		flex-grow: 1;
	}

	a {
		color: rgb(113, 113, 113);

		&.active {
			color: var(--d-l-grey);
			svg {
				color: var(--d-l-grey);
			}
		}
	}
}

.mobile-onelink-pitch-section-wrapper {
	position: fixed;
	bottom: 60px;
	right: -300px;
	width: 250px;
	background-color: var(--white-to-dgrey);
	border-radius: 10px 0 0 10px;
	box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
	transition: right 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
	z-index: 100;

	&.open {
		right: 0;
	}

	.mobile-onelink-pitch-section {
		display: flex;
		flex-direction: column;
		padding: 15px;
		gap: 10px;

		.pitch-button {
			padding: 10px;
			background-color: darken(#272727, 5%);
			color: white;
			border: none;
			border-radius: 5px;
			cursor: pointer;
			transition: all 0.3s ease;

			&:hover {
				background-color: lighten(#272727, 10%);
				transform: translateY(-2px);
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			}

			&:active {
				transform: translateY(0);
				box-shadow: none;
			}
		}
	}
}

@keyframes rotate-horizontally {
	0% {
		transform: rotateY(0deg);
	}
	100% {
		transform: rotateY(360deg);
	}
}

@keyframes jump-animation {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-15px);
	}
}

@keyframes pulse-animation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.invest-now-floating-btn {
	position: fixed;
	bottom: 65px;
	right: 10px;
	background-color: var(--white-to-dgrey);
	// background-color: transparent;
	color: white;
	border: none;
	border-radius: 50%;
	width: 70px;
	height:70px;
	display: none;
	align-items: center;
	justify-content: center;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 99;
	cursor: pointer;
	transition: all 0.3s ease;

	@media (max-width: 460px) {
		display: flex;
	}

	img {
		width: 70px;
		height: 70px;
		transition: transform 0.3s ease;
	}

	&:hover {
		transform: scale(1.1);
		box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);

		img {
			transform: rotate(15deg);
		}
	}

	&.invest-btn-rotate {
		animation: rotate-horizontally 1s ease-in-out;
	}

	&.invest-btn-jump {
		animation: jump-animation 0.5s ease-in-out;
	}

	&.invest-btn-pulse {
		animation: pulse-animation 0.5s ease-in-out;
	}
}
