.my-community-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--text-color);
  font-family: 'Outfit', sans-serif;


  .back-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 2rem 0 0 2rem;
    background: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 1.25rem;
    cursor: pointer;
  }

  .content-container {
    width: 90%;
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: var(--section-bg);
    border-radius: 30px;
    backdrop-filter: blur(10px);

    h1 {
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: center;
      font-family: 'Inter', sans-serif;
    }

    .communities-list {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 3rem;

      .community-card {
        display: flex;
        gap: 1.5rem;
        background: var(--card-bg);
        backdrop-filter: blur(10px);
        border-radius: 0;
        padding: 1rem;
        cursor: pointer;
        transition: transform 0.2s;
        border-bottom: 1px solid var(--border-color);

        &:hover {
          transform: translateY(-2px);
        }

        &:first-child {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }

        &:last-child {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          border-bottom: none;
        }

        .community-image {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          margin:auto;
          

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .community-info {
          flex: 1;

          .name-and-role {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 0.5rem;

            h3 {
              margin: 0;
            }

            .status {
              padding: 0.25rem 0.75rem;
              border-radius: 20px;
              font-size: 0.8rem;
              
              &.owner {
                background: #FF620E;
                color: white;
              }
              
              &.joined {
                background: #2E7D32;
                color: white;
              }
            }
          }

          .size {
            color: #FF620E;
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
          }

          .stats {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: var(--text-secondary);
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
          }

          .subscription {
            font-size: 0.9rem;
            color: var(--text-secondary);
          }
        }
      }
    }

    .create-community-button {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      padding: 0.75rem 1.5rem;
      background: var(--current-theme-color) !important;
      border: none;
      border-radius: 60px;
      color: var(--current-theme-text-color) !important;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.2s;
      font-family: 'Inter', sans-serif;

      &:hover {
        background: var(--current-theme-hover-color);
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.modal-overlay > * {
  pointer-events: auto;
}

@media (max-width: 768px) {
  .my-community-page {
    .content-container {
      padding: 0 1rem;

      .communities-list {
        .community-card {
          flex-direction: column;
          align-items: center;
          text-align: center;

          .community-image {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
}

// Add theme variables
[data-theme="light"] {
  --bg-color: #ffffff;
  --text-color: #000000;
  --text-secondary: #666666;
  --section-bg: rgba(0, 0, 0, 0.03);
  --card-bg: rgba(0, 0, 0, 0.02);
  --border-color: rgba(0, 0, 0, 0.1);
  --current-theme-color: var(--theme-color);
  --current-theme-hover-color: var(--theme-hover-color);
}

[data-theme="dark"] {
  --bg-color: #060810;
  --text-color: #FFFFFF;
  --text-secondary: #888888;
  --section-bg: rgba(6, 8, 16, 0.95);
  --card-bg: rgba(255, 255, 255, 0.02);
  --border-color: rgba(255, 255, 255, 0.1);
  --current-theme-color: var(--theme-color);
  --current-theme-hover-color: var(--theme-hover-color);
}


.community-size-and-share {
  display: flex;
  align-items: center;
  justify-content: space-between; 
}


.share-icon {
  cursor: pointer;
  color: var(--d-l-grey); 
  font-size: 2rem;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;

  .leave-icon {
    cursor: pointer;
    color: #dc3545;
    font-size: 2rem;
    transition: color 0.2s;

    &:hover {
      color: darken(#dc3545, 10%);
    }
  }
}
