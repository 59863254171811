.events-container {
	padding: 2rem;
	min-height: 100vh;
	color: #333;
	font-family: "Inter", sans-serif;
}

.events-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;

	h1 {
		font-size: 2rem;
		font-weight: bold;
		color: #4285f4;
	}

	.create-event-btn {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 0.5rem 1rem;
		background-color: #004cc6;
		color: white;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		transition: background-color 0.3s;

		&:hover {
			background-color: #357ae8;
		}

		svg {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}

.events-grid {
	display: grid;
	gap: 1.5rem;
	grid-template-columns: repeat(1, 1fr); // Default to 1 card per row

	@media (min-width: 600px) {
		grid-template-columns: repeat(2, 1fr); // 2 cards per row for medium screens
	}

	@media (min-width: 900px) {
		grid-template-columns: repeat(3, 1fr); // 3 cards per row for large screens
	}
}

.event-card {
	border-radius: 30px;
	padding: 1.5rem;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease;
	cursor: pointer;
	color: #030303;
	font-family: "Outfit", sans-serif;
	background-color: #ffffff; // Ensure cards have a consistent white background

	&:hover {
		transform: translateY(-5px);
	}

	.event-info {
		margin-bottom: 1rem;

		h3 {
			font-size: 1.25rem;
			margin-bottom: 0.5rem;
			font-weight: 600;
			line-height: 1.5;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
		}

		.price-tag {
			position: relative;
			// background: #ff0000;
			color: #666;
			padding: 4px 12px;
			border-radius: 4px;
			display: inline-flex;
			align-items: center;
			gap: 8px;
			margin: 8px 0;

			.original-price {
				font-size: 0.875rem;
				color: #414141;
				text-decoration: line-through;
			}

			.new-price {
				font-size: 1rem;
				font-weight: bold;
			}
		}

		.event-meta {
			padding: 10px;
			background-color: #ffffff;
			justify-content: space-between;
			border-radius: 30px;
			font-size: 0.875rem;
			color: #040404;
			display: flex;
			gap: 0.5rem;
			align-items: center;

			.leftTime {
				span {
					gap: 10px;
				}
			}

			.separator {
				color: #4d4d4d;
			}
		}
	}

	.event-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.action-buttons {
			width: 100%;
			display: flex;
			gap: 0.75rem;
			justify-content: space-between;

			button {
				display: flex;
				align-items: center;
				padding: 0.5rem 1rem;
				border-radius: 30px;
				border: none;
				font-size: 0.875rem;
				cursor: pointer;
				transition: background-color 0.3s;

				&.copy-btn {
					background-color: #e0e0e0;
					color: #0b0b0b;

					&:hover {
						background-color: #d0d0d0;
					}
				}

				&.delete-btn {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					background-color: #ff484e;
					color: white;

					&:hover {
						background-color: #e04c4f;
					}
				}
			}
			a {
				text-decoration: none;
			}
			.join-btn {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				background-color: #4285f4;
				color: white;
				border: none;
				// text-decoration: none;
				// text-underline: none;

				&:hover {
					background-color: #3574db;
				}

				svg {
					font-size: 1rem;
				}
			}
		}

		.bookings-count {
			font-size: 0.875rem;
			color: #000000;
		}
	}
}

.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 300px;
	width: 100%;
}

.loader {
	width: 50px;
	height: 50px;
	border: 4px solid #f3f3f3;
	border-top: 4px solid #fd5901;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	position: relative;

	&-inner {
		position: absolute;
		width: 70%;
		height: 70%;
		border: 4px solid transparent;
		border-right: 4px solid #fd5901;
		border-left: 4px solid #fd5901;
		border-radius: 50%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: spin-reverse 1s linear infinite;
	}
}

.investor-loader {
	width: 50px;
	height: 50px;
	border: 4px solid #f3f3f3;
	border-top: 4px solid #d3f36b !important;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	position: relative;

	&-inner {
		position: absolute;
		width: 70%;
		height: 70%;
		border: 4px solid transparent;
		border-right: 4px solid #d3f36b !important;
		border-left: 4px solid #d3f36b !important;
		border-radius: 50%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: spin-reverse 1s linear infinite;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin-reverse {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

// Dark theme support
.dark-theme {
	.loader {
		border-color: #2a2a2a;
		border-top-color: #fd5901;

		&-inner {
			border-right-color: #fd5901;
			border-left-color: #fd5901;
		}
	}

	.investor-loader {
		border-color: #2a2a2a;
		border-top-color: #d3f36b !important;
		&-inner {
			border-right-color: #d3f36b !important;
			border-left-color: #d3f36b !important;
		}
	}
}
