.simpleCard {
  flex: 0 0 20rem;

  fieldset,
  legend {
    all: revert;
  }
  fieldset {
    min-height: 150px;
    border: 1px solid rgba(227, 227, 227, 1);

    &:hover {
      border: 1px solid rgba(211, 243, 107, 1);
    }
  }
  legend {
    font-size: 14px;
    font-weight: 500;
  }
  p {
    font-size: 12px;
    font-weight: 400;
  }
}
