.community_dashboard_container {
  // 80px is height of chat navbar and 70px is (height of mobileheader + margin-bottom)
  max-height: calc(100% - 80px - 0px);
  height: 100%;
  border-radius: var(--border-radius);
  // border: 1px solid #cfcfcf;
  background: var(--white-to-grey);
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.11);
  text-align: center;
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 100vw;

  @media (max-width: 820px) {
    border-radius: 15px;
  }

  @media (min-width: 821px) {
    // 85px is removed because mobileheader is not rendered beyond this height
    max-height: calc(100% - 80px);
  }

  .time {
    color: #949494;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .today_text {
    display: inline-block;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    width: 95%;
    margin-top: 10px;
    color: #a0a0a0;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 47%;
      height: 1px;
      background-color: #ebebeb;
      transform: translateY(-50%);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  .chat_messages_group {
    flex: 1 1 0;
    overflow-y: auto;
    scrollbar-gutter: stable;

    display: flex;
    flex-direction: column;
    & > div:first-child {
      margin-top: auto;
    }

    .date_header {
      width: max-content;
      margin: auto;
    }

    scrollbar-width: thick;
    scrollbar-color: var(--currentTheme) transparent;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--currentTheme);
      border-radius: 10rem;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--currentTheme-dark);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10rem;
    }
    // .chat_messages {
    //   height: 80%;
    //   // overflow-y: scroll;
    //   // overflow-x: hidden;

    //   // .other_sender {
    //   //   align-self: flex-start;
    //   //   display: flex;
    //   //   max-width: 90%;

    //   //   @media (min-width: 768px) {
    //   //     max-width: 60%;
    //   //   }

    //   //   .image_profile {
    //   //     height: 50px;
    //   //     width: 50px;
    //   //     border-radius: 50%;
    //   //   }

    //   //   .other_messages {
    //   //     display: flex;
    //   //     flex-direction: column;
    //   //     justify-content: flex-start;
    //   //     align-items: flex-start;

    //   //     .msg-time {
    //   //       font-size: 10px;
    //   //       color: rgb(112, 112, 112);
    //   //       text-align: end;
    //   //       display: flex;
    //   //       align-items: center;
    //   //       gap: 5px;
    //   //       justify-content: flex-end;
    //   //       width: 100%;
    //   //     }

    //   //     .message_container {
    //   //       background-color: #eaeef2;
    //   //       padding: 5px 10px;
    //   //       border-top-right-radius: 10px;
    //   //       border-bottom-right-radius: 10px;
    //   //       border-bottom-left-radius: 10px;
    //   //       margin-bottom: 5px;
    //   //       display: flex;
    //   //       flex-direction: column;
    //   //       align-items: center;

    //   //       &[data-msg-type="text"] {
    //   //         max-width: 90%;
    //   //       }

    //   //       &[data-msg-type="media"],
    //   //       &[data-msg-type="doc"] {
    //   //         max-width: 60%;
    //   //       }

    //   //       @media (min-width: 768px) {
    //   //         max-width: 60%;
    //   //       }

    //   //       p {
    //   //         font-size: 14px;
    //   //       }

    //   //       a {
    //   //         // color: rgb(93, 93, 93);
    //   //         color: #212529;
    //   //       }

    //   //       .video-message {
    //   //         max-width: 100%;
    //   //         // height: auto;
    //   //         max-height: 200px;
    //   //         border-radius: 10px;
    //   //         margin-top: 10px;
    //   //         background-color: #000;
    //   //         box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    //   //       }

    //   //       .image-message {
    //   //         max-width: 100%;
    //   //         // height: auto;
    //   //         max-height: 200px;
    //   //         border-radius: 10px;
    //   //         margin-top: 10px;
    //   //         box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    //   //       }
    //   //     }
    //   //   }
    //   // }

    //   // .my_message_main {
    //   //   align-self: flex-end;

    //   //   .image_profile {
    //   //     height: 50px;
    //   //     width: 50px;
    //   //     border-radius: 50%;
    //   //   }

    //   //   .my_messages {
    //   //     display: flex;
    //   //     flex-direction: column;
    //   //     justify-content: flex-start;
    //   //     align-items: flex-end;
    //   //     padding-inline-end: 0.5rem;

    //   //     .msg-time {
    //   //       font-size: 10px;
    //   //       color: rgb(255, 255, 255);
    //   //       text-align: end;
    //   //       display: flex;
    //   //       align-items: center;
    //   //       gap: 5px;
    //   //       justify-content: flex-end;
    //   //       width: 100%;
    //   //     }

    //   //     .time_name_image {
    //   //       display: flex;
    //   //     }

    //   //     .mymessage_container {
    //   //       max-width: 60%;

    //   //       &:not(:has(.image-message, .video-message, .document-message)) {
    //   //         max-width: 90%;
    //   //       }

    //   //       @media (min-width: 768px) {
    //   //         max-width: 60%;
    //   //       }

    //   //       background-color: var(--currentTheme);
    //   //       color: var(--currentTheme-text);
    //   //       padding: 5px 10px;
    //   //       border-top-left-radius: 10px;
    //   //       border-bottom-right-radius: 10px;
    //   //       border-bottom-left-radius: 10px;
    //   //       margin-bottom: 5px;
    //   //       // margin-right: 50px;
    //   //       display: flex;
    //   //       flex-direction: column;
    //   //       // align-items: center;

    //   //       .chatDropDownMenu {
    //   //         display: none;
    //   //         overflow: visible;
    //   //       }

    //   //       &:hover,
    //   //       &:active {
    //   //         .chatDropDownMenu {
    //   //           display: block;
    //   //         }
    //   //       }

    //   //       p {
    //   //         font-size: 14px;
    //   //         margin-inline-end: 1rem;
    //   //       }

    //   //       a {
    //   //         color: white;
    //   //       }

    //   //       .video-message {
    //   //         max-width: 100%;
    //   //         // height: auto;
    //   //         max-height: 200px;
    //   //         border-radius: 10px;
    //   //         margin-top: 10px;
    //   //         background-color: #000;
    //   //         box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    //   //       }

    //   //       .image-message {
    //   //         max-width: 100%;
    //   //         // height: auto;
    //   //         max-height: 200px;
    //   //         border-radius: 10px;
    //   //         margin-top: 10px;
    //   //         box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    //   //       }
    //   //     }
    //   //   }
    //   // }
    // }
  }

  .popup-close-button,
  .popup-ok_button {
    // width: 20%;
    height: 30px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--currentTheme);
    color: var(--currentTheme-text);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
