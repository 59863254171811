.about-container {
  background: var(--white-to-grey);
  border-radius: 0.5rem;
  overflow: hidden;

  .community-banner {
    position: relative;
    height: 420px;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center -1rem;
    }

    h1 {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      color: white;
      text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    }
  }

  .community-info {
    padding: 1.5rem;
    position: relative;

    .community-header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 2rem;
      z-index: 1;

      .community-logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }

      h1 {
        color: var(--text-color);
        font-size: 1.8rem;
        margin: 0;
      }
    }

    .content-section {
      position: relative;
      z-index: 0;
    }

    .description-section, .admin-section {
      margin-bottom: 2rem;
    }

    .admin-card {
      cursor: pointer;
      display: flex;
      gap: 1.5rem;
      padding: 1.5rem;
      background: var(--background-color);
      border-radius: 0.5rem;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1rem;
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }

      .admin-info {
        h3 {
          color: var(--text-color);
          margin-bottom: 0.5rem;

          @media (max-width: 768px) {
            justify-content: center;
          }
        }

        p {
          color: var(--d-l-grey);
          margin-bottom: 0.5rem;
          font-size: 0.9rem;

          &.company {
            color: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
            font-weight: 500;
          }

          &.bio {
            @media (max-width: 768px) {
              max-width: 100%;
              word-wrap: break-word;
            }
          }
        }

        .linkedin-link {
          color: #0077b5;
          font-size: 1.5rem;
          
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
} 

.community-heading {
    color: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
    margin-bottom: 1rem;
    font-size: 1.5rem;
    background: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.quill-content {
  color: var(--text-color);
  
  // Basic text formatting
  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  // Headers
  h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem;
    color: var(--text-color);
  }

  // Lists
  ul, ol {
    margin-bottom: 1rem;
    padding-left: 2rem;
    
    li {
      margin-bottom: 0.5rem;
    }
  }

  // Links
  a {
    color: #FD5901;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  // Blockquotes
  blockquote {
    border-left: 4px solid #9306FF;
    margin: 1rem 0;
    padding-left: 1rem;
    color: var(--d-l-grey);
  }

  // Code blocks
  pre {
    background: var(--background-color);
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin: 1rem 0;
  }

  // Inline code
  code {
    background: var(--background-color);
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
  }

  // Images
  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 1rem 0;
  }

  // Text alignment
  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }
}