.ChatDeletePopup {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .modal-overlay {
      position: absolute;
      background: #000;
      height: 100vh;
      width: 100%;
      opacity: 0.4;
      z-index: -10;
      top: 0;
      margin: 0;
    }
  
    .modal-container {
      height: auto;
      margin: 20px;
      background-color: #fff;
      border-radius: var(--border-radius);
      padding: 20px 40px;
      position: absolute;
  
      .modal-title {
        text-align: center;
        padding-bottom: 15px;
        border-bottom: rgb(241, 241, 241) 2px solid;
      }
  
      button:disabled {
        opacity: 0.6;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px inset;
        cursor: not-allowed;
      }
    }
  }