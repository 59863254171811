.public-community-view {
  .join-banner {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    z-index: 1000;

    .join-button {
      padding: 0.75rem 2rem;
      //background-color: var(--primary);
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
      border: none;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--primary-dark);
      }
    }
  }

  .admin-posts-container {
    background: var(--white-to-grey);
    border-radius: 0.5rem;
    overflow: hidden;
    margin-top: 2rem;
    padding: 2rem;

    h3 {
      color: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
      margin-bottom: 1rem;
      font-size: 1.5rem;
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .admin-posts-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  // Add modal styles
  .subscription-modal {
    background: white !important;
    max-width: 500px !important;
    width: 90% !important;
    padding: 2rem !important;
    border-radius: 10px !important;
    outline: none !important;
    border: none !important;

    .public-community-modal-content {
      h2 {
        color: #333;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 2rem;
        font-weight: 600;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        input, select {
          padding: 1rem 1.2rem;
          border-radius: 8px;
          border: 1px solid #ddd;
          background-color: #f5f5f5;
          color: #333;
          font-size: 1.1rem;
          width: 100%;
          box-sizing: border-box;
          transition: all 0.3s ease;

          &:focus {
            outline: none;
            border-color: #fd5901;
            box-shadow: 0 0 0 2px rgba(253, 89, 1, 0.1);
          }

          &::placeholder {
            color: #666;
          }
        }

        select {
          appearance: none;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right 1rem center;
          background-size: 1em;
        }

        button {
          background-color: #fd5901;
          color: white;
          padding: 1.2rem;
          border: none;
          border-radius: 8px;
          font-size: 1.2rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;
          width: 100%;
          margin-top: 1rem;

          &:hover {
            background-color: #ff6b1a;
            transform: translateY(-2px);
          }

          &:disabled {
            background-color: #666;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .subscription-modal-overlay {
    // Styles will be handled by the inline styles in the Modal component
  }

  // OTP Modal Styles
  .otp-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    z-index: 1001;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    
    .public-community-modal-content {
      text-align: center;
  
      h2 {
        color: #333;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      
      p {
        margin-bottom: 2rem;
        color: #666;
      }
      
      .otp-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        gap: 8px;
        
        .otp-input {
          width: 40px;
          height: 40px;
          text-align: center;
          border: 2px solid #ddd;
          border-radius: 4px;
          font-size: 1.2rem;
          
          &:focus {
            border-color: #fd5901;
            box-shadow: 0 0 0 2px rgba(253, 89, 1, 0.1);
          }
        }
      }
      
      button {
        background-color: #fd5901;
        padding: 0.8rem 2.5rem;
        border-radius: 8px;
        font-weight: 600;
        color: white;
        border: none;
        cursor: pointer;
        
        &:hover {
          background-color: #e54e00;
        }
      }
    }
  }

  .otp-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }

  // Loader styles
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    
    .loader {
      width: 50px;
      height: 50px;
      border: 5px solid #333;
      border-top: 5px solid #fd5901;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    .loader-text {
      color: white;
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .button-loader {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .content-section {
    margin: 2rem 0;
    padding: 2rem;
    background: var(--white-to-grey);
    border-radius: 0.5rem;

    h3 {
      color: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
      margin-bottom: 1rem;
      font-size: 1.5rem;
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .locked-content {
    position: relative;
    
    .locked-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(2px);
      opacity: 0;
      transition: opacity 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 0.5rem;

      &:hover {
        opacity: 1;
        z-index: 1000;
      }

      .lock-message {
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
        padding: 1rem 2rem;
        background: linear-gradient(90deg, rgba(253, 89, 1, 0.9) 0%, rgba(147, 6, 255, 0.9) 100%);
        backdrop-filter: blur(4px);
        transform: translateY(10px);
        transition: transform 0.3s ease;
      }

      &:hover .lock-message {
        transform: translateY(0);
      }
    }
  }

  // Update existing products and events grid styles
  .products-grid, .events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem 0;

    .product-card, .event-card {
      background: var(--white);
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-4px);
      }
    }
  }
}

// Global modal styles
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -60%) !important;
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.ReactModal__Content--after-open {
  z-index: 10;
  transform: translate(-50%, -50%) !important;
  opacity: 1;
}

.ReactModal__Content--before-close {
  transform: translate(-50%, -60%) !important;
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);

  // Make sure Register component appears properly in modal
  .register_container {
    background: white;
    border-radius: 20px;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
  }
}

.public-community-modal-content {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: modalFadeIn 0.3s ease-out;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 1.8rem;
      font-weight: 600;
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    button {
      background: none;
      border: none;
      font-size: 1.8rem;
      cursor: pointer;
      color: #666;
      transition: color 0.2s;

      &:hover {
        color: #FD5901;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    input, select {
      padding: 0.8rem 1rem;
      border: 2px solid #eee;
      border-radius: 12px;
      font-size: 1rem;
      transition: all 0.2s;
      background: #f8f9fa;

      &:focus {
        outline: none;
        border-color: #FD5901;
        box-shadow: 0 0 0 3px rgba(253, 89, 1, 0.1);
      }

      &::placeholder {
        color: #aaa;
      }
    }

    select {
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1em;
    }

    button {
      padding: 0.8rem;
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
      color: white;
      border: none;
      border-radius: 12px;
      cursor: pointer;
      font-weight: 600;
      font-size: 1rem;
      transition: transform 0.2s, box-shadow 0.2s;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(253, 89, 1, 0.2);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        transform: none;
      }
    }
  }

  p {
    text-align: center;
    color: #666;
    margin: 1rem 0;
  }

  &.success-modal {
    text-align: center;
    
    .modal-header {
      h2 {
        margin: 0 auto;
      }
    }

    p {
      font-size: 1.2rem;
      color: #4a4a4a;
      margin: 1.5rem 0;
    }

    .explore-button {
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
      color: white;
      padding: 1rem 2rem;
      border: none;
      border-radius: 8px;
      font-size: 1.1rem;
      font-weight: 600;
      cursor: pointer;
      transition: transform 0.2s, box-shadow 0.2s;
      width: auto;
      margin: 0 auto;
      display: block;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(253, 89, 1, 0.2);
      }
    }
  }
}

.otp-container {
  display: flex;
  gap: 0.8rem;
  justify-content: center;
  margin: 1.5rem 0;

  input {
    width: 45px;
    height: 45px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    border: 2px solid #eee;
    border-radius: 12px;
    background: #f8f9fa;
    transition: all 0.2s;

    &:focus {
      outline: none;
      border-color: #FD5901;
      box-shadow: 0 0 0 3px rgba(253, 89, 1, 0.1);
    }
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Phone input specific styles
.PhoneInput {
  .PhoneInputInput {
    padding: 0.8rem 1rem;
    border: 2px solid #eee;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.2s;
    background: #f8f9fa;
    width: 100%;

    &:focus {
      outline: none;
      border-color: #FD5901;
      box-shadow: 0 0 0 3px rgba(253, 89, 1, 0.1);
    }
  }

  .PhoneInputCountry {
    margin-right: 0.8rem;
  }
}

.phone-input-container {
  position: relative;
  display: flex;
  align-items: center;
  
  .country-code {
    position: absolute;
    left: 1rem;
    color: #666;
    font-size: 1rem;
  }

  input {
    padding-left: 3rem !important; // Make room for country code
    width: 100%;
  }
}

// Update the products grid styles
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
  max-width: 1200px;
  margin: 0 auto;

  .product-card {
    background: var(--white);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    }

    .product-image {
      position: relative;
      width: 100%;
      height: 200px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }

      .badge {
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 6px 12px;
        border-radius: 20px;
        font-size: 0.875rem;
        font-weight: 600;
        
        &.free {
          background: linear-gradient(135deg, #00C853, #69F0AE);
          color: white;
        }
        
        &.paid {
          background: linear-gradient(135deg, #FD5901, #9306FF);
          color: white;
        }
      }
    }

    .product-content {
      padding: 1.5rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h4 {
        font-size: 1.25rem;
        font-weight: 600;
        color: #2c3e50;
        margin: 0;
      }

      p {
        color: #64748b;
        font-size: 0.95rem;
        line-height: 1.5;
        margin: 0;
      }
    }
  }
} 