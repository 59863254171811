.recommendation_main_container {
  .recommendation_card {
    display: flex;
    justify-content: center;
    align-items: center;
    hr {
      margin: 0px 5%;
    }
    .right_view_profile_card {
      border-radius: var(--border-radius);

      .card-header {
        background-color: #000;
        color: #fff;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        border-radius: 1rem 1rem 0px 0px;

        .title {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
        }
      }

      .card-body {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;

        h5 {
          color: #000;
          font-size: 13px;
          font-family: Poppins;
          font-weight: 500;
        }

        .input-group {
          position: relative;
        }

        .input-group input {
          height: 2rem;
        }

        .input-group input::placeholder {
          font-size: 0.8rem;
        }

        .send-button {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          background-color: #000;
          color: #fff;
        }

        .connect_button {
          border-radius: 50px;
          // border: 1px solid #acacac;
          color: var(--d-l-grey);
          font-size: 13px;
          border: 1px var(--d-l-grey) solid;
          font-family: Poppins;
          padding: 5px 10px;
          display: flex;
          gap: 8px;
          margin: auto 0;
          align-items: center;
          height: 40px;
          img {
            width: 18px;
          }
        }
      }

      .recommendation_card_body {
        display: flex;
        justify-content: start;
        align-items: flex-start;

        &:hover {
          background-color: #4f4f4fa1;
        }

        img {
          width: 45px;
          height: 45px;
        }

        .recommendation_card_text {
          flex-grow: 1;

          h3 {
            font-size: 15px;
            font-family: Poppins;
            font-weight: 600;
          }

          .smallest_typo {
            color: #686868;
            font-size: 12px;
            font-family: Poppins;
          }
        }
      }
    }

    .right_view_profile {
      width: 100%;
    }

    .card_wrapper + .card_wrapper {
      border-top: 1px solid var(--bs-border-color);
    }

    .card_wrapper:last-of-type {
      .recommendation_card_body {
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
    }
  }
}
