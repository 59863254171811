.update-community-form {
  background: var(--white-to-grey);
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    background: linear-gradient(90deg, #FD5901 0%, #9306FF 30%);
    -webkit-background-clip: text;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .form-group {
    margin-bottom: 1.5rem;

    label {
      display: block;
      margin-bottom: 0.5rem;
      color: var(--text-color);
      font-size: 0.9rem;
    }

    input[type="text"],
    input[type="number"],
    select,
    textarea {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      background: var(--background-color);
      color: var(--text-color);
      font-size: 0.9rem;

      &:focus {
        outline: none;
        border-color: #fd5901;
        box-shadow: 0 0 0 2px rgba(253, 89, 1, 0.1);
      }

      &::placeholder {
        color: var(--d-l-grey);
      }
    }

    &.checkbox {
      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        input[type="checkbox"] {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
        }
      }
    }

    .quill {
      .ql-toolbar {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-color: var(--border-color);
        background: var(--background-color);
      }

      .ql-container {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-color: var(--border-color);
        background: var(--background-color);
        min-height: 200px;
        font-size: 0.9rem;
      }

      .ql-editor {
        color: var(--text-color);
        
        &::placeholder {
          color: var(--d-l-grey);
        }
      }
    }
  }

  button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }

  .term-input-group {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
    align-items: center;

    input {
      flex: 1;
    }

    .remove-term {
      padding: 0.5rem 1rem;
      background: #ff4444;
      color: white;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
      
      &:hover {
        background: #cc0000;
      }
    }
  }

  .add-term {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    
    &:hover {
      background: #45a049;
    }
  }

  .delete-button {
    background: #dc3545;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.25rem;
    margin-top: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: #c82333;
    }
  }

  .remove-member-button {
    background: #ffc107;
    color: #000;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: #e0a800;
    }
  }

  .image-upload-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;

    .profile-image-container,
    .banner-image-container {
      position: relative;
      cursor: pointer;
      overflow: hidden;

      &:hover .edit-overlay {
        opacity: 1;
      }

      .edit-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.3s ease;

        span {
          color: white;
          font-size: 0.9rem;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .profile-image-container {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: 0 auto;

      .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .banner-image-container {
      width: 100%;
      height: 200px;
      border-radius: 8px;

      .banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
}

// Dark theme adjustments
[data-bs-theme='dark'] {
  .update-community-form {
    input, select, textarea {
      background: var(--background-color);
      color: var(--text-color);
    }
  }

  .quill {
    .ql-toolbar {
      .ql-stroke {
        stroke: var(--text-color);
      }
      .ql-fill {
        fill: var(--text-color);
      }
      .ql-picker {
        color: var(--text-color);
      }
    }
  }
} 