.register_container {
	display: flex;
	flex-direction: column;
	height: 100dvh;
	padding-bottom: 2rem;
	@media (min-width: 1280px) {
		flex-direction: row;
	}
	.register_container_left {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: rgb(253 242 248);
		height: 100dvh;
		padding: 1.25rem;
		@media (min-width: 1280px) {
			width: 50%;
		}

		.create_account_btn {
			color: white;
			padding: 4px 32px;
			border-radius: 40px;
			font-size: 1.125rem /* 18px */;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			background-color: #fd5901;
			width: 225px;
			height: 41.6;
			// line-height: 1.75rem /* 28px */;
			:hover {
				background-color: #fd590194;
				opacity: 0.8;
			}
			border: 1px solid transparent;
			box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
			// background-color: transparent;
			transition: all 0.15s ease-in-out;

			&:active {
				box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
			}

			&.startup {
				border: 1px solid #fd5901;
				background-color: #fd5901;
				color: white;
			}

			&.investor {
				border: 1px solid #d3f36b;
				background-color: #d3f36b;
				color: black;
			}
		}
	}

	.register_container_right {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 1.25rem;
		height: 100dvh;
		background-color: white;
		@media (min-width: 1280px) {
			width: 50%;
		}
		.login-form-mobile {
			width: 60%;
			@media (max-width: 460px) {
				width: 90%;
			}
		}
	}

	.social-login-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}

	.google_signin {
		cursor: pointer;
	}

	.google_signin img {
		margin-right: 8px;
	}

	.login_buttons_row {
		// margin-top: 20px;
		// display: flex;
		// flex-direction: row;
		// justify-content: center;
		// align-items: center;
		// width: 70%;
		// gap: 10px;
	}

	.login_btn {
		min-width: 150px;
		height: 50px;
		padding: 10px 20px;
		font-weight: 500;
		font-size: 18px;

		border-radius: 10rem;
		border: 1px solid transparent;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
		background-color: transparent;
		transition: all 0.15s ease-in-out;

		&:active {
			box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
		}

		&.startup {
			border: 1px solid #fd5901;
			background-color: #fd5901;
			color: white;
		}

		&.investor {
			border: 1px solid #d3f36b;
			background-color: #d3f36b;
			color: black;
		}

		@media (max-width: 500px) {
			max-width: 100px;
			height: auto;
			font-size: 14px;
			padding: 10px;
		}
	}

	.register_heading {
		display: flex;
		// justify-content: flex-end;
		align-items: center;
		flex-direction: column;

		.backArrow {
			position: absolute;
			top: 10%;
			left: 10%;
			height: 40px;
			width: 40px;
			cursor: pointer;
			@media (min-width: 768px) {
				top: 16%;
				left: 10%;
			}
		}

		h3 {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 700;
			font-size: 48px;
			line-height: 80px;
			margin-top: 150px;
			color: #000000;
		}

		img {
			margin-top: -50px;
			height: 400px;
		}
	}

	.register_heading_right {
		//display: flex;
		justify-content: center;
		flex-direction: column;
		padding-top: 1%;
		gap: 1em;

		.backArrow {
			position: absolute;
			top: 2%;
			left: 5%;
			height: 25px;
			width: 25px;
			cursor: pointer;
			@media (min-width: 768px) {
				top: 16%;
				left: 16%;
			}
		}

		h1 {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			color: #000000;
		}

		h3 {
			margin-left: 10px;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 33px;
			color: #000000;
		}

		.welcome {
			display: none;
		}

		.already_have_account_mobile {
			display: none;
		}
	}

	label {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		text-align: center;
		color: #000000;
	}

	input[type="text"],
	input[type="password"] {
		width: 100%;
		//padding: 15px;
		border-radius: 3px;
		background-color: #fff;
		//padding: 18px 17px;
		gap: 10px;
		//height: 54px;
		border-radius: 10px;

		&.first_name {
			width: 46%;
		}
	}

	input[type="tel"] {
		width: 100%;
		//padding: 15px;
		border-radius: 3px;
		background-color: #fff;
		//padding: 18px 17px;
		gap: 10px;
		//height: 50px;
		border: none;
		border-radius: 10px;
		outline: none;

		&:focus {
			border: none;
		}
	}

	.staySignedIn-fp {
		display: flex;
		justify-content: space-around;
		align-items: center;
		// gap: 10px;
	}

	.form-check {
		margin: 20px;

		.form-check-label {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			text-align: center;
			color: #000000;
		}
	}

	button[type="submit"] {
		width: 437px;
		height: 50px;
		left: 832px;
		top: 745px;
		background: #fd5901;
		border-radius: 10px;
		border: none;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

		&:active {
			box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
		}

		&.startup {
			border: 1px solid #fd5901;
			background-color: #fd5901;
			color: white;
		}

		&.investor {
			border: 1px solid #d3f36b;
			background-color: #d3f36b;
			color: black;
		}
	}

	.submit_btn {
		display: flex;
		justify-content: start;
		align-items: center;
		width: 90%;
		justify-content: center;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #ffffff;
	}

	.line-container {
		display: flex;
		align-items: center;
		justify-content: center;

		.line {
			flex-grow: 1;
			height: 1px;
			background-color: black;
		}
	}

	.text {
		font-size: 18px;
	}

	.login_icons {
		margin-left: -36px;

		img {
			height: 30px;
			margin: 10px;
		}
	}

	.orange {
		color: #fd5901;
	}

	.green {
		color: #d3f36b;
	}
}

@media screen and (max-width: 768px) {
	.register_container {
		.register_heading {
			display: none;
		}

		.register_heading_right {
			h1 {
				font-size: 24px;
			}

			label {
				color: #000;
				font-family: Poppins;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			input[type="text"],
			input[type="password"] {
				width: 100%;
				//padding: 15px;
				border-radius: 3px;
				background-color: #fff;
				//padding: 18px 17px;
				gap: 10px;
				//height: 54px;
				border: 2px solid #000000;
				border-radius: 10px;

				&.first_name {
					width: 100%;
				}

				&::placeholder {
					color: #b4b4b4;
					font-family: Poppins;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			input[type="tel"] {
				width: 100%;
				//padding: 15px;
				border-radius: 3px;
				background-color: #fff;
				//padding: 18px 17px;
				gap: 10px;
				//height: 50px;
				border: none;
				border-radius: 10px;
				outline: none;

				&:focus {
					border: none;
				}
			}

			.welcome {
				display: block;
				color: #000;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.already_have_account {
				display: none;
			}

			.already_have_account_mobile {
				display: flex;
				justify-content: center;
				margin-top: 20px;
				color: #b4b4b4;
				font-family: Poppins;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				flex-direction: column;
			}
		}

		.submit_btn {
			display: flex;
			justify-content: start;
			align-items: center;
			width: 100%;
			justify-content: center;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #ffffff;

			button {
				width: 80%;
			}
		}

		.line-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 70%;
			margin: 20px 0px 20px 85px;

			.line {
				flex-grow: 1;
				height: 1px;
				background-color: black;
			}
		}

		.login_icons {
			margin-left: 0px;

			img {
				height: 30px;
				margin: 10px;
			}
		}
	}
}

.plato_form_control {
	width: 100%;
	background-color: #fff;
	gap: 10px;

	&.first_name {
		width: 100%;
	}

	&::placeholder {
		color: #b4b4b4;
		font-family: Poppins;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.PhoneInputInput {
		border: none;
	}

	.PhoneInputCountry {
		display: flex;
		flex-direction: row-reverse;

		.PhoneInputCountryIcon {
			display: flex;

			img {
				height: 15px;
				width: 24px;
				border-radius: 50%;
			}
		}

		.PhoneInputCountrySelect {
			height: 18px;
			width: 20px;
			margin-left: 2px;
			background-color: transparent;
			border: none;
		}
	}

	/* Add your custom styling here */
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;

	/* Position the select to the left of the input */
	.react-phone-number-input__country {
		order: -1;
		/* Move the country select to the left */
		margin-right: 5px;
		/* Add a small gap between select and input */
	}

	/* Adjust the size of the flag/logo */
	.react-phone-number-input__icon {
		/* You can adjust the height and width to your desired size */
		height: 24px;
		width: auto;
	}
}

.OtpVerificationModal_container {
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;

	@media (max-width: 460px) {
		padding: 0;
	}

	.OtpVerificationModal_main_container {
		background-color: white;
		border-radius: 10px;
		padding: 1.5rem;
		width: 100%;
		max-width: 700px;
		// max-height: 500px;
		position: relative;
		display: flex;
		flex-direction: column;
		@media (min-width: 768px) {
			flex-direction: row;
			width: 100%;
			max-width: 700px;
		}
		@media (max-width: 460px) {
			height: 100svh;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.Modal_Close_btn {
			position: absolute;
			top: 16px;
			right: 16px;
			height: 40px;
			width: 40px;
			cursor: pointer;
			border: none;
			background-color: transparent;
			color: rgb(107 114 128);
			:hover {
				color: rgb(55 65 81);
			}
		}
		.enter_verification_code {
			font-size: 24px;
			line-height: 32px;
			font-weight: 700;
			margin-bottom: 1rem;
		}
		.Otp_Sent_Msg {
			color: rgb(75 85 99);
			margin-bottom: 1.5rem;
		}
		.OTP_Input {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 3rem;
			height: 3rem;
			text-align: center;
			font-size: 1.5rem;
			border-width: 2px;
			border-color: rgb(209 213 219);
			border-radius: 8px;
			:focus {
				border-color: rgb(59 130 246);
				outline: 2px solid transparent;
				outline-offset: 2px;
			}
		}

		.Send_OTP_btn {
			color: #22c55e;
			border: none;
			background-color: transparent;
			:hover {
				text-decoration: underline;
			}
		}
		.Change_Number_btn {
			color: rgb(59 130 246);
			border: none;
			background-color: transparent;
			:hover {
				text-decoration: underline;
			}
		}
		.Modal_Verify_btn {
			background-color: rgb(249 115 22 /100%);
			color: white;
			padding-top: 0.25rem /* 4px */;
			padding-bottom: 0.25rem /* 4px */;
			border-radius: 9999px;
			font-size: 1.125rem /* 18px */;
			line-height: 1.75rem /* 28px */;
			font-weight: 600;
			:hover {
				background-color: rgb(234 88 12 /100%);
			}
			transition-property: color, background-color, border-color,
				text-decoration-color, fill, stroke, opacity, box-shadow, transform,
				filter, backdrop-filter;
			transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
			// transition-duration: 150ms;
			transition-duration: 300ms;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			border: none;
			box-shadow: #b4b4b4 0px 0px 0px 1px;
			@media (min-width: 768px) {
				width: 14rem /* 224px */;
			}
			border: 1px solid transparent;
			box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
			// background-color: transparent;
			transition: all 0.15s ease-in-out;

			&:active {
				box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
			}

			&.investor {
				background-color: #d3f36b !important;
				color: black !important;
				
				&:hover {
					background-color: #c1e159 !important;
				}
			}
			
			&.startup {
				background-color: #fd5901 !important;
				color: white !important;
				
				&:hover {
					background-color: #e54e01 !important;
				}
			}
		}
	}
}

.status-message {
	padding: 12px;
	border-radius: 8px;
	margin-bottom: 16px;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	animation: fadeIn 0.3s ease-in-out;

	&.success {
		background-color: #dcfce7;
		color: #166534;
		border: 1px solid #86efac;
	}

	&.error {
		background-color: #fee2e2;
		color: #991b1b;
		border: 1px solid #fca5a5;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
