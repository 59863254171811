.team_section {
  .card {
    position: relative;
    // height: 138px;
    margin-top: 20px;
    border-radius: 4px;
    // background: #fff;
    background-color: var(--bs-light);

    // box-shadow: 4px 4px 30px 0px rgba(234, 238, 242, 0.5);
    box-shadow: var(--bs-box-shadow-sm);
    width: 22rem;

    .card-body {
      display: flex;
      // justify-content: center;
      align-items: center;

      .card-title {
        // background-color: #fff;
        background: var(--bs-light);

        position: absolute;
        top: -10px;
        left: 25%;
        transform: translateX(-50%);
        border-radius: 5px;
        color: #ff772f;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .company_text {
        margin: 0 10px;
        h6 {
          color: #626262;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        input {
          border: none;
          outline: none;
        }
      }

      .card-text {
        color: rgba(0, 0, 0, 0.63);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

// @media (min-width: 1181px) {
//   .team_section {
//     .card {
//       .card-body {
//         display: flex;
//         gap: 20px;
//         width: 22.5rem;
//       }
//     }
//   }
// }

@media (min-width: 425px) {
  .team_section {
    .card {
      .card-body {
        display: flex;
        gap: 20px;
        width: 22rem;
      }
    }
  }
}

@media (max-width: 425px) {
  .team_section {
    .card {
      .card-body {
        width: 100%;
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }
  }
}
