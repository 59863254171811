.new-investor-private-routes {
  // background-color: var(--investor-bg);
  min-height: 100dvh;
  .newInvestor_container {
    display: flex;
    // background-color: #f9faf3;
    margin: 0;
    position: relative;
    min-height: 99vh;

    .sidebar {
      // width: 300px;
      // background-color: #eaeef2;
      position: fixed;
      top: calc(60px + 0%);
      left: 0;
      bottom: 0;
      z-index: 100;
      transition: width 0.3s ease;
      overflow: scroll;

      // Add class for sidebar in fixed position
      &.fixed {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    }

    .sidebar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .sidebar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }

    .content {
      flex: 1;
      margin-left: 200px;
      transition: margin-left 0.3s ease;
      margin-top: 6%;
      overflow: hidden;
    }

    // Add a class to handle the sidebar collapsed state
    &.sidebar-collapsed {
      .sidebar {
        width: 65px;
      }

      .content {
        margin-left: 65px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .new-investor-private-routes {
    .newInvestor_container {
      .content {
        transition: margin-left 0.3s ease;
        margin-top: 8%;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 820px) {
  .new-investor-private-routes {
    .newInvestor_container {
      .sidebar {
        top: 8.5%;
        left: 0;
        bottom: 0;
        // Add class for sidebar in fixed position
        // &.fixed {
        // }
      }

      // .sidebar::-webkit-scrollbar {
      // }

      /* Hide scrollbar for IE, Edge and Firefox */

      .content {
        // flex: 1;
        transition: margin-left 0.3s ease;
        margin-top: 10%;
        max-width: 100%;
        overflow: hidden;
      }

      // Add a class to handle the sidebar collapsed state
      &.sidebar-collapsed {
        .sidebar {
          width: 0;
          opacity: 0;
          transition: width 0.3s ease, opacity 0.3s ease;
        }

        .content {
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .new-investor-private-routes {
    .newInvestor_container {
      // background-color: #f9faf3;
      background-color: var(--investor-bg);

      .sidebar {
        left: 0;
        bottom: 0;
        // Add class for sidebar in fixed position
        &.fixed {
        }
      }

      .sidebar::-webkit-scrollbar {
      }

      /* Hide scrollbar for IE, Edge and Firefox */

      .content {
        // flex: 1;
        margin-left: 0px;
        transition: margin-left 0.3s ease;
        margin-top: 70px;
        max-width: 100%;
        overflow: hidden;
      }

      // Add a class to handle the sidebar collapsed state
      &.sidebar-collapsed {
        .sidebar {
          width: 0;
          opacity: 0;
          transition: width 0.3s ease, opacity 0.3s ease;
        }

        .content {
          margin-left: 0px;
        }
      }
    }
  }
}

// Utlities that can be called anywhere in Investor account
.d-l-grey {
  color: var(--d-l-grey);
}

.white-to-grey {
  background-color: var(--white-to-grey);
}
