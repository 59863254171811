:root {
	--background-color: #18191D80;
}
.dark-theme {
	--background-color: #18191D80;
}

.public-routes-container {
	

	.public-routes-main-container {
		display: flex;
		width: 100dvw;
		background: var(--background-secondary);

		.public-routes-main {
			width: 100%;
			// overflow: hidden;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}
