.custom-bs-carousel {
  min-width: 30vw;
  min-height: 30vh;

  max-width: 50vw;

  @media (max-width: 420px) {
    width: 90vw;
  }
}
