.chat_dashboard_container {
	// 80px is height of chat navbar and 70px is (height of mobileheader + margin-bottom)
	max-height: calc(100% - 80px - 56px);
	height: 100%;
	border-radius: var(--border-radius);
	// background: var(--white-to-grey);
	box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.11);
	text-align: center;
	position: relative;
	padding: 10px;
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	// background: radial-gradient(circle, #3f8cff 20%, #e78175 100%);
	background: radial-gradient(
		circle,
		rgba(36, 0, 54, 0.95) 20%,
		rgba(0, 0, 0, 0.95) 100%
	);

	@media (max-width: 820px) {
		border-radius: 0px;
	}

	@media (min-width: 821px) {
		// 85px is removed because mobileheader is not rendered beyond this height
		max-height: calc(100% - 80px);
	}

	.name_title {
		color: #000;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.time {
		color: #949494;
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.today_text {
		display: inline-block;
		padding: 0 20px;
		position: relative;
		z-index: 1;
		width: 95%;
		margin-top: 10px;
		color: #a0a0a0;
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			width: 47%;
			height: 1px;
			background-color: #ebebeb;
			transform: translateY(-50%);
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}

	.chat_messages_group {
		flex: 1 1 0;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 0px;
			display: none;
		}
		// scrollbar-gutter: stable;

		display: flex;
		flex-direction: column;
		& > div:first-child {
			margin-top: auto;
		}

		.date_header {
			width: max-content;
			margin: auto;
		}

		// scrollbar-width: thick;
		// scrollbar-color: var(--currentTheme) transparent;

		// &::-webkit-scrollbar {
		// 	width: 4px;
		// }

		// &::-webkit-scrollbar-thumb {
		// 	background-color: var(--currentTheme);
		// 	border-radius: 10rem;
		// }

		// &::-webkit-scrollbar-thumb:hover {
		// 	background-color: var(--currentTheme-dark);
		// }

		// &::-webkit-scrollbar-track {
		// 	background-color: transparent;
		// 	border-radius: 10rem;
		// }
	}

	.chat_messages {
		height: 80%;
	}

	.popup-close-button,
	.popup-ok_button {
		// width: 20%;
		height: 30px;
		flex-shrink: 0;
		border-radius: var(--border-radius);
		background: var(--currentTheme);
		color: var(--currentTheme-text);
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		border: none;
		margin: 5px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
