.purchase-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 25, 40, 0.8);
  backdrop-filter: blur(12px) saturate(180%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .purchase-modal-content {
    background: linear-gradient(145deg, #ffffff, #f8fafc);
    padding: 2.5rem;
    border-radius: 16px;
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2),
                0 5px 15px rgba(0, 0, 0, 0.1);
    animation: modalSlideIn 0.3s ease-out;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;
      font-size: 1.8rem;
      color: #666;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        background: #f0f0f0;
        color: #333;
      }
    }

    .modal-header {
      text-align: left;
      margin-bottom: 2.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h2 {
        margin-bottom: 0;
        color: #2d3748;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
      }

      .subtitle {
        color: #718096;
        font-size: 1.2rem;
        line-height: 1.5;
        font-weight: normal;
      }
    }

    .item-details-card {
      background: #ffffff;
      padding: 1.5rem;
      border-radius: 12px;
      margin-bottom: 2.5rem;
      border: 1px solid #e2e8f0;

      .card-header {
        margin-bottom: 1rem;
        
        h3 {
          color: #2d3748;
          font-size: 1.2rem;
          font-weight: 600;
        }
      }

      .details-grid {
        display: grid;
        gap: 1rem;

        .detail-item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0.75rem 0;
          border-bottom: 1px solid #e2e8f0;

          &:last-child {
            border-bottom: none;
          }

          .label {
            font-weight: 600;
            color: #4a5568;
            min-width: 120px;
          }

          .value {
            color: #2d3748;
            text-align: right;
            flex: 1;
            margin-left: 1rem;
          }
        }
      }
    }

    .purchase-form {
      .form-group {
        margin-bottom: 1.5rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          
          .label-text {
            color: #4a5568;
            font-weight: 500;
            font-size: 0.95rem;
          }
        }

        input {
          width: 100%;
          padding: 0.875rem;
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          font-size: 1rem;
          transition: all 0.2s ease;
          background: #f8fafc;

          &:focus {
            outline: none;
            border-color: #4299e1;
            background: white;
            box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
          }

          &::placeholder {
            color: #a0aec0;
          }
        }
      }

      .modal-actions {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        margin-top: 2.5rem;

        button {
          padding: 0.875rem 1.75rem;
          border-radius: 8px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          font-size: 1rem;

          &.cancel-btn {
            background: #f7fafc;
            border: 1px solid #e2e8f0;
            color: #4a5568;

            &:hover {
              background: #edf2f7;
              border-color: #cbd5e0;
            }
          }

          &.proceed-btn {
            background: #4299e1;
            border: none;
            color: white;

            &:hover {
              background: #3182ce;
              transform: translateY(-1px);
            }

            &.disabled {
              background: #cbd5e0;
              cursor: not-allowed;
              transform: none;
            }
          }
        }
      }
    }
  }
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 
