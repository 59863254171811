.register_container {
	display: flex;
	min-height: 100vh;
	background-color: rgb(253 242 248);

	.register_container_left {
		width: 45%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2rem;

		// Profile picture container
		.profile-section {
			text-align: center;

			.image-upload-container {
				width: 250px;
				height: 250px;
				border-radius: 50%;
				margin: 0 auto 2rem;
				cursor: pointer;
				overflow: hidden;
				border: 2px dashed var(--button-color);
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				background-color: #f5f5f5;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					position: relative;
					z-index: 1;
				}

				&:hover .edit-overlay {
					opacity: 1;
				}

				.edit-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.5);
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					transition: opacity 0.3s ease;
					color: white;
					font-size: 1.1rem;
					font-weight: 500;
					z-index: 2;

					.edit-icon {
						background: white;
						padding: 10px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						color: var(--button-color);
						
						&:hover {
							transform: scale(1.1);
							transition: transform 0.2s ease;
						}
					}
				}
			}

			.avatar-display {
				display: flex;
				justify-content: center;
				gap: 1rem;
				margin: 2rem 0;

				.avatar-image {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					cursor: pointer;
					transition: transform 0.2s;

					&:hover {
						transform: scale(1.1);
					}
				}
			}

			.community-message {
				font-size: 1.1rem;
				color: #666;
				margin-top: 1.5rem;
				text-align: center;
			}

			.image-label {
				width: 100%;
				height: 100%;
				display: block;
				position: relative;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.edit-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.3);
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0;
					transition: opacity 0.2s;
					border-radius: 50%;
					color: white;

					&:hover {
						opacity: 1;
					}
				}
			}

			.upload-info {
				text-align: center;
				color: #666;
				font-size: 0.9rem;
				margin: 1rem 0;
				padding: 0 1rem;
			}
		}
	}

	.register_container_right {
		width: 55%;
		padding: 2rem 4rem;

		// User type toggle
		.user-type-toggle {
			display: flex;
			justify-content: center;
			gap: 1rem;
			margin-bottom: 2rem;

			.toggle-btn {
				min-width: 120px;
				font-size: 1rem;
				padding: 0.75rem 1.5rem;
				border: 2px solid;
				border-radius: 25px;
				font-weight: 600;
				cursor: pointer;
				transition: all 0.3s ease;

				&.startup {
					border-color: #fd5901;
					color: #fd5901;
					&.active {
						background-color: #fd5901;
						color: white;
						box-shadow: 0 2px 4px rgba(253, 89, 1, 0.2);
					}
				}

				&.investor {
					border-color: #d3f36b;
					color: #749508;
					&.active {
						background-color: #d3f36b;
						color: black;
						box-shadow: 0 2px 4px rgba(46, 49, 146, 0.2);
					}
				}
			}
		}

		// Signup method toggle
		.signup-method-toggle {
			display: flex;
			justify-content: center;
			gap: 1rem;
			margin-bottom: 2rem;

			.method-btn {
				min-width: 160px;
				font-size: 0.95rem;
				padding: 0.75rem 1.5rem;
				border: 2px solid;
				border-radius: 25px;
				font-weight: 600;
				cursor: pointer;
				transition: all 0.3s ease;

				&.active {
					background-color: var(--button-color);
					color: var(--txt-color);
					box-shadow: 0 2px 4px var(--button-shadow);
				}
			}
		}

		// Form styling
		.form-group {
			margin-bottom: 1.5rem;

			input {
				width: 100%;
				padding: 0.8rem 1.2rem;
				border: 1px solid #ddd;
				border-radius: 10px;
				font-size: 1.1rem;
				
				&[type="tel"] {
					width: 120%;
					margin: 0 -10%;
					font-size: 1.2rem;
					letter-spacing: 0.5px;
					font-weight: 500;
					&::placeholder {
						font-size: 1.1rem;
						font-weight: normal;
						letter-spacing: normal;
					}
				}

				&:focus {
					border-color: #fd5901;
					outline: none;
				}
			}
		}

		// Company/Investor details button
		.add-company-details-btn {
			margin: 1.5rem 0;
			font-size: 1rem;
			width: 100%;
			padding: 1rem;
			border-radius: 10px;
			font-weight: 600;
			cursor: pointer;
			transition: all 0.3s;
			color: var(--txt-color);
			&.startup {
				background-color: #fd5901;
				box-shadow: 0 2px 4px rgba(253, 89, 1, 0.2);
			}

			&.investor {
				background-color: #d3f36b;
				box-shadow: 0 2px 4px rgba(46, 49, 146, 0.2);
			}
		}

		.submit-btn {
			width: 100%;
			padding: 1rem;
			border: none;
			border-radius: 10px;
			font-weight: 600;
			font-size: 1rem;
			cursor: pointer;
			transition: all 0.3s;
			background-color: var(--button-color);
			color: var(--txt-color);
			box-shadow: 0 2px 4px var(--button-shadow);

			&:hover {
				transform: translateY(-1px);
				box-shadow: 0 4px 6px var(--button-shadow);
			}
		}

		.login-redirect {
			text-align: center;
			margin-top: 2rem;
			padding-top: 1.5rem;
			border-top: 1px solid #eee;

			span {
				color: #666;
			}

			a {
				color: var(--button-color-text);
				text-decoration: none;
				font-weight: 600;
				margin-left: 0.5rem;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		.register_container_left,
		.register_container_right {
			padding: 2rem;
		}

		.back-button {
			top: 1rem;
			left: 1rem;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;

		.register_container_left,
		.register_container_right {
			width: 100%;
			padding: 1.5rem;
		}

		.register_container_left {
			.profile-section {
				.image-upload-container {
					width: 200px;
					height: 200px;
				}
			}
		}

		.user-type-toggle,
		.signup-method-toggle {
			flex-direction: row;
			flex-wrap: wrap;
			gap: 0.5rem;

			.toggle-btn,
			.method-btn {
				flex: 1;
				min-width: 140px;
				padding: 0.5rem 1rem;
			}
		}
	}

	@media (max-width: 480px) {
		.register_container_left {
			.profile-section {
				.image-upload-container {
					width: 150px;
					height: 150px;
				}
			}
		}

		.avatar-display {
			.avatar-image {
				width: 40px;
				height: 40px;
			}
		}

		.popup-content {
			padding: 1.5rem;
			width: 90%;
			margin: 0 auto;
		}
	}

	@media (min-width: 769px) and (max-width: 1280px) {
		flex-direction: row !important;
		.register_container_left,
		.register_container_right {
			width: 50%;
			padding: 2rem;
		}

		.back-button {
			top: 1.5rem;
			left: 1.5rem;
		}

		.user-type-toggle,
		.signup-method-toggle {
			flex-direction: row;
			justify-content: space-between;
		}

		.form-group {
			input {
				padding: 0.8rem;
			}
		}

		.submit-btn {
			padding: 0.8rem;
		}
	}
}

.create-account-heading {
	font-size: 2rem;
	font-weight: 700;
	color: #fd5901;
	text-align: center;
	margin-bottom: 2rem;
}

.company-details-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.popup-content {
	max-height: 90vh;
	overflow-y: auto;
	background: white;
	padding: 2.5rem;
	border-radius: 15px;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
	width: 500px;
	position: relative;

	h3 {
		color: #fd5901;
		margin-bottom: 1.5rem;
		font-weight: 600;
	}

	input,
	textarea,
	select {
		width: 100%;
		padding: 1rem;
		margin-bottom: 1.5rem;
		border: 1px solid #ddd;
		border-radius: 8px;
		font-size: 0.9rem;
		display: block;

		&:focus {
			border-color: #fd5901;
			outline: none;
			box-shadow: 0 0 0 2px rgba(253, 89, 1, 0.1);
		}
	}

	textarea {
		min-height: 100px;
		resize: vertical;
	}

	select {
		appearance: none;
		background-image: url("data:image/svg+xml,...");
		background-repeat: no-repeat;
		background-position: right 1rem center;
		background-size: 1em;
	}

	.image-upload-container {
		width: 120px;
		height: 120px;
		border-radius: 12px;
		margin: 0 auto 1.5rem;
		cursor: pointer;
		overflow: hidden;
		border: 2px dashed var(--button-color);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		background-color: #fff5f0;

		.logo-image {
			width: 100%;
			height: 100%;
			object-fit: contain; // Changed from cover to contain
			padding: 10px; // Add some padding inside
		}

		&:hover {
			background-color: #fff0e6;
			&::after {
				content: "Change Logo";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: var(--button-color);
				color: var(--txt-color);
				padding: 0.5rem;
				font-size: 0.8rem;
				text-align: center;
			}
		}
	}

	.upload-text {
		text-align: center;
		color: #666;
		font-size: 0.9rem;
		margin-bottom: 2rem;
	}

	// Add a preview container for when image is selected
	.logo-preview {
		position: relative;
		width: 120px;
		height: 120px;
		margin: 0 auto 1.5rem;
		border-radius: 12px;
		overflow: hidden;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: white;
		}

		.remove-logo {
			position: absolute;
			border: none;
			top: 5px;
			right: 5px;
			background: rgba(255, 255, 255, 0.9);
			border-radius: 50%;
			padding: 5px;
			cursor: pointer;
			color: var(--button-color);

			&:hover {
				background: white;
			}
		}
	}
}

.close-popup-btn {
	position: absolute;
	top: 1rem;
	right: 1rem;
	background: none;
	border: none;
	font-size: 1.5rem;
	color: var(--d-l-grey);
	cursor: pointer;
}

.popup-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}

.company-details-added {
	color: #28a745; // Green color to indicate success
	font-weight: bold;
	text-align: center;
	margin-top: 1rem;
}

.save-btn {
	width: 100%;
	padding: 1rem;
	background-color: var(--button-color);
	border: none;
	color: var(--txt-color);
	border-radius: 10px;
	font-weight: 600;
	margin: 1rem 0;
	cursor: pointer;
	transition: opacity 0.3s;

	&:hover {
		opacity: 0.9;
	}
}

.close-btn {
	width: 100%;
	padding: 1rem;
	background-color: transparent;
	border: 2px solid #fd5901;
	color: #fd5901;
	border-radius: 10px;
	font-weight: 600;
	margin: 1rem 0;
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		background-color: #fd5901;
		color: white;
	}
}

.social-links,
.color-card-data {
	margin: 1.5rem 0;

	input {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 1.5rem;
		}
	}
}

.form-section-title {
	color: #666;
	font-size: 0.9rem;
	margin: 1rem 0 0.5rem;
	font-weight: 600;
}

.user-type-toggle,
.signup-method-toggle {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 2rem;

	.toggle-btn,
	.method-btn {
		padding: 0.75rem 1.5rem;
		border: 2px solid;
		border-radius: 25px;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.3s ease;

		&.active {
			color: white;
		}
	}
}

.profile-section {
	.avatar-options {
		display: flex;
		justify-content: center;
		gap: 1rem;
		margin-top: 1rem;

		.avatar-option {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			cursor: pointer;
			transition: transform 0.2s;

			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

.company-details-status {
	text-align: center;
	color: #4caf50;
	padding: 0.5rem;
	margin: 1rem 0;
	font-weight: 600;
}

.avatar-display {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin: 2rem 0;

	.avatar-image {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}
}

.community-message {
	text-align: center;
	color: #666;
	font-size: 1.1rem;
	font-weight: 500;
	margin-top: 1rem;
}

.otp-input-container {
	margin-top: 1rem;

	input {
		width: 100%;
		padding: 0.8rem;
		border: 1px solid #ddd;
		border-radius: 8px;
		font-size: 1rem;
		text-align: center;
		letter-spacing: 4px;

		&:focus {
			border-color: var(--primary-color);
			outline: none;
		}
	}
}

.send-otp-btn {
	margin-top: 0.5rem;
	padding: 0.5rem 1rem;
	background-color: var(--primary-color);
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: opacity 0.3s;

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}

	&:hover:not(:disabled) {
		opacity: 0.9;
	}
}

.avatar-display {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin: 2rem 0;
}

// Add CSS variables for dynamic colors
:root {
	--button-color: #fd5901;
	--button-color-text: #fd5901;
	--button-shadow: rgba(253, 89, 1, 0.2);
	--txt-color: white;
}

.investor-theme {
	--button-color: #d3f36b;
	--button-color-text: #749508;
	--button-shadow: rgba(46, 49, 146, 0.2);
	--txt-color: black;
}

// Add these styles for the back button
.register-back-button {
	position: absolute;
	top: 2rem;
	left: 2rem;
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: var(--button-color-text);
	font-weight: 600;
	z-index: 10;

	svg {
		font-size: 1.5rem;
	}

	&:hover {
		opacity: 0.8;
	}
}
