.investor-private-routes {
	background-color: var(--startup-bg);
	background-image: radial-gradient(circle, #69027433, transparent 60%);
	background-repeat: no-repeat;
	background-size: 100% 120vh;

	@media (max-width: 378px) {
		width: 137vw;
	}

	.investor_home_container {
		display: flex;
		margin: 0;
		position: relative;

		// Sidebar styles
		.sidebar {
			position: fixed;
			top: calc(80px + 0%);
			left: 0;
			bottom: 0;
			z-index: 10;
			transition: width 0.3s ease;
			overflow: scroll;
			width: 210px;

			// Hide scrollbar across browsers
			&::-webkit-scrollbar {
				display: none;
			}
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */

			&.fixed {
				position: absolute;
				top: auto;
				bottom: 0;
			}
		}

		// Content styles
		.content {
			flex: 1;
			margin-left: 210px;
			transition: margin-left 0.3s ease;
			margin-top: 90px; // 80px + 10px
			overflow: hidden;
			min-height: calc(100dvh - 90px);
		}

		// Collapsed sidebar state
		&.sidebar-collapsed {
			.sidebar {
				width: 65px;

				@media (max-width: 820px) {
					width: 0;
					opacity: 0;
					transition: width 0.3s ease, opacity 0.3s ease;
				}
			}

			.content {
				margin-left: 65px;

				@media (max-width: 820px) {
					margin-left: 0;
				}
			}
		}
	}

	// Mobile styles
	@media (max-width: 820px) {
		.investor_home_container {
			.sidebar {
				top: 8.5%;
			}

			.content {
				margin-left: 0;
				max-width: 100%;
			}
		}
	}

	@media (max-width: 468px) {
		.investor_home_container {
			background-color: var(--startup-bg);

			.sidebar {
				top: 70px;
			}
		}
	}

	// Mobile bottom toolbar
	.mobile-bottom-toolbar {
		position: fixed;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);
		box-sizing: border-box;
		background-color: var(--white-to-grey);
		z-index: 10;

		a {
			color: rgb(113, 113, 113);

			&.active {
				color: var(--d-l-grey);
			}
		}
	}
}
