.investor_onelink_layout_wrapper {
  .investor_view_container {
    display: flex;
    background-color: var(--investor-bg);
    margin: 0;
    position: relative;

    .sidebar {
      // width: 300px;
      // background-color: #eaeef2;
      position: fixed;
      top: calc(80px + -1%);
      left: 0;
      bottom: 0;
      z-index: 100;
      transition: width 0.3s ease;
      overflow: scroll;
      // border: 1px solid blue;
      display: flex;
      flex-direction: column;

      // Add class for sidebar in fixed position
      &.fixed {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    }

    .sidebar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .sidebar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }

    .content {
      flex: 1;
      margin-left: 230px;
      transition: margin-left 0.3s ease;
      margin-top: calc(80px + 3rem);
      overflow: hidden;
      min-height: 100dvh;
    }

    // Add a class to handle the sidebar collapsed state
    &.sidebar-collapsed {
      .sidebar {
        width: 65px;
      }

      .content {
        margin-left: 65px;
      }
    }
  }

  @media (max-width: 820px) {
    .investor_view_container {
      .sidebar {
        left: 0;
        bottom: 0;
        // Add class for sidebar in fixed position
        // &.fixed {
        // }
      }

      //   .sidebar::-webkit-scrollbar {
      //   }

      /* Hide scrollbar for IE, Edge and Firefox */

      .content {
        // flex: 1;
        margin-left: 0px;
        transition: margin-left 0.3s ease;
        margin-top: calc(80px + 3rem);
        max-width: 100%;
        overflow: hidden;
      }

      // Add a class to handle the sidebar collapsed state
      &.sidebar-collapsed {
        .sidebar {
          width: 0;
          opacity: 0;
          transition: width 0.3s ease, opacity 0.3s ease;
        }

        .content {
          margin-left: 0px;
        }
      }
    }
  }

  @media (max-width: 468px) {
    .investor_view_container {
      background-color: var(--investor-bg);
      .sidebar {
        left: 0;
        bottom: 0;
        // Add class for sidebar in fixed position
        // &.fixed {
        // }
      }

      //   .sidebar::-webkit-scrollbar {
      //   }

      /* Hide scrollbar for IE, Edge and Firefox */

      .content {
        // flex: 1;
        margin-left: 0px;
        transition: margin-left 0.3s ease;
        margin-top: calc(80px + 2rem);
        max-width: 100%;
        overflow: hidden;
      }

      // Add a class to handle the sidebar collapsed state
      &.sidebar-collapsed {
        .sidebar {
          width: 0;
          opacity: 0;
          transition: width 0.3s ease, opacity 0.3s ease;
        }

        .content {
          margin-left: 0px;
        }
      }
    }
  }
}
