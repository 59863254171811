.products-container {
  padding: 1rem;

  .add-product-btn {
    background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
      opacity: 0.9;
    }
  }

  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;

    .product-card {
      background: var(--white-to-grey);
      border-radius: 0.5rem;
      overflow: hidden;
      transition: transform 0.2s;
      display: flex;
      flex-direction: column;
      
      &:hover {
        transform: translateY(-2px);
      }

      .product-image {
        position: relative;
        height: 200px;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .badge {
          position: absolute;
          top: 1rem;
          right: 1rem;
          padding: 0.25rem 0.75rem;
          border-radius: 1rem;
          font-size: 0.8rem;
          font-weight: 500;

          &.free {
            background: #4CAF50;
            color: white;
          }

          &.paid {
            background: #FD5901;
            color: white;
          }
        }
      }

      .product-content {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        flex: 1;

        h3 {
          color: var(--text-color);
          margin-bottom: 0.5rem;
          font-size: 1.1rem;
        }

        p {
          color: var(--d-l-grey);
          font-size: 0.9rem;
          margin-bottom: 1rem;
          line-height: 1.4;
        }

        .access-btn {
          margin-top: auto;
          display: inline-block;
          background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          text-decoration: none;
          font-size: 0.9rem;
          
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}

.urls-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .popup-content {
    background: var(--white-to-grey);
    padding: 2rem;
    border-radius: 0.5rem;
    width: 90%;
    max-width: 500px;

    h3 {
      margin-bottom: 1rem;
      color: var(--text-color);
    }

    .urls-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1.5rem;

      a {
        color: #FD5901;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }

    button {
      background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 0.5rem;
      cursor: pointer;
      
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast {
  border-radius: 8px;
  font-family: 'Outfit', sans-serif;
} 