.skeleton-loader {
	// background-color: #1b1b1b;
	background-color: var(--white-to-grey);
	border-radius: 8px;
	padding: 16px;
	width: 100%;

	.profile-info {
		display: flex;
		align-items: center;
		margin-bottom: 16px;

		.profile-image {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			margin-right: 12px;
		}

		.profile-details {
			.name {
				width: 120px;
				height: 16px;
				margin-bottom: 4px;
			}

			.location {
				width: 80px;
				height: 14px;
				margin-bottom: 4px;
			}

			.date {
				width: 100px;
				height: 14px;
			}
		}
	}

	.content-area {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		@media (min-width: 1024px) {
			height: 250px;
		}
		.title {
			width: 100%;
			height: 20px;
			margin-bottom: 8px;
		}

		.text {
			width: 100%;
			height: 16px;
			margin-bottom: 8px;

			// &:last-child {
			// 	margin-bottom: 0;
			// }
		}
	}

	.interaction-buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 16px;

		.button {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.skeleton-item {
		background-color: var(--skeleton-item-bg);
		animation: shimmer 0.8s infinite;
	}

	@keyframes shimmer {
		0% {
			background-color: var(--skeleton-item-bg);
		}
		50% {
			background-color: #3b3b3b;
		}
		100% {
			background-color: var(--skeleton-item-bg);
		}
	}
}
