.pfp {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.17);
    background: rgba(159, 159, 159, 0.19);
    height: 100%;
    justify-content: center;
    gap: 30px;
    p {
        font-size: 12px;
        text-align: center;
    }
}

@media (max-width: 426px) {
    .pfp {
        width: 50%;
        justify-content: none;
        gap: 10px;
        align-items: center;
        padding: 20px;
        // height: 120px;
    }
}
