$primary-color: #fd5901;
$secondary-color: #1b2430;
.navbar-container2 {
	position: fixed;
	top: -2px;
	left: 0;
	right: 0;
	z-index: 100;
	color: white;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 40px;
	background-color: #000000;

	@media (max-width: 768px) {
		width: 768px;
	}
	@media (max-width: 460px) {
		padding: 10px 20px;
		// max-width: 460px;
		width: 100dvw;
	}

	.navbar-logo {
		display: flex;
		align-items: center;
		gap: 10px;
		.logo {
			img {
				width: 150px;
				cursor: pointer;
				// filter: invert(1);
			}
			// @media (max-width: 769px) {
			// 	display: none;
			// }
		}
		.hamberger-logo {
			display: none;
			@media (max-width: 769px) {
				display: block;
				img {
					width: 30px;
					cursor: pointer;
					filter: invert(1);
				}
			}
		}
	}

	.navbar-items {
		.navbar,
		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 50px;
			a {
				text-decoration: none;
				color: white;
			}

			li {
				cursor: pointer;
				:hover {
					color: $primary-color;
				}
				@media (max-width: 769px) {
					display: none;
				}
			}
		}
	}

	.login-btn-container {
		// background: #2b2b2b;
		// border-radius: 15px;
		// padding: 5px 10px;
		// text-align: center;
		// line-height: 20px;
		// border: 0.3px solid white;
		// border-image-source: linear-gradient(180deg, #ffffff 0%, #636363 100%);
		// border-image-slice: 1;

		:hover {
			color: $primary-color;
		}

		#btn-460 {
			display: block;
			@media (min-width: 460px) {
				display: none;
			}
			position: relative;
			padding: 0.5rem 1rem;
			background: #050505; /* Solid background for the button */
			color: #fff;
			border-radius: 20px;
			border: 1px solid transparent; /* Transparent border */
			cursor: pointer;
			transition: background 0.3s ease;
			z-index: 1;
			/* Apply the gradient to the border using a pseudo-element */
			&:before {
				content: "";
				position: absolute;
				top: -1px;
				left: -1px;
				right: -1px;
				bottom: -1px;
				border-radius: 20px;
				padding: 1.5px; /* Adjust the thickness here */
				background: linear-gradient(87.49deg, #090edb 32.4%, #fd5901 90.21%);
				-webkit-mask: linear-gradient(#fff 0 0) content-box,
					linear-gradient(#fff 0 0);
				mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: destination-out;
				mask-composite: exclude;
				z-index: -1;
			}
		}
		#btn-461 {
			display: block;
			position: relative;
			padding: 0.7rem 2rem;
			background: #050505; /* Solid background for the button */
			color: #fff;
			border-radius: 20px;
			border: 1px solid transparent; /* Transparent border */
			cursor: pointer;
			transition: background 0.3s ease;
			z-index: 1;

			/* Apply the gradient to the border using a pseudo-element */
			&:before {
				content: "";
				position: absolute;
				top: -1px;
				left: -1px;
				right: -1px;
				bottom: -1px;
				border-radius: 20px;
				padding: 1.5px; /* Adjust the thickness here */
				background: linear-gradient(87.49deg, #090edb 32.4%, #fd5901 90.21%);
				-webkit-mask: linear-gradient(#fff 0 0) content-box,
					linear-gradient(#fff 0 0);
				mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
				-webkit-mask-composite: destination-out;
				mask-composite: exclude;
				z-index: -1;
			}
			@media (max-width: 461px) {
				display: none;
			}
		}
	}
}
.navbar-items-wrapper {
	@media (min-width: 769px) {
		display: none;
	}
	transform: translateX(-100%);
	transition: transform 0.5s ease;
	&.active {
		transform: translateX(0);
	}
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: #050505;
	color: white;
	width: 30%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	@media (max-width: 460px) {
		width: 60%;
	}
	.navbar-logo {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 20px 20px;
		.logo {
			img {
				width: 150px;
				cursor: pointer;
				// filter: invert(1);
			}
		}
		.hamberger-logo {
			display: none;
			@media (max-width: 769px) {
				display: block;
				img {
					width: 30px;
					cursor: pointer;
					filter: invert(1);
				}
			}
		}
	}

	.navbar,
	ul {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 0 20px;
		a {
			text-decoration: none;
			color: white;
		}

		li {
			cursor: pointer;
			:hover {
				color: $primary-color;
			}
		}
	}
}
