// EventModal.scss
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background-color: white;
	border-radius: 0.5rem;
	padding: 2rem;
	width: 100%;
	max-width: 500px;
	max-height: 90vh;
	position: relative;
	animation: modal-appear 0.3s ease-out;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;

	h2 {
		font-size: 1.25rem;
		font-weight: 600;
		color: #111827;
		margin-bottom: 1.5rem;
	}

	form {
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
	}
}
.modal-content::-webkit-scrollbar {
	display: none; /* Hide scrollbar in WebKit browsers */
}

.form-group {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	label {
		font-size: 0.875rem;
		font-weight: 500;
		color: #374151;
	}

	input,
	textarea,
	select {
		padding: 0.625rem;
		border: 1px solid #d1d5db;
		border-radius: 0.375rem;
		font-size: 0.875rem;
		color: #111827;
		background-color: white;
		transition: border-color 0.2s;

		&:focus {
			outline: none;
			border-color: #4285f4;
			box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
		}

		&::placeholder {
			color: #9ca3af;
		}
	}

	textarea {
		resize: vertical;
		min-height: 100px;
	}

	select {
		cursor: pointer;
	}
}
.form-group-time {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
	// input{
	//     width: 100%;
	// }
}

.modal-actions {
	display: flex;
	gap: 1rem;
	margin-top: 1rem;

	button {
		flex: 1;
		padding: 0.75rem;
		border-radius: 0.375rem;
		font-size: 0.875rem;
		font-weight: 500;
		cursor: pointer;
		transition: all 0.2s;
	}

	.create-btn {
		background-color: #000;
		color: white;
		border: none;

		&:hover {
			background-color: #333;
		}

		&:active {
			background-color: #000;
		}
	}

	.cancel-btn {
		background-color: white;
		color: #374151;
		border: 1px solid #d1d5db;

		&:hover {
			background-color: #f3f4f6;
		}
	}
}

@keyframes modal-appear {
	from {
		opacity: 0;
		transform: translateY(-20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.events-header {
	.create-event-btn {
		padding: 0.5rem 1rem;
		background-color: #000;
		color: white;
		border: none;
		border-radius: 0.375rem;
		font-size: 0.875rem;
		font-weight: 500;
		cursor: pointer;
		transition: background-color 0.2s;

		&:hover {
			background-color: #333;
		}
	}
}

.readonly-picker {
	background-color: #f5f5f5;
	cursor: not-allowed;
}
