// Global styles and variables
$sidebar-width: 220px;
$collapsed-sidebar-width: 65px;
$icon-size: 25px;
$mobile-breakpoint: 768px; // Mobile view breakpoint
$primary-color: #fd5901; // Primary brand color for active elements

.sidebar_container {
  width: 100%;
  margin-right: 0;
  padding: 0;
}

.sidebar-main {
  width: 20%;
  height: 100vh;
  background-color: var(--white-to-grey);
  position: absolute;
  border-radius: 0px 8px 8px 0px;
  border: 2px solid red;
}

.pro-sidebar {
  width: 100%;
  min-width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(211, 211, 211, 0.555);
  }
}

.pro-sidebar .pro-menu a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #20315f;
}

.hr-above-support {
  color: rgba(173, 173, 173, 0.2);
  border: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-item-content {
  display: contents;
  justify-content: center;
  align-items: center;
}
.pro-item-content a img {
  height: 20px;
}
.pro-item-content span {
  margin-left: 24px;
  font-weight: 400;
}

#header {
  position: relative;
  width: 220px;
}

.investor_view_sidebar {
  height: 100%;
  #header {
    height: 100%;
  }
}

.closemenu {
  position: absolute;
  right: 0px;
  z-index: 11111;
  top: 4.7rem;
}
.closemenu-Left {
  margin-top: 4.3rem;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 65px;
  min-width: 40px;
}

.investor_view_sidebar {
  #header .pro-sidebar-inner {
    border-radius: 0 20px 20px 0;
    background-color: var(--white-to-grey);
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: var(--d-l-grey);
  font-weight: bold;
}

.logotext {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 10px;
  h3 {
    height: 23px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #000000;
  }

  h4 {
    margin: 0;
    font-size: 10px;
    margin-bottom: 20px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  margin: auto;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
  justify-content: flex-start;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #eef2f3 0%, #eef2f3 100%);
}

#header .pro-sidebar-inner .pro-sidebar-layout .active-item {
  padding: 1px;
}

#header .logo {
  padding: 20px;
}

.investor_view_sidebar {
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background: #fd5901;
    * {
      color: #fff !important;
    }
  }

  .items-active {
    color: #fd5901 !important;
  }

  .invest-now {
    background: #fd5901 !important;
    color: white !important;
  }
}

// Mobile Bottom Navigation Styles
@media (max-width: $mobile-breakpoint) {
  .bottom-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: var(--white-to-grey);
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10000s;

    .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: var(--d-l-grey);
      font-size: 12px;

      .items-active {
        color: $primary-color;
        font-weight: bold;
      }

      svg {
        margin-bottom: 5px;
      }
    }

    .invest-now {
      background: $primary-color;
      color: white !important;
      padding: 10px 20px;
      border-radius: 20px;
      font-weight: bold;
    }
  }
}
