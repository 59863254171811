.meeting-scheduler {
	min-height: 100vh;
	padding: 2rem;
	background-color: var(--startup-bg);
	background-image: radial-gradient(circle, rgba(105, 2, 116, 0.2), transparent 60%);
	overflow-y: scroll;
	&::-webkit-scrollbar {
		width: 0px;
	}


	&__container {
		display: grid;
		grid-template-columns: 1fr 1.5fr;
		gap: 2rem;

		max-width: 1400px;
		margin: 0 auto;

		@media (max-width: 992px) {
			grid-template-columns: 1fr;
		}
	}

	&__left, &__right {
		background: var(--white-to-grey);

		border-radius: 12px;
		padding: 2rem;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		height: max-content;
		position: sticky;
		top: 2rem;
	}

	.header-section {
		margin-bottom: 2rem;
		border-bottom: 1px solid var(--bs-border-color);
		padding-bottom: 1.5rem;
		position: relative;


		.back-button {
			position: relative;
			top: -1rem;
			left: -1rem;
			background: none;
			border: none;
			color: var(--bs-body-color);
			display: flex;
			align-items: center;
			gap: 0.5rem;
			margin-bottom: 1rem;
			padding: 0.5rem;
			border-radius: 8px;
			transition: background-color 0.2s;

			&:hover {
				background-color: var(--bs-secondary-color);
			}
		}
	}

	.profile {
		display: flex;
		gap: 1rem;
		align-items: center;

		&__image {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			object-fit: cover;
		}

		&__info {
			h2 {
				margin: 0;
				color: var(--text-color);
				font-size: 1.5rem;
			}


			p {
				margin: 0.25rem 0 0;
				color: var(--bs-grey-white);
			}
		}
	}

	.details-section {
		.meeting-info {

			display: flex;
			align-items: center;
			gap: 1rem;
			margin-bottom: 1rem;
			color: var(--bs-grey-white);

			&.price-info {
				.price-tag {
					display: flex;
					align-items: center;
					gap: 0.5rem;

					.original-price {
						text-decoration: line-through;
						color: var(--bs-grey-white);
					}


					.new-price {
						color: var(--bs-primary);
						font-weight: 600;
					}
				}

				.free-tag {
					color: var(--bs-success);
					font-weight: 600;
				}

			}
		}

		.description-section {
			margin-top: 2rem;

			h4 {
				color: var(--text-color);
				margin-bottom: 1rem;
			}

			p {
				color: var(--bs-grey-white);
				line-height: 1.6;
			}
		}
	}

	.calendar-section {
		margin-bottom: 2rem;

		.custom-calendar {
			width: 100%;
			border: none;
			background: var(--bs-body-bg);
			padding: 1rem;
			border-radius: 12px;

			.react-calendar__navigation {
				display: flex;
				margin-bottom: 1rem;

				button {
					background: none;
					border: none;
					padding: 0.5rem;
					margin: 0 0.25rem;
					cursor: pointer;
					color: var(--bs-body-color);

					&:hover {
						background-color: var(--bs-secondary-color);
						border-radius: 4px;
					}

					&:disabled {
						opacity: 0.5;
						cursor: not-allowed;
					}
				}

				.react-calendar__navigation__label {
					font-weight: bold;
					font-size: 1.1rem;
					color: var(--bs-body-color);
				}

			}

			.react-calendar__month-view__weekdays {
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 0.8em;
				color: var(--text-color);

				abbr {
					text-decoration: none;
				}
			}

			.react-calendar__tile {
				padding: 0.75rem;
				background: none;
				border: none;
				text-align: center;
				line-height: 1;
				font-size: 1rem;
				cursor: pointer;
				transition: background-color 0.2s;
				color: var(--bs-body-color);

				&:hover {
					background-color: var(--bs-secondary-color);
					border-radius: 4px;

				}

				&--active {
					background-color: var(--bs-primary) !important;
					color: white;
					border-radius: 4px;

					&:hover {
						background-color: var(--bs-primary-dark) !important;
					}
				}

				&:disabled {
					opacity: 0.5;
					cursor: not-allowed;
					//background-color: var(--bs-secondary-color);

					&:hover {
						background-color: var(--bs-secondary-color);
					}
				}
			}
		}
	}


	.time-slots {
		h4 {
			color: var(--text-color);
			margin-bottom: 1rem;
		}

		&__grid {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
			gap: 0.5rem;

			button {
				padding: 0.75rem;
				border: 1px solid var(--bs-border-color);
				border-radius: 8px;
				background: var(--bs-body-bg);
				color: var(--bs-body-color);
				transition: all 0.2s;

				&:hover {
					background: var(--bs-secondary-color);
				}

				&.selected {
					background: var(--bs-primary);
					color: white;
					border-color: var(--bs-primary);
				}

				&.booked {
					background-color: #e0e0e0;
					color: #999;
					cursor: not-allowed;
					
					&:hover {
						background-color: #e0e0e0;
					}
				}
			}
		}
	}

	.confirm-details-btn {
		width: 100%;
		padding: 1rem;
		margin-top: 2rem;
		background: var(--bs-primary);
		color: white;
		border: none;
		border-radius: 8px;
		font-weight: 500;
		transition: background-color 0.2s;

		&:hover {
			background: darken(#1a73e8, 10%);
		}

		&:disabled {
			background: var(--bs-secondary);
			cursor: not-allowed;
		}
	}

	.details-form-modal {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1000;
		margin-top: 3rem !important;

		.modal-content {
			position: relative;
			background: var(--background-color);
			padding: 2rem;
			padding-top: 0 !important;
			border-radius: 12px;
			width: 90%;
			max-width: 600px;
			max-height: 80vh;
			overflow-y: auto;
			margin-top: 0 !important;

			.back-button {
				position: relative;
				top: 1rem;
				left: -1rem;
				background: none;
				width: 20%;
				border: none;
				color: var(--bs-body-color);
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 0.5rem;
				border-radius: 8px;
				transition: background-color 0.2s;
				z-index: 1000;

				&:hover {
					background-color: var(--bs-secondary-color);
				}
			}

			.form-section {
				margin-top: 3rem;
				h3 {
					color: var(--text-color);
					margin-bottom: 1.5rem;
				}

				form {
					display: flex;
					flex-direction: column;
					gap: 1rem;

					input, textarea {
						padding: 0.75rem;
						border: 1px solid var(--bs-border-color);
						border-radius: 8px;
						background: var(--bs-body-bg);
						color: var(--bs-body-color);

						&:focus {
							outline: none;
							border-color: var(--bs-primary);
						}
					}

					.order-summary {
						background: var(--background-color);
						padding: 1.5rem;
						border-radius: 8px;
						margin: 1rem 0;

						h4 {
							color: var(--text-color);
							margin-bottom: 1rem;
						}

						.summary-item {
							display: flex;
							justify-content: space-between;
							margin-bottom: 0.5rem;
							color: var(--bs-grey-white);

							&.discount {
								color: var(--bs-success);
							}

							&.total {
								margin-top: 1rem;
								padding-top: 1rem;
								border-top: 1px solid var(--bs-border-color);
								font-weight: 600;
								color: var(--text-color);
							}
						}
					}

					button[type="submit"] {
						padding: 1rem;
						background: var(--bs-primary);
						color: white;
						border: none;
						border-radius: 8px;
						font-weight: 500;
						margin-top: 1rem;

						&:hover {
							background: darken(#1a73e8, 10%);
						}

						&:disabled {
							background: var(--bs-secondary-bg);
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
}

.meeting-description {
	margin: 1.5rem 0;
	color: #444;
	line-height: 1.6;
}

.book-meeting {
	width: 100%;
	padding: 0.8rem;
	background-color: #1a73e8;
	color: white;
	border: none;
	border-radius: 6px;
	cursor: pointer;
	font-weight: 500;
	transition: background-color 0.2s;

	&:hover {
		background-color: #1557b0;
	}
}

// Loader styles
.loader-container {
	position: fixed; // Changed to fixed positioning
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.6); // Made semi-transparent
	backdrop-filter: blur(3px); // Added subtle blur effect
	z-index: 1000; // Ensure loader appears above other content
}

.loader {
	width: 40px; // Slightly reduced size
	height: 40px;
	border: 2px solid rgba(26, 115, 232, 0.2); // Made border more subtle
	border-top: 2px solid #1a73e8;
	border-radius: 50%;
	animation: spin 0.8s linear infinite; // Slightly faster animation
	position: relative;

	&-inner {
		position: absolute;
		width: 75%;
		height: 75%;
		border: 2px solid rgba(26, 115, 232, 0.2);
		border-top: 2px solid #1a73e8;
		border-radius: 50%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: spin 0.6s linear infinite reverse; // Slightly faster animation
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

// Responsive styles
@media (max-width: 1024px) {
	.meeting-scheduler {
		padding: 1rem;

		&__container {
			flex-direction: column;
			gap: 2rem;
		}

		&__left {
			padding-right: 0;
			border-right: none;
			border-bottom: 1px solid #eee;
			padding-bottom: 2rem;
		}
	}

	.time-slots__grid {
		grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
	}
}

@media (max-width: 768px) {
	.meeting-scheduler {
		&__container {
			padding: 1rem;
		}
	}

	.calendar-container {
		.custom-calendar {
			.react-calendar__tile {
				padding: 0.5rem;
				font-size: 0.9rem;
			}
		}
	}

	.time-slots__grid {
		grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

		button {
			padding: 0.4rem;
			font-size: 0.9rem;
		}
	}
}

@media (max-width: 480px) {
	.meeting-scheduler {
		padding: 1rem;

		&__container {
			flex-direction: column;
			gap: 2rem;
		}

		&__left {
			padding-right: 0;
			border-right: none;
			border-bottom: 1px solid #eee;
			padding-bottom: 0;
		}
	}
	.profile {
		flex-direction: column;
		margin-bottom: 0;
		// align-items: center;
		// text-align: center;
	}

	.meeting-description {
		margin: 0.5rem 0;
	}

	.time-slots__grid {
		grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
	}

	.booking-form {
		input,
		textarea,
		button {
			font-size: 0.9rem;
		}
	}
}

// Additional utility classes
.text-center {
	text-align: center;
}

.mt-1 {
	margin-top: 0.25rem;
}
.mt-2 {
	margin-top: 0.5rem;
}
.mt-3 {
	margin-top: 1rem;
}
.mt-4 {
	margin-top: 1.5rem;
}

.mb-1 {
	margin-bottom: 0.25rem;
}
.mb-2 {
	margin-bottom: 0.5rem;
}
.mb-3 {
	margin-bottom: 1rem;
}
.mb-4 {
	margin-bottom: 1.5rem;
}

// Error state styles
.error {
	color: #dc3545;
	font-size: 0.875rem;
	margin-top: 0.25rem;
}

.input-error {
	border-color: #dc3545 !important;

	&:focus {
		box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.25) !important;
	}
}

// Success state styles
.success {
	color: #28a745;
	font-size: 0.875rem;
	margin-top: 0.25rem;
}

// Disabled state styles
.disabled {
	opacity: 0.6;
	pointer-events: none;
	cursor: not-allowed;
}
