@import "bootstrap/scss/functions";

:root,
[data-bs-theme="light"] {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-black: #000;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-grey-white: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f5f5f5;
	--bs-dark: #212529;
	--bs-startup: #fd5901;
	--bs-investor: #d3f36b;
	--bs-primary-rgb: 13, 110, 253;
	--bs-secondary-rgb: 108, 117, 125;
	--bs-success-rgb: 25, 135, 84;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 255, 193, 7;
	--bs-danger-rgb: 220, 53, 69;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 33, 37, 41;
	--bs-startup-rgb: 253, 89, 1;
	--bs-investor-rgb: 211, 243, 107;

	--bs-primary-text-emphasis: #052c65;
	--bs-secondary-text-emphasis: #2b2f32;
	--bs-success-text-emphasis: #0a3622;
	--bs-info-text-emphasis: #055160;
	--bs-warning-text-emphasis: #664d03;
	--bs-danger-text-emphasis: #58151c;
	--bs-light-text-emphasis: #495057;
	--bs-dark-text-emphasis: #495057;
	--bs-startup-text-emphasis: #fd5901;
	--bs-investor-text-emphasis: #d3f36b;

	--bs-primary-bg-subtle: #cfe2ff;
	--bs-secondary-bg-subtle: #e2e3e5;
	--bs-white-bg-subtle: #2f2f2f;
	--bs-success-bg-subtle: #d1e7dd;
	--bs-info-bg-subtle: #cff4fc;
	--bs-warning-bg-subtle: #fff3cd;
	--bs-danger-bg-subtle: #f8d7da;
	--bs-light-bg-subtle: #fcfcfd;
	--bs-dark-bg-subtle: #ced4da;
	--bs-startup-bg-subtle: #fd5901;
	--bs-investor-bg-subtle: #d3f36b;

	--bs-primary-border-subtle: #9ec5fe;
	--bs-secondary-border-subtle: #c4c8cb;
	--bs-success-border-subtle: #a3cfbb;
	--bs-info-border-subtle: #9eeaf9;
	--bs-warning-border-subtle: #ffe69c;
	--bs-danger-border-subtle: #f1aeb5;
	--bs-light-border-subtle: #e9ecef;
	--bs-dark-border-subtle: #adb5bd;
	--bs-startup-border-subtle: #fd5901;
	--bs-investor-border-subtle: #d3f36b;

	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
		"Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.15),
		rgba(255, 255, 255, 0)
	);
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #333;
	--bs-body-color-rgb: 51, 51, 51;
	--bs-body-bg: #fff;
	--bs-body-bg-rgb: 234, 238, 247;
	--bs-emphasis-color: #000;
	--bs-emphasis-color-rgb: 0, 0, 0;
	--bs-secondary-color: rgba(33, 37, 41, 0.75);
	--bs-secondary-color-rgb: 33, 37, 41;
	--bs-secondary-bg: #e9ecef;
	--bs-secondary-bg-rgb: 233, 236, 239;
	--bs-tertiary-color: rgba(33, 37, 41, 0.5);
	--bs-tertiary-color-rgb: 33, 37, 41;
	--bs-tertiary-bg: #f8f9fa;
	--bs-tertiary-bg-rgb: 248, 249, 250;
	--bs-heading-color: inherit;
	--bs-link-color: #0d6efd;
	--bs-link-color-rgb: 13, 110, 253;
	--bs-link-decoration: underline;
	--bs-link-hover-color: #0a58ca;
	--bs-link-hover-color-rgb: 10, 88, 202;
	--bs-code-color: #d63384;
	--bs-highlight-color: #212529;
	--bs-highlight-bg: #fff3cd;
	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: #dee2e6;
	--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
	--bs-border-radius: 0.375rem;
	--bs-border-radius-sm: 0.25rem;
	--bs-border-radius-lg: 0.5rem;
	--bs-border-radius-xl: 1rem;
	--bs-border-radius-xxl: 2rem;
	--bs-border-radius-2xl: var(--bs-border-radius-xxl);
	--bs-border-radius-pill: 50rem;
	--bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	--bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
	--bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
	--bs-focus-ring-width: 0.25rem;
	--bs-focus-ring-opacity: 0.25;
	--bs-focus-ring-color: rgba(13, 110, 253, 0.25);
	--bs-form-valid-color: #198754;
	--bs-form-valid-border-color: #198754;
	--bs-form-invalid-color: #dc3545;
	--bs-form-invalid-border-color: #dc3545;

	// Custom colors
	--startup-bg: #eaeef2;
	--investor-bg: #f9faf3;
	--white-to-grey: white;
	--white-to-grey-container: white;
	--w-t-n: #333333;
	--w-t-b: #333333;
	--d-l-grey: var(--bs-gray-900);
	--tag-box: #c6c6c636;
	--white-to-dgrey: var(--bs-light);
	--text-color: #000000;
	--background-color: #ffffff;
	--ex-key-focus-bg: var(--bs-gray-300);

	// Chat page colors
	--chat-page-bg: var(--bs-gray-200);
	// --chat-page-bg: var(--bs-gray-400);
	--chat-sidebar-chat-border: var(--bs-gray-400);
	--chat-page-nav: #ffffff;

	.card {
		--bs-card-bg: #ffffff;
	}

	--skeleton-item-bg: #e2e2e233;

	// --background-secondary: #1a1a1a;
	--background-secondary: #f0f0f0;
}

// Dark Mode colors
// Build dark mode with sass
$color-mode-type: data;

@include color-mode(dark) {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-black: #000;
	--bs-white: #fff;
	--bs-grey-white: #f8f9fa;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #242427;
	--bs-dark: #212529;
	--bs-startup: #926a54;
	--bs-investor: #d3f36b;
	--bs-primary-rgb: 13, 110, 253;
	--bs-secondary-rgb: 108, 117, 125;
	--bs-success-rgb: 25, 135, 84;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 255, 193, 7;
	--bs-danger-rgb: 220, 53, 69;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 33, 37, 41;
	--bs-startup-rgb: 253, 89, 1;
	--bs-investor-rgb: 211, 243, 107;

	--bs-primary-text-emphasis: #052c65;
	--bs-secondary-text-emphasis: #2b2f32;
	--bs-success-text-emphasis: #0a3622;
	--bs-info-text-emphasis: #055160;
	--bs-warning-text-emphasis: #664d03;
	--bs-danger-text-emphasis: #58151c;
	--bs-light-text-emphasis: #495057;
	--bs-dark-text-emphasis: #495057;
	--bs-startup-text-emphasis: #fd5901;
	--bs-investor-text-emphasis: #d3f36b;

	--bs-primary-bg-subtle: #cfe2ff;
	--bs-secondary-bg-subtle: #e2e3e5;
	--bs-success-bg-subtle: #d1e7dd;
	--bs-info-bg-subtle: #cff4fc;
	--bs-warning-bg-subtle: #fff3cd;
	--bs-danger-bg-subtle: #f8d7da;
	--bs-light-bg-subtle: #fcfcfd;
	--bs-dark-bg-subtle: #ced4da;
	--bs-startup-bg-subtle: #fd5901;
	--bs-investor-bg-subtle: #d3f36b;

	--bs-primary-border-subtle: #9ec5fe;
	--bs-secondary-border-subtle: #c4c8cb;
	--bs-success-border-subtle: #a3cfbb;
	--bs-info-border-subtle: #9eeaf9;
	--bs-warning-border-subtle: #ffe69c;
	--bs-danger-border-subtle: #f1aeb5;
	--bs-light-border-subtle: #e9ecef;
	--bs-dark-border-subtle: #adb5bd;
	--bs-startup-border-subtle: #fd5901;
	--bs-investor-border-subtle: #d3f36b;

	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
		"Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.15),
		rgba(255, 255, 255, 0)
	);
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #fafafa;
	--bs-body-color-rgb: 250, 250, 250;
	//   --bs-body-bg: #333;
	--bs-body-bg: ##060810;
	--bs-body-bg-rgb: 51, 51, 51;
	--bs-emphasis-color: #000;
	--bs-emphasis-color-rgb: 0, 0, 0;
	--bs-secondary-color: rgba(33, 37, 41, 0.75);
	--bs-secondary-color-rgb: 33, 37, 41;
	--bs-secondary-bg: #e9ecef;
	--bs-secondary-bg-rgb: 233, 236, 239;
	--bs-tertiary-color: rgba(33, 37, 41, 0.5);
	--bs-tertiary-color-rgb: 33, 37, 41;
	--bs-tertiary-bg: #f8f9fa;
	--bs-tertiary-bg-rgb: 248, 249, 250;

	--bs-heading-color: inherit;

	--bs-link-color: #2566c9;
	--bs-link-color-rgb: 37, 102, 201;
	--bs-link-decoration: underline;
	--bs-link-hover-color: #0a58ca;
	--bs-link-hover-color-rgb: 10, 88, 202;

	--bs-code-color: #d63384;

	--bs-highlight-color: #212529;
	--bs-highlight-bg: #fff3cd;

	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: var(--bs-gray-600);
	--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
	--bs-border-radius: 0.375rem;
	--bs-border-radius-sm: 0.25rem;
	--bs-border-radius-lg: 0.5rem;
	--bs-border-radius-xl: 1rem;
	--bs-border-radius-xxl: 2rem;
	--bs-border-radius-2xl: var(--bs-border-radius-xxl);
	--bs-border-radius-pill: 50rem;

	--bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	--bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
	--bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);

	--bs-focus-ring-width: 0.25rem;
	--bs-focus-ring-opacity: 0.25;
	--bs-focus-ring-color: rgba(var(--bs-startup-border-subtle), 0.25);

	--bs-form-valid-color: #198754;
	--bs-form-valid-border-color: #198754;
	--bs-form-invalid-color: #dc3545;
	--bs-form-invalid-border-color: #dc3545;

	// Custom colors
	--startup-bg: #060810;
	--investor-bg: #060810;
	--white-to-grey: #11131a;
	--white-to-grey-container: #18191dcc;
	--w-t-n: #ddff71;
	--w-t-b: white;
	--dark-btn-investor: #18181899;
	--white-to-dgrey: #272727;
	--d-l-grey: var(--bs-gray-100);
	--tag-box: #979797;
	--darkMode-currentTheme: var(--currentTheme);
	--text-color: #ffffff;
	--background-color: #000000;
	--ex-key-focus-bg: white;

	// Chat page colors
	--chat-page-bg: var(--bs-black);
	--chat-sidebar-chat-border: var(--bs-gray-700);
	--chat-page-nav: #1d1d1d;

	.card {
		--bs-card-bg: #1e1f24;
	}

	--skeleton-item-bg: #2b2b2b;
	// .btn {
	//   --bs-btn-bg: #979797;
	//   --bs-btn-hover-border-color: rgb(141, 141, 141);
	// }
	.btn-startup {
		--bs-btn-bg: #{$startup};
		--bs-btn-hover-border-color: #{tint-color($startup, 15%)};
	}

	.form-input {
		label {
			color: var(--bs-gray-100);
		}
	}

	// modal
	.modal {
		background-color: rgba($color: shade-color($body-bg, 50%), $alpha: 0.25);
	}

	--background-secondary: #1a1a1a;
}
