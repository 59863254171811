:root {
	--bg-color: #18191D80;
	--text-color: #333333;
	--text-color-secondary: #848587;
	--accent-color: #fd5901;
	--background-color: #18191D80;
	--background-color-secondary: #37344433;
	--background-btn: rgba(52, 55, 68, 0.2);
}

.dark-theme {
	--bg-color: var(--white-to-grey);
	--text-color: #ffffff;
	--text-color-secondary: #9ca3af;
	--accent-color: #fd5901;
	--background-color: #1e1f24;
	--background-color-secondary: rgba(6, 6, 6, 0.2);
	--background-btn: rgb(30, 30, 30);
}

.navbar-public-container {
	width: 100%;
	height: 60px;
	background: linear-gradient(180deg, #1e1e2e, #1e1e2e);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
	box-sizing: border-box;
	position: fixed;
	z-index: 110;

	@media (max-width: 820px) {
		padding: 0 1rem;
	}

	@media (max-width: 480px) {
		padding: 0 0.5rem;
	}

	.navbar-public-logo {
		width: auto;
		display: flex;
		gap: 0.5rem;

		.navbar-public-sidebar-toggle {
			cursor: pointer;
			@media (min-width: 481px) {
				display: none;
			}
			svg {
				fill: var(--text-color);
			}
		}

		img {
			height: 35px;
			&:hover {
				cursor: pointer;
			}
		}

		@media (max-width: 480px) {
			img {
				height: 30px;
			}
		}
	}

	.navbar-public-links {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1rem;

		@media (max-width: 820px) {
			display: none;
		}

		a {
			text-decoration: none;
			color: var(--text-color);
			font-size: 1.25rem;

			&:hover {
				color: var(--accent-color);
			}
		}
	}

	.navbar-public-profile-items {
		display: flex;
		align-items: center;
		gap: 1rem;
		justify-content: space-between;

		@media (max-width: 820px) {
			gap: 0.5rem;
		}

		svg {
			fill: var(--text-color);
		}

		.notification-container {
			position: relative;
			cursor: pointer;
			width: 49px;
			height: 49px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--background-color-secondary);

			@media (max-width: 480px) {
				width: 40px;
				height: 40px;
			}

			.notification-count {
				position: absolute;
				background: red;
				bottom: 0;
				right: 0;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 13px;
				color: #fff;
			}
		}

		.message-container {
			width: 49px;
			height: 49px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--background-color-secondary);
			position: relative;
			cursor: pointer;
			@media (max-width: 480px) {
				width: 40px;
				height: 40px;
			}

			.message-count {
				position: absolute;
				top: 0;
				right: 0;
				background-color: #fd5901;
				color: black;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: black;
				border: 1px solid #fd5901;
				transform: translate(25%, -25%);
				animation: radial-wave 1.5s infinite;
			}
		}

		.profile-container {
			width: 49px;
			height: 49px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--background-color-secondary);
			position: relative;
			@media (max-width: 480px) {
				width: 40px;
				height: 40px;
			}

			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				object-fit: cover;
				cursor: pointer;
			}

			.profile-dropdown {
				position: absolute;
				top: 100%;
				right: 0;
				background-color: var(--background-color);
				// border: 1px solid var(--text-color-secondary);
				border-radius: 8px;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
				margin-top: 10px;
				min-width: 150px;
				z-index: 120;

				div {
					padding: 10px 15px;
					cursor: pointer;
					color: var(--text-color);

					&:hover {
						background-color: var(--background-color-secondary);
						color: var(--accent-color);
					}

					// &:first-child {
					// 	border-bottom: 1px solid var(--text-color-secondary);
					// }
				}
			}
		}
	}
}
