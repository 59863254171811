.our_investor_container {
  background-color: #fff;
  margin-top: 30px;

  .our_investor {
    color: #fff;

    h2 {
      color: #fff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      text-align: center;
      letter-spacing: -0.002em;
      padding: 80px;
    }
  }

  .our_investor_card {
    box-sizing: border-box;
    width: 357px;
    height: 375px;
    border: 1px solid #dbdbdb;
    border-radius: var(--border-radius);
    color: #fff;
    background: #fff;

    .text_center {
      margin: 20px 0px;
    }

    .inside_card {
      /* margin: 26px; */

      img {
        height: 120px;
        width: 120px;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: -0.002em;
        color: #000000;
        position: relative;
      }

      .underline_one {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 38%;
          width: 25%;
          height: 3px;
          background-color: #fd5901;
        }
      }
      .underline_two {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 27%;
          width: 46%;
          height: 3px;
          background-color: #fd5901;
        }
      }

      .underline_three {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 20%;
          width: 60%;
          height: 3px;
          background-color: #fd5901;
        }
      }
      .underline_four {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 15%;
          width: 66%;
          height: 3px;
          background-color: #fd5901;
        }
      }
      .underline_five {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 30%;
          width: 40%;
          height: 3px;
          background-color: #fd5901;
        }
      }

      .underline_six {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 30%;
          width: 40%;
          height: 3px;
          background-color: #fd5901;
        }
      }

      .underline_seven {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 27%;
          width: 46%;
          height: 3px;
          background-color: #fd5901;
        }
      }
      .underline_eight {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 27%;
          width: 46%;
          height: 3px;
          background-color: #fd5901;
        }
      }
      .underline_nine {
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 27%;
          width: 46%;
          height: 3px;
          background-color: #fd5901;
        }
      }

      h4 {
        color: #414141;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .why_choose_us_container {
    .why_choose_us {
      h2 {
        font-size: 26px;
        padding: 20px;
      }
    }
  }

  .our_investor_container {
    .our_investor_card {
      box-sizing: border-box;
      width: 310px;
      height: 365px;
      border: 1px solid #e3e3e3;
      border-radius: var(--border-radius);
      color: #fff;
      background: #fcfcfc;

      .text_center {
        margin: 20px 0px;
      }

      .inside_card {
        /* margin: 26px; */

        img {
          height: 120px;
          width: 120px;
        }

        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 45px;
          text-align: center;
          letter-spacing: -0.002em;
          color: #000000;
        }

        h4 {
          color: #414141;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }
}

.mb-69 {
  margin-bottom: 69px;
}

@media screen and (max-width: 768px) {


}