.feedpostcard_main_container {
	width: 100%;
	margin-inline: auto;

	@media (max-width: 378px) {
		margin: 0;
		// width: 110vw;
		overflow-x: hidden;
	}

	.feedpostcard_container {
		@media (max-width: 378px) {
			padding: 10px;

			.box {
				padding: 10px;
				margin: 0;
				border-radius: 0;
			}
		}

		.repost_container {
			// padding: 2px 5px;
			position: relative;
			.repost_options {
				position: absolute;
				top: 45px;
				right: -90px;
				// right: 0;

				width: 220px;

				border: 1px solid lightgray;
				background-color: var(--white-to-grey);
				padding: 10px;

				display: flex;
				flex-direction: column;
				gap: 5px;
				z-index: 10;

				.single_option {
					display: flex;
					align-items: center;
					gap: 5px;

					&:hover {
						background-color: var(--bs-light);
					}

					.title {
						font-size: 12px;
						font-weight: 500;
						color: var(--d-l-grey);
					}
					.description {
						font-size: 10px;
						font-weight: 100;
					}

					@media (max-width: 420px) {
						.description {
							display: none;
						}
					}
				}
			}
		}
		.para_container {
			display: flex;
			margin: auto;
			flex-direction: column;

			@media (max-width: 378px) {
				margin: 0;

				.para_container_text {
					padding: 8px;
					margin: 5px 0;

					p {
						font-size: 0.9rem;
					}
				}
			}

			.para_container_text {
				background: rgba(52, 55, 68, 0.2);
				padding: 10px 20px;
				border-radius: 16px;
				// margin-bottom: 15px;
				p {
					flex-shrink: 0;
					// border-radius: var(--border-radius);
					// border-top: 1px solid var(--bs-border-color);
					// background: #eaeef2;
					// color: #373737;
					color: var(--d-l-grey);
					font-size: 1rem;
					font-family: Outfit;
					font-weight: 400;
					line-height: 150%;
					letter-spacing: 0.5px;
					padding: 0px 0px;
					margin-bottom: -0.1rem;
					// padding: 10px 5px 0 5px;
					// background: var(--white-to-dgrey);

					@media (max-width: 768px) {
						height: auto;
						font-size: 1rem; /* Reduce font size for smaller screens */
					}
				}
			}
		}

		.feed_header_container {
			display: flex;
			justify-content: space-between;

			@media (max-width: 378px) {
				padding: 0;

				.feedpostcard_content {
					gap: 0.5rem;

					img {
						width: 40px;
						height: 40px;
					}

					.feedpostcart_text_header {
						margin-left: 5px;

						.info-container {
							.designation-location {
								font-size: 11px;

								span {
									gap: 2px;
								}
							}
						}
					}
				}

				.three_dot {
					padding-right: 0;
				}
			}

			.feedpostcard_content {
				display: flex;
				justify-content: center;
				align-items: center;

				.feedpostcart_text_header {
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-left: 10px;
					.header-container {
						.top-voice-badge {
							position: relative;
							margin-left: 0.125rem;
							// display: flex;
							// align-items: center;
							cursor: pointer;
							top: -1px;

							.top-voice-icon {
								width: 1.2rem;
								height: 1.2rem;
								fill: #c0c0c0;
							}
							.top-voice-text {
								width: 70px;
								position: absolute;
								top: -15px;
								left: 10px;
								color: #fff;
								font-size: 0.8rem;
								font-weight: 500;
								opacity: 0;
								transition: opacity 0.3s ease;
							}

							&:hover .top-voice-text {
								opacity: 1;
							}
						}
					}

					.connect_button_feed {
						font-size: 13px;
						padding: 5px;
						gap: 4px;
						align-items: center;
						cursor: pointer;
						color: #fd5901;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50px;
						transition: background-color 0.3s, color 0.3s;

						&:hover {
							background-color: #fd5901;
							color: white;
							border-color: #fd5901;
						}
					}

					.request_sent_feed {
						font-size: 13px !important;
						padding: 5px;
						gap: 4px;
						align-items: center;
						color: #fd5901 !important;
						display: flex;
						cursor: default;
						justify-content: center;
						align-items: center;
						border-radius: 50px;
						transition: background-color 0.3s, color 0.3s;
					}

					.connect_button_feed span {
						font-size: 14px;
					}

					.info-container {
						margin-top: 0.3rem;
						.designation-location {
							font-weight: 400;
							white-space: nowrap; // Prevent text from wrapping to a new line
							overflow: hidden; // Hide any overflow
							text-overflow: ellipsis; // Show ellipsis if text overflows
						}
					}

					img {
						width: 15.954px;
						height: 26.346px;
						margin-right: 5px;

						@media (max-width: 768px) {
							width: 10.954px; /* Adjust image size for smaller screens */
							height: 18.346px;
						}
					}

					span {
						margin-right: 5px;
					}
				}
			}

			.feedpostcard_footer {
				cursor: pointer;
				img {
					width: 15.954px;
					height: 26.346px;
					margin-right: 5px;

					@media (max-width: 768px) {
						width: 10.954px; /* Adjust image size for smaller screens */
						height: 18.346px;
					}
				}

				.feedpostcard_footer_like_comment {
					span {
						margin-right: 5px;
					}
				}
			}

			.three_dot {
				display: flex;
				justify-content: center;
				align-items: center;

				.kebab_menu_container {
					position: absolute;
					cursor: pointer;

					@media (max-width: 420px) {
						img {
							width: 20px;
							transform: rotate(90deg);
						}
					}

					ul.kebab_menu {
						position: absolute;
						list-style-type: none;
						top: 30px;
						right: 20px;
						height: max-content;
						// background-color: white;
						background-color: var(--white-to-grey);
						@media (max-width: 420px) {
							right: 5px;
						}

						li {
							margin-bottom: 6px;
							color: rgb(92, 92, 92);
							&:hover {
								border-bottom: 1px solid #d1d1d1;
								color: black;
								cursor: pointer;
							}
						}
						li:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.feedpostcard_footer {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.comment_container {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100px;
				}

				.d-flex {
					width: 100%;
					justify-content: space-between !important;
					align-items: center;
					padding: 0 20px;

					// Remove width constraint from existing elements
					div {
						width: auto !important;
					}

					// Adjust comment count style
					.feedpostcard_comment_count_badge {
						position: static !important;
						transform: none !important;
						margin-left: 5px;
						background: transparent;
						color: var(--white-to-grey) !important;
						font-size: 12px;
					}

					// Hide text labels
					.actions-btn-text-hide {
						display: none !important;
					}
				}
			}

			.feedpostcard_footer_like_comment {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}
	}

	.box {
		// background-color: #fff;
		padding: 20px;
		padding-bottom: 0px;
		border-radius: var(--border-radius);
		// background: #fff;
		background-color: var(--white-to-grey);
		box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);

		@media (max-width: 378px) {
			padding: 10px;
			padding-bottom: 0;
			margin: 0;
			border-radius: 0;
			box-shadow: none;
		}
	}
	.comment_container {
		display: flex;
		// justify-content: center;
		align-items: center;
		height: 100px; /* Adjust the height as needed */
		// .logo {
		img {
			width: 45px !important;
			height: 45px;
			border-radius: 50%;
			// margin-right: 10px;
			object-fit: cover;
			border: 1px solid rgba(202, 202, 202, 0.2);
		}
		// }

		.input_and_logo_section {
			display: flex;
			align-items: center;
			padding: 10px;
			padding-right: 0;
			width: 100%;
			gap: 0.5rem;

			.input_box {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 0 10px;
				border-radius: 170px;
				// border: 1px solid #cacaca;
				border: 1px solid rgba(202, 202, 202, 0.2);
				// background: #fcfcfc;
				background-color: var(--bs-light);
				height: 54px;
				transition: border-color 0.3s, box-shadow 0.3s;
				// &:focus {
				// 	box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a box shadow on focus */
				// 	background-color: #fff; /* Optional: Change background color on focus */
				// }
				&:hover {
					border-color: #fd5901; /* Darker border color on hover */
					box-shadow: 0 0 10px rgba(253, 89, 1, 0.5);
				}

				input[type="text"] {
					flex-grow: 1;
					padding: 4px;
					border: none;
					border-radius: 4px;
					outline: none;
					margin-left: 3px;
					// margin-right: -90px;
					width: 100%;
					background-color: var(--bs-light);
					transition: box-shadow 0.3s;
				}

				.icons {
					display: flex;
					margin-left: 10px;
					justify-content: center;
					align-items: center;

					.smiley_icon,
					.image_icon {
						font-size: 20px;
						cursor: pointer;
						margin-right: 5px;
					}
				}

				.comment_icons {
					.image_icon {
						margin-right: 0 !important;
					}

					.smiley_icon {
						margin-left: -15px !important;
					}
				}
			}
			.comment-post-btn {
				border-radius: 30px;
			}
		}
	}

	.single-comment {
		.comment-details {
			background: rgba(52, 55, 68, 0.2);
			color: var(--d-l-grey);
			border-radius: 1.25rem;
			padding: 0.75rem 1rem;
			margin-bottom: 0.5rem;

			h6 {
				color: var(--d-l-grey);
				font-family: 'Outfit', sans-serif;
				font-weight: 600;
				font-size: 0.875rem;
				margin-bottom: 0.25rem;
			}

			.comments-user-name {
				display: flex;
				gap: 0.4rem;
				text-decoration: none;
				
				h6 {
					font-weight: 700; // Make username bolder
					margin: 0;
				}
			}

			.comment {
				font-family: 'Outfit', sans-serif;
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 0.945rem;
				text-align: left;
				margin-top: 0.5rem;
				color: var(--d-l-grey);
			}
		}

		.actions {
			padding: 0.5rem 1rem;

			.like-text {
				font-family: 'Outfit', sans-serif;
				font-size: 0.75rem;
				font-weight: 500;
				color: var(--d-l-grey);
				cursor: pointer;
				transition: color 0.2s ease;
				text-decoration: underline;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;

				&:hover {
					color: #fd5901;
				}

				&.liked {
					color: #fd5901;
					font-weight: 600;
				}
			}
		}

		.img_container {
			max-width: 5rem;
			
			img {
				object-fit: cover;
				padding: 0.125rem;
				height: 3.125rem;
				width: 3.125rem;
				max-width: 3.438rem;
				border: 1px solid rgba(202, 202, 202, 0.2);
				border-radius: 50%;
			}
		}
	}

	.comment_messages {
		border-radius: var(--border-radius);
		background: #ededed;
		.connection_item {
			padding: 10px;
			.connection_left {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 10px;

				.comment_connection {
					width: 60px;
					height: 60px;
					border-radius: 50%;
					margin-left: 20px;

					@media (max-width: 500px) {
						width: 40px;
						height: 40px;
						margin: 0;
					}
				}

				img {
					height: 60px;
					width: 60px;
					border-radius: 50%;
				}

				.body_container {
					.connection_time {
						font-size: 12px;
					}
					.connection_designation {
						color: #484848;
						font-family: Poppins;
						font-size: 13px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}
					.connection_name {
						color: #484848;
						font-family: Poppins;
						font-size: 18px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						text-decoration: none;
					}
				}
			}

			.connection_right {
				display: flex;
				gap: 10px;
				.days_time {
					color: #000;
					font-family: Poppins;
					font-style: normal;
					line-height: normal;
				}
			}
		}
		.comment_text {
			color: #000;
			font-family: Poppins;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			// margin: 0px 20px 20px 20px;
			padding: 0 20px 0px 20px;
		}
		.deleteIcon {
			height: 30px;
			width: 30px;
		}
	}

	.logo img {
		max-width: 50px; /* Adjust the logo size as needed */
		margin-right: 10px; /* Add some spacing between the logo and input box */
	}

	.input_box {
		display: flex;
		align-items: center;
		width: 100%;
	}

	input[type="text"] {
		flex-grow: 1; /* Grow the input box to take up remaining space */
		padding: 5px;
		border: 1px solid #ccc; /* Optional: Add a border to the input box */
		border-radius: 4px; /* Optional: Add border-radius */
	}

	.icons {
		display: flex;
		margin-left: 10px; /* Add some spacing between the input box and icons */
	}

	.smiley_icon,
	.image_icon {
		font-size: 20px; /* Adjust the icon size as needed */
		cursor: pointer; /* Optional: Add cursor pointer for icons */
		margin-right: 5px; /* Optional: Add spacing between icons */
	}
}

#reactionsModalStartup {
	.modal-title {
		color: var(--d-l-grey);
	}
	.modal-dialog {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100% - 1rem);
		margin: 0 auto; // Center horizontally
	}
	
	.modal-content {
		background-color: var(--white-to-grey);
		width: 100%;
		max-width: 500px; // Add max-width for better appearance
		margin: auto; // Add some margin
		
		.modal-body {
			overflow: auto;
			max-height: 70vh;
			color: var(--d-l-grey);
			padding: 1rem;
			
			.user-list {
				img {
					object-fit: cover;
					border-radius: 50%;
					width: 40px;
					height: 40px;
				}
			}
		}

		.reactions_investor {
			.nav-item {
				// Tabs padding
				//   border: 1px solid red;
				cursor: pointer;
				// color: #000000;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-transform: capitalize;

				&.active {
					color: var(--currentTheme-dark);

					// &::before {
					//   content: "";
					//   display: inline-block;
					//   width: 3px;
					//   height: 50%;
					//   margin-right: 2px;
					//   background-color: #fd5901;
					// }
				}
			}
		}
	}
}

#reportPostModal {
	.reasons_container {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;

		.form-check-label {
			border-radius: 130px;
			border: 1px solid grey;
			padding: 5px 10px;
			margin: 0;
		}
	}
}

.image-popup-container {
	width: 50vw;
	position: relative;
	button {
		position: absolute;
	}
	// img {
	//   width: 100%;
	//   object-fit: contain;
	//   height: auto;
	//   max-height: 80vh;
	// }
	@media (max-width: 820px) {
		width: 80vw;
	}
}

.comment-container-wrapper {
	max-height: 0; /* Start with max-height of 0 */
	overflow: hidden; /* Hide overflow */
	transition: max-height 0.4s ease; /* Smooth transition */
}

.comment-container-wrapper.show {
	max-height: 500px; /* Set a maximum height when shown */
}

.image-scroll-container {
	display: flex;
	overflow-x: auto; // Enable horizontal scrolling
	scroll-snap-type: x mandatory; // Enable snap scrolling
	-webkit-overflow-scrolling: touch; // Smooth scrolling on iOS
	padding: 10px; // Add some padding around the container

	// Hide scrollbar for a cleaner look
	&::-webkit-scrollbar {
		display: none; // Hide scrollbar in WebKit browsers
	}

	// Optional: Add some styles for the scrollbar in other browsers
	scrollbar-width: none; // Hide scrollbar in Firefox
	-ms-overflow-style: none; // Hide scrollbar in IE and Edge
}

.image-item {
	flex: none; // Prevent flex items from stretching
	width: 100%; // Set width to 100% of the container
	max-height: 30rem; // Set maximum height
	object-fit: cover; // Maintain aspect ratio and cover the area
	scroll-snap-align: start; // Align images to the start on scroll
	margin-right: 10px; // Add some space between images

	// Optional: Add a border radius for rounded corners
	border-radius: 10px;
}

.poll-section {
	padding: 15px 0;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.poll-option {
		display: flex;
		align-items: center;
		gap: 10px;
		width: 100%;

		.poll-option-content {
			flex: 1;
			background: var(--bs-light);
			padding: 10px 15px;
			border-radius: 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			z-index: 1;

			.progress-bar {
				z-index: 0;
			}

			.option-text,
			.vote-count {
				position: relative;
				z-index: 2;
				color: var(--d-l-grey);
				font-size: 14px;
			}

			&[style*="opacity: 0.6"] {
				cursor: not-allowed;

				.option-text,
				.vote-count {
					opacity: 0.8;
				}
			}
		}

		.vote-button {
			padding: 8px 16px;
			border-radius: 20px;
			border: 1px solid var(--d-l-grey);
			background: transparent;
			color: var(--d-l-grey);
			font-size: 14px;
			transition: all 0.3s ease;
			min-width: 80px;
			white-space: nowrap;

			&:hover:not(:disabled) {
				background: var(--bs-light);
			}

			&:disabled {
				opacity: 0.6;
				cursor: not-allowed;
				background: var(--bs-light);
				border-color: var(--bs-light);
				color: var(--d-l-grey);
			}

			&.votedButton {
				background: #fd5901 !important;
				color: white !important;
				border-color: #fd5901 !important;
				opacity: 1 !important;
				cursor: pointer !important;
			}
		}
	}
}

.poll-section-investor {
	padding: 15px 0;
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.poll-option {
		display: flex;
		align-items: center;
		gap: 10px;
		width: 100%;

		.poll-option-content {
			flex: 1;
			background: var(--bs-light);
			padding: 10px 15px;
			border-radius: 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			z-index: 1;

			.progress-bar {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background-color: rgba(
					211,
					243,
					107,
					0.3
				) !important; // Investor theme color with opacity
				border-radius: 8px;
				transition: width 0.3s ease;
				z-index: 0;
			}

			.option-text,
			.vote-count {
				position: relative;
				z-index: 2;
				color: var(--d-l-grey);
				font-size: 14px;
			}

			&[style*="opacity: 0.6"] {
				cursor: not-allowed;

				.option-text,
				.vote-count {
					opacity: 0.8;
				}
			}
		}

		.vote-button {
			padding: 8px 16px;
			border-radius: 20px;
			border: 1px solid var(--d-l-grey);
			background: transparent;
			color: var(--d-l-grey);
			font-size: 14px;
			transition: all 0.3s ease;
			min-width: 80px;
			white-space: nowrap;

			&:hover:not(:disabled) {
				background: var(--bs-light);
			}

			&:disabled {
				opacity: 0.6;
				cursor: not-allowed;
				background: var(--bs-light);
				border-color: var(--bs-light);
				color: var(--d-l-grey);
			}

			&.votedButton {
				background: #d3f36b !important;
				color: #1d1d1d !important;
				border-color: #d3f36b !important;
				opacity: 1 !important;
				cursor: pointer !important;
			}
		}
	}
}

.read-more-text {
	color: grey;
	cursor: pointer;
	text-decoration: underline;
	font-weight: bold;
	font-style: italic;
	font-size: 13.6px;
}

.read-more-text:hover {
	color: #0073b1;
}

.actions-btn-text-hide {
	@media (max-width: 480px) {
		display: none;
	}
}

.feedpostcard_comment_count_badge {
	color: var(--d-l-grey) !important;
	right: -30px;
	top: -5px;

	// @media (max-width: 480px) {
	// 	right: 0px;
	// }
}


.community-color {
	background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%) !important;
	color: white;
}

// Remove the top circle badge
.position-absolute.translate-middle.badge {
	display: none;
}

.community-page {
	@media (max-width: 378px) {
		.community-content {
			padding: 0;
			margin: 0;
			width: 100%;
			
			.main-content {
				margin: 0;
				padding: 0;
				width: 115vw;
				overflow-x: hidden;
			}
		}
	}
}