.MarketCard {
  flex: 0 0 100%;
  margin-inline: auto;
  background-color: black;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  gap: 10px;
  border-radius: var(--border-radius);
  align-items: center;
  // color: white;

  @media (min-width: 768px) {
    flex: 0 0 28rem;
  }
  .titles {
    display: flex;
    gap: 5px;
  }
  h3 {
    font-size: 20px;
  }
  h3:nth-child(1) {
    color: white;
  }
  .amount {
    display: flex;
    background-color: white;
    padding: 10px 15px;
    border-radius: 15px;
    input {
      border: none;
      outline: none;
    }
  }
}

@media (max-width: 425px) {
  .MarketCard {
    width: 300px;
    .titles {
      flex-direction: column;
      align-items: center;
    }
    h3 {
      font-size: 15px;
    }
    .amount {
      padding: 10px 5px;
      input {
        font-size: 13px;
        width: 200px;
      }
    }
  }
}
