.linkedin-login-container {
	margin-top: 0.3rem;
	max-width: 100px;
	box-sizing: border-box;
}

.linkedin-button {
	/* width: 100%; */
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	padding: 0.25rem 0.25rem;
	background-color: #0077b5;
	color: white;
	border: none;
	border-radius: 0.375rem;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
	position: relative;
}

.linkedin-button svg {
	margin: 0;
	padding: 0;
}

.linkedin-button .button-content {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.linkedin-button:hover {
	background-color: #005885;
}

.linkedin-button:disabled {
	background-color: #99c5db;
	cursor: not-allowed;
}

.linkedin-button .spinner {
	width: 1rem;
	height: 1rem;
	border: 2px solid #ffffff;
	border-top-color: transparent;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@keyframes spin {
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

/* @media (max-width: 460px) {
	.linkedin-button {
		font-size: 0.75rem;
		padding: 0.625rem 0.75rem;
	}

	.linkedin-button svg {
		width: 1rem;
		height: 1rem;
	}
} */
