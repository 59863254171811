.chat_navbar_container {
	border-radius: var(--border-radius);
	// border: 1px solid #dcdcdc;
	background: transparent;
	box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.05);
	padding: 10px 30px;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// width: 95%;

	@media (max-width: 820px) {
		border-radius: 0px;
		padding: 10px;
	}

	.left {
		display: flex;
		align-items: center;

		.rounded_img {
			border-radius: 50%;
			height: 40px;
			width: 40px;
		}

		.title_and_message {
			margin-left: 10px;
			.name_title {
				color: var(--text-color);
				font-family: Poppins;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.online {
				color: #a3ffa3;
				font-family: Poppins;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			.message_title {
				color: #858585;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			@media (max-width: 820px) {
				.name_title {
					font-size: 18px;
				}
				.online {
					font-size: 12px;
				}
				.message_title {
					font-size: 12px;
				}
			}
		}
	}

	.right {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		.call,
		.video {
			margin-right: 20px;
			height: 25px;
			width: 25px;
		}
	}
}
ul.kebab_menu {
	position: absolute;
	list-style-type: none;
	bottom: -50px;
	right: 50px;
	height: max-content;
	// background-color: #a7a7a7;
	z-index: 3;

	background-color: white;

	li {
		margin-bottom: 6px;
		color: rgb(92, 92, 92);
		font-size: 14px;
		&:hover {
			border-bottom: 1px solid #d1d1d1;
			color: black;
			cursor: pointer;
		}
	}
	li:last-child {
		margin-bottom: 0;
	}
}
