.newInvestor_sidebar_container {
	width: 100%;
	max-width: 10%;
	margin: 0;
	padding: 0;
}

.sidebar-main {
	width: 20%;
	height: 100vh;
	background-color: #ffffff;
	position: absolute;
	border-radius: 8px 8px 8px 0px;
	border: 2px solid red;
}

.pro-sidebar .pro-menu a {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: var(--d-l-grey);
}

.hr-above-support {
	color: var(--d-l-grey);
	border: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-item-content {
	display: contents;
	justify-content: center;
	align-items: center;
}
.pro-item-content a img {
	height: 20px;
}
.pro-item-content span {
	margin-left: 24px;
	font-weight: 400;
}

#header {
	position: relative;
	width: 220px;
	// margin: 10px 92px;
	overflow: hidden;

&::-webkit-scrollbar {
    display: none; // For Webkit browsers
  }

}

// #header .pro-sidebar {
//   /* height: 100vh; */
// }

.closemenu {
	position: absolute;
	right: 0px;
	z-index: 11111;
	top: 4.7rem;
}
.closemenu-Left {
	margin-top: 4.3rem;
}

#header .pro-sidebar {
	width: 90%;
	min-width: 90%;
}

#header .pro-sidebar.collapsed {
	width: 65px;
	min-width: 40px;
}

#header .pro-sidebar-inner {
	background-color: var(--white-to-grey);
	box-shadow: 0.5px 0.866px 2px 0px rgba(246, 236, 236, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
	overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
	font-size: 20px;
	padding: 0 20px;
	color: var(--d-l-grey);
	font-weight: bold;
}

.logotext {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 20px;
	padding-bottom: 10px;
}
.logotext h3 {
	height: 23px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	margin-top: 5px;
	/* identical to box height */

	color: var(--d-l-grey);
}

.logotext h4 {
	margin: 0;
	font-size: 12px;
	margin-bottom: 20px;
}
.create_post_newInvestor {
	border: none;
	background: #d3f36b;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5em;
	padding: 0.5em 1.5em;
	box-shadow: 0 4px 10px 0 rgba(63, 60, 255, 0.2);
}
.create_post img {
	height: 15px !important;
	margin-left: 5px;
}
.plus_btn_newInvestor {
	width: 40px;
	height: 40px;
	left: 90px;
	top: 329px;
	border: none;
	background: #d3f36b;
	border-radius: 10px;
	margin-left: -7px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
	/* padding: 0 5px; */
	margin: auto;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
	color: var(--d-l-grey);
	margin: 10px 0px;
	font-weight: bold;
	justify-content: flex-start;
}

#header
	.pro-sidebar-inner
	.pro-sidebar-layout
	ul
	.pro-inner-item
	.pro-icon-wrapper {
	background-color: #fbf4cd;
	color: var(--d-l-grey);
	border-radius: 3px;
}

#header
	.pro-sidebar-inner
	.pro-sidebar-layout
	ul
	.pro-inner-item
	.pro-icon-wrapper
	.pro-item-content {
	color: var(--d-l-grey);
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
	background-image: linear-gradient(0deg, #eef2f3 0%, #eef2f3 100%);
	* {
		color: var(--currentTheme-dark) !important;
	}
}

#header .pro-sidebar-inner .pro-sidebar-layout .active-item {
	/* height: 48px; */
	/* width: 48px; */
	/* border-radius: 50%; */
	/* display: flex;
      justify-content: center;
      align-items: center; */
	padding: 1px;
}

#header .logo {
	padding: 20px;
}

// CSS for communities in investor sidebar

// CSS for Community in sidebar
.newInvestor_sidebar_container {
	.sidebar__community {
		details {
			font-size: inherit;
			font-weight: 400;
			color: var(--d-l-grey);
			position: relative;

			summary {
				list-style-type: none;
				font-size: inherit;
				color: inherit;
				transition: margin 0.15s ease-out;
			}

			&[open] summary {
				margin-bottom: 10px;
				color: var(--currentTheme-dark);

				// &:hover {
				//   background-color: #d3f36b;
				// }
			}

			.sidebar__community__btn {
				outline: none;
				border: none;
				background-color: transparent;
				padding-block: 0.25em;
				padding-inline: 0;
				color: var(--currentTheme-dark);
				font-size: 0.85rem;
				font-weight: 500;
				text-align: left;

				// &:hover {
				//   background-color: #d3f36b;
				// }
			}
		}

		// For responsiveness of Modal component
		// .modal {
		//   margin-top: 20%;

		//   @media (min-width: 500px) {
		//     margin-top: 15%;
		//   }

		//   @media (min-width: 575px) {
		//     margin-top: 13.5%;
		//   }

		//   @media (min-width: 768px) {
		//     margin-top: 10%;
		//   }

		//   @media (min-width: 769px) {
		//     margin-top: 0;
		//   }
		// }
	}
}
@media (min-width: 820px) {
	.mobile-onboarding {
		display: none !important;
	}
}

@media (max-width: 820px) {
	.mobile-onboarding {
		margin-left: 1rem;
	}
}

.pro-sidebar .pro-menu {
  
  .pro-menu-item {
    &:focus {
      outline: none;
    }
    
   
    a:focus {
      outline: none;
    }
  }
}


#header {
  .pro-sidebar-inner {
    .pro-sidebar-layout {
      button:focus,
      a:focus,
      div:focus {
        outline: none;
      }
    }
  }
}
