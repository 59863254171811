.not-found-container {
  background: #eaeef2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  width: 100vw;
  height: 100vh;

  padding: 20px;

  text-align: center;

  img {
    width: auto;
    max-height: 60vh;
    object-fit: contain;

    @media (max-width: 820px) {
      width: 100vw;
      height: auto;
    }
  }

  button {
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 26.823px;
    background: #fd5901;
    width: 116.467px;
    height: 40.799px;
    flex-shrink: 0;
  }
}
