// Offcanvas css
.offcanvas.attachment-preview {
  --bs-offcanvas-width: 100%;
  // --bs-offcanvas-height: 30vh;

  @media (min-width: 821px) {
    --bs-offcanvas-width: 400px;
  }

  .offcanvas-body {
    .image-preview,
    .video-preview {
      position: relative;
      // display: inline-block;
      // margin-right: 10px;
      max-width: 100%;
      max-height: 60dvh;

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // max-width: 200px;
        // max-height: 150px;
      }

      .remove-preview {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;

        width: 25px;
        height: 25px;
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .document-preview {
      position: relative;
      max-width: 100%;
      max-height: 60dvh;
      // color: #fafafa;
      .remove-preview {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;

        width: 25px;
        height: 25px;
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .preview {
        background-color: rgba(var(--bs-secondary-rgb), 1);
        height: 350px;

        .react-pdf__Page {
          width: fit-content;
          margin-inline: auto;
        }
      }
    }

    .send-container {
      background-color: #eaeef2;
      border-radius: var(--border-radius);
      .text-input {
        border: none;
        outline: none;
        font-size: 14px;
        padding: 8px;
        border-radius: 6px;
        background-color: #eaeef2;

        &::placeholder {
          color: #555 !important;
        }
      }

      .send-btn {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        color: #989898;
        border: 0 !important;
        &:hover {
          color: var(--currentTheme);
        }
        &:active {
          box-shadow: none;
          transform: scale(1.1);
        }
      }
    }
  }
}
