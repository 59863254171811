.community-page {
	min-height: 100vh;
	background: var(--background-color);
	color: var(--text-color);
	max-width: 100dvw;
	overflow-x: hidden;

	.community-header {
		position: fixed;
		width: 100%;
		background: var(--white-to-grey);
		padding: 0.4rem 0;
		border-bottom: 1px solid var(--border-color);
		z-index: 1000;

		.community-info {
			// max-width: 80rem;
			// margin: 0 auto;
			padding: 0 2rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1rem;
			justify-content: space-between;

			.info-wrapper {
				display: flex;
				align-items: center;
				gap: 1rem;
			}

			.right-elements {
				display: flex;
				align-items: center;
				gap: 1rem;
			}

			img {
				width: 3rem;
				height: 3rem;
				border-radius: 0.5rem;
				object-fit: cover;
			}

			.info-text {
				h1 {
					font-size: 1.2rem;
					margin-bottom: 0.25rem;
					color: var(--text-color);
				}

				p {
					display: none;
				}

				.stats {
					display: flex;
					gap: 1rem;
					font-size: 0.8rem;
					color: var(--d-l-grey);
				}
			}
		}
	}

	.community-content {
		max-width: 80rem;
		margin: 0 auto;
		padding: 2rem;
		padding-top: 1rem;
		grid-template-columns: 15rem 1fr;
		gap: 2rem;

		.sidebar {
			position: fixed;
			width: 10rem;
			left: 0rem;
			top: 10rem;

			nav {
				background: var(--white-to-grey);
				// border-radius: 0.5rem;
				padding: 0.5rem;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;

				button {
					padding: 0.75rem 1rem;
					border: none;
					background: transparent;
					color: var(--text-color);
					border-radius: 0.5rem;
					text-align: left;
					transition: all 0.2s;
					font-size: 0.9rem;

					&:hover {
						background: var(--bs-light);
					}

					&.active {
						background: linear-gradient(
							90deg,
							#fd5901 0%,
							#9306ff 100%
						) !important;
						color: white !important;
					}
				}
			}

			&.closed {
				display: none;
			}
		}

		.main-content {
			//margin-left: 7rem;
			margin-top: 5rem;
			.create-post {
				padding: 1rem;
				border-radius: 0.5rem;
				display: flex;
				align-items: center;
				gap: 1rem;
				margin-bottom: 1rem;
				margin-left: 1rem;
				position: relative;
				@media (max-width: 400px) {
					padding: 0.5rem;
					margin-left: 0;
					margin-right: 0;
					gap: 0rem;
				}

				img {
					width: 3rem;
					height: 3rem;
					border-radius: 50%;
					object-fit: cover;
				}

				input {
					flex: 1;
					background: var(--background-color);
					border: none;
					padding: 0.75rem 1rem;
					border-radius: 1.5rem;
					color: var(--text-color);
					height: 54px;
					cursor: pointer;

					&::placeholder {
						color: var(--d-l-grey);
					}

					&:hover {
						border-color: #fd5901;
						box-shadow: 0 0 10px rgba(253, 89, 1, 0.5);
					}

					@media (max-width: 378px) {
						padding: 0.5rem;
						font-size: 0.9rem;
						height: 45px;
					}
				}

				.post-btn {
					background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%);
					color: white;
					border: none;
					padding: 0.5rem 1.5rem;
					border-radius: 1.5rem;
					cursor: pointer;

					&:hover {
						opacity: 0.9;
					}
				}

				.filter-button {
					background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%);
					border: none;
					border-radius: 8px;
					cursor: pointer;
					color: white;
					padding: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: opacity 0.2s ease;

					&:hover {
						opacity: 0.9;
					}

					@media (max-width: 378px) {
						padding: 6px;
						min-width: 32px;
						height: 32px;
					}
				}

				.filter-dropdown {
					position: absolute;
					top: 70%;
					right: 0;
					background: var(--white-to-grey);
					border: 1px solid var(--border-color);
					border-radius: 8px;
					box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
					z-index: 1000;
					min-width: 150px;
					margin-top: 5px;

					.filter-option {
						padding: 10px 15px;
						cursor: pointer;
						transition: background-color 0.2s;
						color: var(--text-color);

						&:hover {
							background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%);
						}

						&:first-child {
							border-top-left-radius: 8px;
							border-top-right-radius: 8px;
						}

						&:last-child {
							border-bottom-left-radius: 8px;
							border-bottom-right-radius: 8px;
						}
					}
				}
			}

			.posts-container {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				padding-bottom: 4rem;
			}
		}
	}
}

@media (max-width: 768px) {
	.community-page {
		.community-content {
			grid-template-columns: 1fr;
			padding: 1rem;

			.sidebar {
				position: static;
				margin-top: 3.5rem;
				margin-left: -1rem;
				width: 100vw;
			}

			.main-content {
				margin-left: 0;
			}
		}

		.community-header {
			.community-info {
				.info-wrapper {
					.hamburger-btn {
						display: block;
					}
				}
			}
		}

		.community-content {
			.sidebar {
				&.open {
					display: block;
				}
				&.closed {
					display: none;
				}
			}
		}
	}
}

.horizontal-menu {
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
	margin: 1rem 0;
	flex-wrap: wrap;
	margin-left: 2rem;

	button {
		background: none;
		border: none;
		padding: 0.5rem 1rem;
		color: var(--text-color);
		cursor: pointer;
		font-size: 1rem;
		position: relative;

		&.active {
			background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%) !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
			font-weight: 600;

			&:after {
				content: "";
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 100%;
				height: 2px;
				background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%) !important;
			}
		}

		&:hover {
			background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%) !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
		}
	}
}

@media (max-width: 768px) {
	.horizontal-menu {
		gap: 0.5rem;

		button {
			padding: 0.5rem;
			font-size: 0.9rem;
		}
	}
}

.whatsapp-banner {
	background: linear-gradient(135deg, #075e54 0%, #128c7e 100%);
	padding: 1rem 1.5rem;
	margin: 1rem;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
	position: relative;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(
			45deg,
			rgba(255, 255, 255, 0.1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
		pointer-events: none;
	}

	.banner-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
		cursor: pointer;
		margin-right: 1rem;

		.banner-text {
			display: flex;
			align-items: center;
			gap: 1rem;
			color: white;

			.whatsapp-icon {
				width: 2.5rem;
				height: 2.5rem;
				filter: brightness(0) invert(1);
			}

			.banner-message {
				display: flex;
				flex-direction: column;

				.title {
					font-weight: 600;
					font-size: 1.1rem;
				}

				.subtitle {
					font-size: 0.85rem;
					opacity: 0.9;
				}
			}
		}

		.join-btn {
			background: white;
			color: #075e54;
			border: none;
			padding: 0.6rem 1.5rem;
			border-radius: 50px;
			font-weight: 600;
			font-size: 0.9rem;
			transition: all 0.2s ease;
			min-width: 100px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover:not(:disabled) {
				transform: translateY(-2px);
				box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
			}

			&:disabled {
				opacity: 0.8;
				cursor: not-allowed;
			}
		}
	}

	.close-btn {
		background: rgba(255, 255, 255, 0.2);
		border: none;
		color: white;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: all 0.2s ease;
		font-size: 1.2rem;
		padding: 0;
		line-height: 1;

		&:hover {
			background: rgba(255, 255, 255, 0.3);
			transform: rotate(90deg);
		}
	}

	@media (max-width: 378px) {
		padding: 0.5rem;
		margin: 0.5rem;

		.banner-content {
			.banner-text {
				gap: 0.5rem;

				.whatsapp-icon {
					width: 2rem;
					height: 2rem;
				}

				.banner-message {
					.title {
						font-size: 0.9rem;
					}
				}
			}

			.join-btn {
				padding: 0.4rem 1rem;
				font-size: 0.8rem;
				min-width: 80px;
				height: 32px;
			}
		}

		.close-btn {
			position: absolute;
			top: 0.25rem;
			right: 0.25rem;
			width: 20px;
			height: 20px;
			font-size: 1rem;
		}
	}
}

@media (max-width: 576px) {
	.whatsapp-banner {
		padding: 0.75rem 1rem;

		.banner-content {
			.banner-text {
				.banner-message {
					.subtitle {
						display: none;
					}
				}
			}
		}
	}
}

.mobile-sidebar {
	position: fixed;
	top: 60px;
	left: 0;
	width: 280px;
	height: calc(100vh - 80px);
	background: var(--white-to-grey);
	z-index: 999;
	box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease;
	overflow-y: auto;

	.sidebar-header {
		padding: 1.5rem;
		border-bottom: 1px solid var(--border-color);

		.profile-section {
			display: flex;
			align-items: center;
			gap: 1rem;
			text-decoration: none;

			.profile-pic {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				object-fit: cover;
			}

			.profile-info {
				h3 {
					margin: 0;
					font-size: 1rem;
					color: var(--text-color);
				}

				p {
					margin: 0;
					font-size: 0.8rem;
					color: var(--d-l-grey);
				}
			}
		}
	}

	.sidebar-onboarding {
		padding: 0.5rem 1.5rem;
		border-bottom: 1px solid var(--border-color);

		// Ensure the switch is visible against the sidebar background
		:global(.form-check-input) {
			background-color: var(--background-color);
		}
	}

	.sidebar-menu {
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		button {
			width: 100%;
			text-align: left;
			padding: 0.75rem 1rem;
			border: none;
			background: transparent;
			color: var(--text-color);
			border-radius: 0.5rem;
			transition: all 0.2s;

			&:hover {
				background: var(--bs-light);
			}

			&.active {
				background: linear-gradient(90deg, #fd5901 0%, #9306ff 100%);
				color: white;
			}
		}
	}
}

.profile-link {
	margin-left: auto; // Push to right end
	display: flex;
	align-items: center;
	text-decoration: none;

	.profile-pic {
		width: 35px;
		height: 35px;
		border-radius: 50% !important;
		object-fit: cover;
		border: 2px solid transparent;
		transition: border-color 0.2s ease;

		&:hover {
			border-color: var(--currentTheme);
		}
	}
}

.logo-link {
	display: block;
	margin-bottom: 1rem;
	padding: 0.5rem 0;

	img {
		height: 2rem;
		width: auto;
		border-radius: 0;
	}
}

.desktop-logo-link {
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	z-index: 100;
	transition: transform 0.2s ease;

	img {
		height: 2.5rem;
		width: auto;
		filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
	}

	&:hover {
		transform: translateY(-2px);
	}
}

@media (max-width: 768px) {
	.desktop-logo-link {
		display: none;
	}
}

.title-with-share {
	display: flex;
	align-items: center;
	gap: 1rem;

	h1 {
		margin: 0;
	}

	.share-icon {
		cursor: pointer;
		font-size: 1.2rem;
		color: var(--d-l-grey);
		transition: color 0.2s ease;

		&:hover {
			color: var(--primary);
		}
	}
}

@media (max-width: 378px) {
	.community-page {
		.community-content {
			padding: 0.5rem;
			max-width: 100%;
			overflow-x: hidden;
		}

		.community-header {
			.community-info {
				padding: 0 1rem;

				img {
					width: 2.5rem;
					height: 2.5rem;
				}

				.info-text {
					h1 {
						font-size: 1rem;
					}

					.stats {
						font-size: 0.7rem;
					}
				}
			}
		}
	}
}
