:root {
	--bg-color: #f5f5f5;
	--text-color: #333333;
	--text-color-secondary: #848587;
	--accent-color: #fd5901;
	--background-color: #f5f5f5;
	--background-color-secondary: #37344433;
	--background-btn: rgba(52, 55, 68, 0.2);
}

.dark-theme {
	--bg-color: #1e1e1e;
	--text-color: #ffffff;
	--text-color-secondary: #9ca3af;
	--accent-color: #fd5901;
	--background-color: #1e1e1e;
	--background-color-secondary: rgba(6, 6, 6, 0.2);
	--background-btn: rgb(30, 30, 30);
}

.public-sidebar {
	// position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	max-height: calc(100dvh - 80px);
	width: 60px;
	background-color: var(--background-color);
	color: var(--text-color);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	transition: width 0.3s ease, opacity 0.5s ease, transform 0.5s ease;
	z-index: 100;
	opacity: 1;
	transform: translateX(0);
	overflow: hidden;
}

.public-sidebar-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	opacity: 1;
	transition: opacity 0.5s ease;
	color: var(--text-color);
}

.public-sidebar-item {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: flex-start;
	color: var(--text-color);
	padding: 7px 20px;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.3s ease, width 0.3s ease;
	position: relative;
	width: 100%;

	.public-sidebar-icon {
		width: 25px;
		height: 25px;
	}

	svg {
		fill: var(--text-color);
		transition: fill 0.3s ease;
	}
}

.public-sidebar-label {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 100%;
	white-space: nowrap;
	background-color: var(--background-color);
	padding: 5px 10px;
	border-radius: 4px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	transition: opacity 0.3s ease, visibility 0.3s ease;
	z-index: 10;
}

.public-sidebar-item:hover .public-sidebar-label {
	opacity: 1;
	visibility: visible;
}

.public-sidebar-item:hover {
	transform: translateX(5px);
	color: var(--accent-color);

	svg {
		fill: var(--accent-color);
	}
}

// Media query for mobile devices
@media (max-width: 480px) {
	.public-sidebar.collapsed {
		width: 0;
		opacity: 0;
		transform: translateX(-100%);
		transition: width 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
		overflow: hidden;

		.public-sidebar-content {
			opacity: 0;
		}

		.public-sidebar-label {
			display: none;
		}
	}

	.public-sidebar.expended {
		position: absolute;
		top: 80px;
		left: 0;
		width: 210px;
		z-index: 1000;

		.public-sidebar-label {
			position: static;
			opacity: 1;
			visibility: visible;
			background: none;
			box-shadow: none;
			padding: 0 10px;
		}
	}
}

// Ensure sidebar remains visible on larger screens
@media (min-width: 481px) {
	.public-sidebar {
		width: 60px;
	}

	.public-sidebar.expended {
		width: 250px;

		.public-sidebar-label {
			position: static;
			opacity: 1;
			visibility: visible;
			background: none;
			box-shadow: none;
			padding: 0 10px;
		}
	}
}
