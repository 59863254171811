/* resetpasswordpopup.scss */
.save_post_popup {
	.popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 9999;
	}

	.popup-content {
		// background-color: var(--white-to-grey);
		background: var(--white-to-grey);
		color: var(--d-l-grey);
		padding: 40px;
		text-align: center;
		border-radius: var(--border-radius);
		position: relative;
		width: 40%;

		h1 {
			color: #000;
			font-family: Poppins;
			font-size: 34px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		p {
			color: #373737;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		/* Center the buttons */
		button {
			margin: 10px;
		}

		.collection_selecter {
			background: var(--white-to-grey);
			color: var(--d-l-grey);
			padding: 5px;
			border-radius: 4px;
			width: 100%;
		}

		/* Style the close button */
		.close-button {
			position: absolute;
			top: 10px;
			right: 10px;
			background: none;
			border: none;
			font-size: 18px;
			cursor: pointer;
			color: var(--grey-to-white);
		}

		.ok_button {
			border-radius: 10px;
			background: #fd5901;
			color: #fff;
			text-align: center;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border: none;
		}

		h6 {
			font-family: Poppins;
			font-size: 17px;
			font-style: normal;
			font-weight: 100;
			line-height: normal;
			color: #fd5901;
		}
	}
}

@media (max-width: 768px) {
	.save_post_popup {
		.popup-content {
			// background-color: #fff;
			background: var(--white-to-grey);
			color: var(--d-l-grey);
			padding: 40px;
			text-align: center;
			border-radius: var(--border-radius);
			position: relative;
			width: 80%;

			h1 {
				color: #000;
				// color: var(--d-l-grey);
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			p {
				color: #373737;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		button {
			margin: 10px;
		}

		/* Style the close button */
		.close-button {
			width: 20px;
			position: absolute;
			top: 0px;
			right: 10px;
			background: none;
			border: none;
			font-size: 14px;
			cursor: pointer;
		}

		.ok_button {
			flex-shrink: 0;
			border-radius: 10px;
			background: #fd5901;
			color: #fff;
			text-align: center;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border: none;
		}
		h6 {
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			color: #fd5901;
		}
	}
}
.community-color {
	background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%) !important;
	color: white;
}