.whatsapp-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .whatsapp-popup {
    background: var(--white-to-grey);
    padding: 2rem;
    border-radius: 1rem;
    width: 90%;
    max-width: 400px;
    position: relative;

    .close-btn-wa {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--text-color);

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    h2 {
      margin-bottom: 1rem;
      color: var(--text-color);
    }

    p {
      margin-bottom: 1.5rem;
      color: var(--d-l-grey);
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input {
        padding: 0.75rem;
        border: 1px solid var(--border-color);
        border-radius: 0.5rem;
        background: var(--background-color);
        color: var(--text-color);

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }

      .error {
        color: #ff4444;
        font-size: 0.875rem;
        margin-top: -0.5rem;
      }

      button {
        padding: 0.75rem;
        border: none;
        border-radius: 0.5rem;
        background: linear-gradient(90deg, #FD5901 0%, #9306FF 100%);
        color: white;
        cursor: pointer;
        font-weight: 500;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .spinner-border {
          width: 1rem;
          height: 1rem;
          border-width: 0.15em;
        }
      }
    }
  }
} 