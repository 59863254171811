/* resetpasswordpopup.scss */
.reset_password_popup {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
  }

  .popup-content {
    background-color: #fff;
    padding: 40px;
    text-align: center;
    border-radius: var(--border-radius);
    position: relative;
    width: 40%;

    h1 {
      color: #000;
      font-family: Poppins;
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: #373737;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    /* Center the buttons */
    button {
      margin: 10px;
    }

    /* Style the close button */
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
    }

    .reset_button {
      width: 50%;
      height: 50px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #fd5901;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
    }

    .password_input {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      position: relative;

      input {
        display: flex;
        width: 437px;
        height: 54px;
        padding: 18px 17px;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 2px solid #000;
      }

      .eye-button {
        position: absolute;
        right: 30px;
        background-color: transparent;
        border: none;
        svg {
          height: 30px;
          width: 24px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .reset_password_popup {
    .popup-content {
      background-color: #fff;
      padding: 40px;
      text-align: center;
      border-radius: var(--border-radius);
      position: relative;
      width: 80%;

      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #373737;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    button {
      margin: 10px;
    }

    /* Style the close button */
    .close-button {
      width: 20px;
      position: absolute;
      top: 0px;
      right: 10px;
      background: none;
      border: none;
      font-size: 14px;
      cursor: pointer;
    }

    .reset_button {
      width: 40%;
      height: 30px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #fd5901;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
    }
  }
}
