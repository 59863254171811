.investorPopUp {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // height: 100%;
    // width: 100%;
    position: fixed;
    .blackbg {
        // position: absolute;
        background: #000;
        height: 100vh;
        width: 100%;
        opacity: 0.2;
        z-index: -10;
        top: 0;
    }
    .containerTwo {
        display: flex;
        align-items: center;
        justify-content: center;
        .wrapperTwo {
            background-color: #fff;
            border-radius: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            padding: 20px 60px;
            position: absolute;
            top: 0;
            display: flex;
            flex-direction: column;
            // gap: 30px;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            height: 20%;
            width: 40%;
            margin: auto;
            span {
                font-size: 20px;
            }
            img {
                height: 50px;
            }
        }
    }
}

@media (max-width: 768px) {
    .investorPopUp {
        .containerTwo {
            .wrapperTwo {
                width: 400px;
            }
        }
    }
}

@media (max-width: 426px) {
    .investorPopUp {
        .containerTwo {
            .wrapperTwo {
                width: 300px;
                height: 200px;
            }
        }
    }
}
