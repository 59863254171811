.startup_navbar_container {
	display: flex;
	justify-content: space-between;
	height: 80px;
	left: 60px;
	top: 39px;
	background: var(--white-to-grey);
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 1rem;
	z-index: 100;

	// @media (min-width: 768px) {
	// 	border-radius: 0px 0px 20px 20px;
	// }

	.startup_navbar_left_container {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		justify-content: center;
		align-items: center;

		.hamburger {
			display: none;
			@media (min-width: 821px) {
				display: inline;
			}
		}

		.logo_container {
			width: auto;

			img {
				width: 50px;
				height: 50px;
				&:hover {
					cursor: pointer;
				}
			}
		}

		.startup_navbar_search_container {
			.searchbar-container {
				display: flex;
				align-items: center;
				position: relative;
				margin-right: 10px;

				.searchbar-input {
					background: var(--bs-light);
					border-radius: 7px;
					border: none;
					transition: width 0.4s ease;
					padding: 5px 15px;
					padding-left: 12%;
					height: 45px;
					outline: none;
					width: 400px;
				}

				.searchbar-button {
					position: absolute;
					left: 1px;
					top: 0;
					width: 48px;
					height: 45px;
					padding: 4.5px 0;
					background: var(--bs-light);
					border: none;
					border-top-right-radius: 7px;
					border-bottom-right-radius: 7px;
					border-radius: 7px;
					cursor: pointer;
				}
			}

			.search_results {
				display: flex;
				width: 100%;
				flex-direction: column;
				gap: 5px;
				background-color: var(--bs-light);

				.single_result:nth-child(6),
				.single_result:nth-child(13) {
					border-bottom: none;
				}

				span {
					color: var(--darkMode-currentTheme);
				}

				a {
					text-decoration: none;
					color: var(--d-l-grey);
					&:hover {
						color: #fd5901;
					}
				}

				.single_result {
					border-bottom: 1px solid lightgrey;
					padding: 2px 0;
					transition: padding-left ease 0.2s;
					&:hover {
						border-radius: 5px;
						padding-left: 10px;
					}
				}
			}
		}

		.mobile-home-hamberger {
			display: none;
		}
	}

	.startup_navbar_right_container {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.icons-container {
			display: flex;
			align-items: center;

			@media (min-width: 821px) {
				gap: 15px;
				margin: 0 15px;
			}

			.mobile-icon-wrapper-search {
				display: none;

				@media (min-width: 768px) {
					background: #eaeef2;
				}
			}

			.icon-wrapper {
				width: 49px;
				height: 49px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: var(--bs-dark);
				position: relative;

				&:hover {
					cursor: pointer;
					background-color: transparent;
				}

				&:active {
					box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px inset;
				}

				// @media (min-width: 768px) {
				// 	background: red;
				// }

				@media (max-width: 500px) {
					width: 40px;
					height: 40px;
				}

				.notification-icon,
				.message-icon {
					font-weight: bold;
					font-size: 20px;

					svg {
						color: var(--d-l-grey);
					}
				}

				.profile-pic {
					width: 49px;
					height: 49px;
					border-radius: 50%;
					cursor: pointer;

					@media (max-width: 820px) {
						width: 35px;
						height: 35px;
					}

					@media (max-width: 500px) {
						width: 25px;
						height: 25px;
					}
				}

				.me {
					position: absolute;
					width: 23px;
					height: 23px;
					top: 65px;

					font-family: "Poppins";
					font-style: normal;
					font-weight: 500;
					font-size: 15px;
					line-height: 22px;
					color: #8f8f8f;
				}
			}

			.notification-container {
				position: relative;
				cursor: pointer;

				.notification-count {
					position: absolute;
					background: red;
					bottom: 0;
					right: 0;
					height: 20px;
					width: 20px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 13px;
					color: #fff;
				}
			}

			.message-count {
				position: absolute;
				top: 0;
				right: 0;
				background-color: #fd5901;
				color: black;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: black;
				border: 1px solid #fd5901;
				transform: translate(25%, -25%);
				animation: radial-wave 1.5s infinite;
			}
		}
	}
}

@keyframes radial-wave {
	0% {
		box-shadow: 0 0 0 0 #fd5901, 0 0 0 2.5px rgba(253, 89, 1, 0),
			0 0 0 5px rgba(253, 89, 1, 0);
	}
	40% {
		box-shadow: 0 0 0 2.5px rgba(253, 89, 1, 0), 0 0 0 5px #fd5901,
			0 0 0 10px rgba(253, 89, 1, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(253, 89, 1, 0), 0 0 0 2.5px rgba(253, 89, 1, 0),
			0 0 0 5px rgba(253, 89, 1, 0);
	}
}

@media (max-width: 820px) {
	.startup_navbar_container {
		.startup_navbar_left_container {
			.logo_container {
				display: none;
			}

			.startup_navbar_search_container {
				display: none;
			}

			.mobile-home-hamberger {
				display: flex;
				padding-left: 20px;

				h1 {
					color: var(--d-l-grey) !important;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
					margin-left: 2px;
					text-transform: uppercase;
				}
			}

			img {
				height: 23px;
				width: 23px;
			}
		}

		.startup_navbar_right_container {
			.search_container {
				.searchbar-container {
					display: none;
				}
			}

			.search_container_mobile {
				left: 0;
				top: 80px;
				background-color: var(--white-to-grey);
				width: 100%;
				min-height: 80px;

				.searchbar-container {
					display: flex;
					align-items: center;
					position: relative;
					margin-right: 10px;

					.searchbar-input {
						height: 49px;
						flex-grow: 1;
						background: var(--bs-light);
						border-radius: 20px;
						border: none;
						transition: width 0.4s ease;
						padding: 0 15px;
						outline: none;
					}

					.searchbar-button {
						position: absolute;
						right: -5px;
						top: 0;
						width: 48px;
						height: 49px;
						background: #fd5901;
						border: none;
						border-top-right-radius: 20px;
						border-bottom-right-radius: 20px;
						cursor: pointer;
					}
				}

				.search_results {
					display: flex;
					width: 100%;
					flex-direction: column;
					gap: 5px;

					.single_result:nth-child(6),
					.single_result:nth-child(13) {
						border-bottom: none;
					}

					.search-heading {
						color: var(--d-l-grey);
					}

					a {
						text-decoration: none;
						color: #6c757d;

						&:hover {
							color: #fd5901;
						}
					}

					.single_result {
						border-bottom: 1px solid lightgrey;
						padding: 2px 0;
						transition: padding-left ease 0.2s;

						&:hover {
							border-radius: 5px;
							padding-left: 10px;
						}
					}
				}
			}

			.icons-container {
				.mobile-icon-wrapper-search {
					display: flex;
					width: 49px;
					height: 49px;
					border-radius: 50%;
					justify-content: center;
					align-items: center;
					flex-direction: column;

					.search_container {
						left: -300px;
						top: 60px;
						background: var(--bs-light);
						width: 350px;
						min-height: 80px;

						.searchbar-container {
							display: flex;
							align-items: center;
							position: relative;
							margin-right: 10px;

							.searchbar-input {
								height: 49px;
								width: 310px;
								background: #eaeef2;
								border-radius: 20px;
								border: none;
								transition: width 0.4s ease;
								padding: 0 15px;
								outline: none;
							}

							.searchbar-button {
								position: absolute;
								right: -5px;
								top: 0;
								width: 48px;
								height: 49px;
								background: #fd5901;
								border: none;
								border-top-right-radius: 20px;
								border-bottom-right-radius: 20px;
								cursor: pointer;
							}
						}

						.search_results {
							display: flex;
							width: 100%;
							flex-direction: column;
							gap: 5px;

							.single_result:nth-child(6),
							.single_result:nth-child(13) {
								border-bottom: none;
							}

							a {
								text-decoration: none;
								color: #6c757d;

								&:hover {
									color: #fd5901;
								}
							}

							.single_result {
								border-bottom: 1px solid lightgrey;
								padding: 2px 0;
								transition: padding-left ease 0.2s;

								&:hover {
									border-radius: 5px;
									padding-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.startup_navbar_container {
		.startup_navbar_left_container {
			.mobile-home-hamberger {
				display: flex;
				align-items: center;

				h1 {
					color: #000;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin: 0;
					margin-left: 2px;
					text-transform: uppercase;
					margin-top: 2px;
				}
			}

			img {
				height: 20px;
				width: 20px;
			}
		}

		.startup_navbar_search_container {
			display: none;
		}

		.search_container_mobile {
			left: 0;
			top: 60px;
			width: 100%;
			min-height: 80px;

			.searchbar-container {
				display: flex;
				align-items: center;
				position: relative;
				margin-right: 10px;

				.searchbar-input {
					height: 49px;
					flex-grow: 1;
					background: #eaeef2;
					border-radius: 20px;
					border: none;
					transition: width 0.4s ease;
					padding: 0 15px;
					outline: none;
				}

				.searchbar-button {
					position: absolute;
					right: -5px;
					top: 0;
					width: 48px;
					height: 49px;
					background: #fd5901;
					border: none;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
					cursor: pointer;
				}
			}

			.search_results {
				display: flex;
				width: 100%;
				flex-direction: column;
				gap: 5px;

				.single_result:nth-child(6),
				.single_result:nth-child(13) {
					border-bottom: none;
				}

				a {
					text-decoration: none;
					color: #6c757d;

					&:hover {
						color: #fd5901;
					}
				}

				.single_result {
					border-bottom: 1px solid lightgrey;
					padding: 2px 0;
					transition: padding-left ease 0.2s;

					&:hover {
						border-radius: 5px;
						padding-left: 10px;
					}
				}
			}
		}
	}
}
