.startup_achievements_section {
  overflow-x: auto;
  max-width: 100%;
  background: var(--white-to-grey);
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10rem;
    background-color: rgba(253, 89, 1, 0.8);
  }

  &::-webkit-scrollbar-track {
    border-radius: 10rem;
    background-color: rgba(253, 89, 1, 0.2);
    margin-inline: 10dvw;
  }

  .nav-tabs {
    a {
      &:link {
        color: var(--d-l-grey);
      }
      &.active {
        color: var(--currentTheme) !important;
      }
    }
    h5 {
      font-size: 16px;
    }
  }

  .single-card {
    gap: 5px;
    text-align: center;
    padding: 10px 0;
    .badge-icon {
      @media (max-width: 500px) {
        max-width: 70%;
      }
    }
    .image_text {
      position: relative;
      text-align: center;
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--d-l-grey);
      }
      img {
        max-width: 250px;
        height: max-content;

        @media (max-width: 500px) {
          min-height: 55px;
        }
      }
    }
    .description {
      margin: -15px 0 0 0;
      padding: 0 10px;
      color: var(--d-l-grey);
      @media (max-width: 500px) {
        font-size: 8px;
        margin: -8px 0 0 0;
      }
    }
    h6 {
      margin: 0;
      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
    p {
      font-size: 11px;
    }
  }

  .achievements_users {
    .single-card {
      h5 {
        color: #fd5901;
      }
    }
  }
}
